/** @jsx jsx */
import { jsx } from 'theme-ui'

import React, { useState } from 'react'
import { FilterList } from '@material-ui/icons'

const Filter = ({ onClick }) => {
  const [isOpen, setIsOpen] = useState(true)
  return (
    <FilterList
      onMouseDown={() => {
        setIsOpen(!isOpen)
        onClick(isOpen)
      }}
      sx={{
        width: '17px',
        height: '26px',
        fill: 'white',
        padding: '4px 8px',
        cursor: 'pointer',
        backgroundColor: isOpen ? 'muted' : 'accent',
      }}
    />
  )
}

export default Filter
