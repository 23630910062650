import React from 'react'
import Paper from '@material-ui/core/Paper'
import style from './style.css'

const RfqBest = ({ currency, bids, asks, bidKeys, askKeys }) => {
  return (
    <div className={style.containerBest}>
      <Paper className={style.paper} elevation={0} square>
        <Paper className={style.bestSell}>
          <div className={style.bidSell}>SELL {currency}</div>
          <div className={style.currencyRFQ}>
            {bidKeys.length > 0 ? Math.max(...bidKeys.map(k => bids[k].bidPrice)) : ''}
          </div>
        </Paper>

        <Paper className={style.bestBuy}>
          <div className={style.askBuy}>BUY {currency}</div>
          <div className={style.currencyRFQ}>
            {askKeys.length > 0 ? Math.min(...askKeys.map(k => asks[k].offerPrice)) : ''}
          </div>
        </Paper>
      </Paper>
    </div>
  )
}

export default RfqBest
