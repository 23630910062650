import styled from '@emotion/styled'

export const ExternalTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: none;
  font-family: ${props => props.theme.fonts.body[props.colorMode]};
  white-space: nowrap;
  text-overflow: ellipsis;

  thead {
    inset-block-start: 0; /* "top" */
    position: sticky;
    top: 0px;
    z-index: 1;
  }
  tfoot {
    inset-block-end: 0; /* "bottom" */
    position: sticky;
    bottom: 0px;
    z-index: 1;
    width: 100%;
  }

  thead tr {
    font-size: 13px;
    color: ${props => props.theme.colors.text};
    background-color: ${props => props.theme.colors.aux};
    width: 100%;
    height: 28px;
  }

  thead tr th {
    font-family: ${props => props.theme.fonts.tabs[props.colorMode]};
    font-weight: normal;
    overflow: hidden;
    height: 28px;
    min-width: 100px;
  }

  tbody {
    position: sticky;
    z-index: 0;
  }

  tbody tr {
    text-align: left;
  }

  tbody tr:nth-of-type(odd) {
    background-color: ${props => props.theme.colors.secondary};
  }

  tbody tr:nth-of-type(even) {
    background-color: ${props => props.theme.colors.primary};
  }

  .filter {
    border: none;
    background-color: ${props => props.theme.colors.accentAlt};
    height: 46px;
    text-align: left;
    font-size: 16px;

    .filterCheckBoxLabel {
      margin-left: 60px;
      display: flex;
      margin-top: auto;
      margin-bottom: auto;
      align-items: center;
      width: 120px;
    }

    /* Hide the browser's default checkbox */
    input {
      visibility: hidden;
      height: 0px;
      width: 0px;
    }

    .customCheckBox {
      display: inline-block;
      margin-right: 10px;
      height: 16px;
      width: 16px;
      border: 2px solid ${props => props.theme.colors.checkbox};
      border-radius: 4px;
    }

    input:checked ~ .customCheckBox {
      background-color: ${props => props.theme.colors.checkbox};
    }

    .customCheckBox:after {
      content: '';
      position: relative;
      display: none;
    }

    input:checked ~ .customCheckBox:after {
      display: block;
    }

    .customCheckBox:after {
      left: 4px;
      top: 0px;
      width: 6px;
      height: 12px;
      border: 1px solid ${props => props.theme.colors.accentAlt};
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .checkBoxHeader {
    min-width: 42px;
    width: 42px;
  }

  .fixed-size {
    width: 340px;
  }
`
