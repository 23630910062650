/** @jsx jsx */
import { jsx } from 'theme-ui'
import { renderPips } from '../../common/utilities'
import * as style from './style'

const Price = ({ value, instrument }) => {
  const price = renderPips(value, instrument)
  return (
    <span>
      <span>{price.bigFigure}</span>
      <span sx={style.pip}>{price.pips}</span>
      <span>{price.fractionalPips}</span>
    </span>
  )
}

export default Price
