export const connectionIconContainer = {
  padding: '20px 0 20px 25px',
}

export const red = {
  color: 'red',
}

export const green = {
  color: 'green',
}

export const orange = {
  color: 'orange',
}
