export const statusContainer = {
  textAlign: 'center',
}

export const input = {
  width: '100%',
  bg: 'primary',
  color: 'text',
  border: 'none',
  fontSize: 3,
  '&:hover': {
    bg: 'secondary',
  },
}

export const container = {
  marginTop: '0px',
}

export const orkContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  ml: '15px',
}

export const orkInput = {
  mt: '0px',
  width: '38px',
  height: '23px',
  fontSize: '13px',
  px: '3px',
}

export const orkTipography = {
  color: 'text',
  fontSize: '13px',
  mr: '5px',
}

export const tradingCapacitiy = {
  ...orkInput,
  width: '71px',
  pl: '0px',
}
