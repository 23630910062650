import { useState, useEffect } from 'react'
import { prop, isEmpty, propEq, reject } from 'ramda'

const includes = acc => ({ accessor }) => acc.includes(accessor)

export default ({
  accessor,
  setAccessor,
  tabIndex,
  openFilter,
  openAccessor,
  defaultExcludeColumns,
}) => {
  const [cols, setCols] = useState([])
  const [columns, setColumns] = useState([])
  const [filterable, setFilterable] = useState(openFilter)
  const [open, setOpen] = useState(false)
  const defaultAccessors = columns =>
    defaultExcludeColumns
      ? columns.map(prop('accessor')).filter(e => !defaultExcludeColumns.includes(e))
      : columns.map(prop('accessor'))

  useEffect(() => {
    setFilterable(openFilter)
  }, [openFilter])

  useEffect(() => {
    setOpen(openAccessor)
  }, [openAccessor])

  useEffect(() => {
    const newAccessor = !isEmpty(accessor) ? accessor : defaultAccessors(columns)
    isEmpty(accessor) && setAccessor({ tabIndex, accessor: newAccessor })
    setCols(columns.filter(includes(newAccessor)))
  }, [columns])

  const handler = accessorKey => {
    const pred = propEq('accessor', accessorKey)
    if (cols.find(pred)) {
      const newCols = reject(pred, cols)
      if (newCols.length === 0) {
        return false
      }
      setAccessor({ tabIndex, accessor: newCols.map(prop('accessor')) })
      setCols(newCols)
    } else {
      const [aux] = columns.filter(c => c.accessor === accessorKey)
      const newCols = columns.filter(c => [...cols, aux].includes(c))
      setAccessor({ tabIndex, accessor: newCols.map(prop('accessor')) })
      setCols(newCols)
    }
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return [cols, handler, handleOpen, handleClose, setColumns, columns, filterable, open]
}
