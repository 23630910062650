const formatPriceData = (priceData, instrument) => {
  if (priceData && instrument && priceData.split('.').length > 1) {
    const price = priceData.split('.')
    const firstPosition =
      instrument?.priceDecimals - (instrument?.priceDecimals - instrument?.pipDecimal)
    const lastPosition = firstPosition - instrument?.pipDigits

    const bigFigure = `${price[0]}.${price[1].substring(0, lastPosition)}`
    const pips = price[1].substring(firstPosition, lastPosition)
    const fractionalPips =
      price.length > 2
        ? `${price[1].substring(firstPosition, instrument?.priceDecimals)}${price
            .slice(2)
            .reduce((x, y) => `${x}.${y}`, '')}`
        : price[1].substring(firstPosition, instrument?.priceDecimals)
    const error = price.length > 2

    return {
      bigFigure,
      pips,
      fractionalPips,
      error,
    }
  } else {
    const bigFigure = priceData
    const pips = ''
    const fractionalPips = ''
    const error = isNaN(priceData)

    return {
      bigFigure,
      pips,
      fractionalPips,
      error,
    }
  }
}

export default formatPriceData
