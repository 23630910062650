import { Stomp } from '@stomp/stompjs'
import uuid from 'uuid'

import config from '../common/config'

const client = Stomp.over(() => new WebSocket(config().urls.orders)) //, null, { transports: ['xhr-streaming'] }))
if (process.env.NODE_ENV !== 'development') {
  client.debug = () => { }
}


export const connectToOrdersSocket = payload => dispatch =>
  client.connect({ Authorization: `Bearer ${payload}` }, () => {
    dispatch({ type: 'ORDER_SOCKET_CONNECTED', payload: true })
    client.subscribe('/private/order', data => {
      const body = JSON.parse(data.body)
      const { type, orders, operations } = body
      switch (type) {
        case 'order_list':
          dispatch({ type: 'ORDER_LIST_DATA', payload: orders })
          break
        case 'order_details':
          dispatch({ type: 'GET_ORDER_DATA_FULLFILLED', payload: body })
          break
        case 'operation_list':
          dispatch({ type: 'TRADE_LIST_DATA', payload: operations })
          break
        case 'order_update':
          const order = JSON.parse(data.body)
          clearOrderTexts(order)
          dispatch({ type: 'ORDER_UPDATE', payload: order })
          if (order.execStatus === 'TRADE') {
            dispatch({ type: 'TRADE_UPDATE', payload: convertOrderToTrade(order) })
          }
          break
        default:
          break
      }
    })
  })

const convertOrderToTrade = order => {
  return {
    'masterOrderid': order.masterOrderId,
    'orderid': order.orderId,
    'clientOderId': order.clientOrderId,
    'operationId': order.updateId,
    'symbol': order.symbol,
    'exchangeId': order.exchangeId,
    'instrumentId': order.instrumentId,
    'side': order.side,
    'price': order.lastPrice,
    'quantity': order.lastQuantity,
    'operationAccount': order.operationAccount,
    'tradingCapacity': order.tradingCapacity,
    'date': order.date,
    'userId': order.userId,
    'tradeId': order.tradeId,
  }
}

/**
 * @author Jesús Fernández
 *
 * This method introduces empty text by default to missing variables to avoid errors when updating fields in OrderList
 *
 * @param {object} order   Received object which contains order data
 *
 */
const clearOrderTexts = order => {
  if (!order.text) {
    order.text = ''
  }
  if (!order.orderRejectReason) {
    order.orderRejectReason = ''
  }
  if (!order.orderRejectText) {
    order.orderRejectText = ''
  }

  if (!order.orderCancelReason) {
    order.orderCancelReason = ''
  }
  if (!order.orderCancelText) {
    order.orderCancelText = ''
  }
}
export const cleanOrderData = () => dispatch => {
  dispatch({
    type: 'GET_ORDER_DATA_CLEAN',
    payload: {},
  })
}

export const getOrder = (orderId, token) => dispatch => {
  dispatch({
    type: 'GET_ORDER_DATA_PENDING',
    payload: {},
  })
  setTimeout(() =>
    client.send(
      `/private/order/get`,
      { Authorization: `Bearer ${token}` },
      JSON.stringify({ type: `get_order`, oid: uuid(), orderId })
    )
  )
}

export const getOrderList = token => () =>
  setTimeout(() =>
    client.send(
      `/private/order/list`,
      { Authorization: `Bearer ${token}` },
      JSON.stringify({ type: `order_list`, oid: uuid() })
    )
  )

export const getTradeList = token => () =>
  setTimeout(() =>
    client.send(
      `/private/order/operations`,
      { Authorization: `Bearer ${token}` },
      JSON.stringify({ type: `operation_list`, oid: uuid() })
    )
  )

export const makeOrder = (payload, token) => () =>
  client.send(
    '/private/order/new',
    { Authorization: `Bearer ${token}` },
    JSON.stringify({
      ...payload,
      type: 'new_order',
      oid: uuid(),
      date: new Date(),
    })
  )

export const makeOrderWithConfirm = (payload, message, token) => dispatch => {
  dispatch({
    type: 'SHOW_CONFIRM_DIALOG',
    payload: {
      message: message.body,
      title: message.title,
      side: payload.side,
      orderInfo: payload,
      selectedAccount: message.selectedAccount,
      dontShowAgain: () => dispatch({ type: 'CHANGE_CONFIRM_MODAL_REQUIRED' }),
      action: () => {
        client.send(
          '/private/order/new',
          { Authorization: `Bearer ${token}` },
          JSON.stringify({
            ...payload,
            type: 'new_order',
            oid: uuid(),
            date: new Date(),
          })
        )
        dispatch({ type: 'CLOSE_CONFIRM_DIALOG', payload })
      },
    },
  })
}

export const cancelOrderWithConfirm = (payload, token) => dispatch => {
  dispatch({
    type: 'SHOW_CONFIRM_DIALOG',
    payload: {
      message: payload.message,
      title: payload.title,
      show: true,
      order: payload.order,
      side: payload.side,
      selectedAccount: payload.selectedAccount,
      orderInfo: payload.orderInfo,
      action: () => {
        client.send(
          '/private/order/cancel',
          { Authorization: `Bearer ${token}` },
          JSON.stringify({
            type: 'cancel_order',
            oid: uuid(),
            date: new Date(),
            origClOrdId: payload.origClOrdId,
            symbol: payload.symbol,
            side: payload.side,
            exchangeId: payload.exchangeId,
            operationAccount: payload.operationAccount,
          })
        )
        dispatch({ type: 'CLOSE_CONFIRM_DIALOG', payload })
      },
    },
  })
}

export const replaceOrder = (payload, token) => dispatch => {
  client.send(
    '/private/order/replace',
    { Authorization: `Bearer ${token}` },
    JSON.stringify({
      ...payload,
      type: 'replace_order',
      oid: uuid(),
      date: new Date(),
    })
  )
  dispatch({ type: 'CLOSE_ORDER_DIALOG', payload })
}

export const showOrderDialog = payload => ({ type: 'SHOW_ORDER_DIALOG', payload })

export const closeOrderDialog = () => ({ type: 'CLOSE_ORDER_DIALOG' })
