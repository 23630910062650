import { tableColumns } from '../../common/utilities'
import FormattedNumberText from '../FormattedNumber'
import React from 'react'
import { numberCell } from '../../common/utilities/tableCellUtils'

const sides = ['BUY', 'SELL']
const capacities = ['DEAL', 'MATCH', 'ANY_OTHER']
const product = ['ROLLING_SPOT', 'FXSPOT']

const columnsHead = t => {
  const c = tableColumns(t)

  return [
    c.getDateColumn({
      header: 'tradeLocal',
      accessor: 'formatDate',
      csvHeader: 'date',
      width: 285,
    }),
    c.getColumn({ header: 'account', accessor: 'formatAccount', csvHeader: 'account' }),
    c.getColumn({ header: 'instrument', accessor: 'symbol', csvHeader: 'instrument' }),
    c.getSelectColumn({ header: 'side', csvHeader: 'side', options: sides }),
    c.getFromToIntColumn({
      header: 'orderQuantity',
      accessor: 'formatQuantity',
      csvHeader: 'quantity',
      Cell: numberCell,
    }),
    c.getFromToFloatColumn({
      header: 'price',
      accessor: 'formatPrice',
      csvHeader: 'price',
      Cell: numberCell,
    }),
    c.getSelectColumn({
      header: 'tradingCapacity',
      csvHeader: 'tradingCapacity',
      options: capacities,
    }),
    c.getColumn({ accessor: 'tradeId', csvHeader: 'tradeId' }),
    c.getColumn({ accessor: 'orderId', csvHeader: 'orderId' }),
    c.getSelectColumn({
      header: 'productSubType',
      accessor: 'productSubTypeFormat',
      csvHeader: 'productSubType',
      options: product,
    }),
    c.getColumn({ accessor: 'externalCustomerId', csvHeader: 'externalCustomerId' }),

  ]
}

const defaultSorted = [{ id: 'formatDate', desc: true }]

export { columnsHead, defaultSorted }
