export const container = colorMode => ({
  zIndex: '1000',
  position: 'fixed',
  marginTop: '33px',
  width: '295px',
  textAlign: 'center',
  padding: '0px 8px 8px 8px',
  height: '299px',
  overflow: 'hidden',
  backgroundColor: 'muted',
  border: theme => theme.borders.alt[colorMode],
  borderColor: 'borderAccountContainer',
  '*': { ':focus': { outline: 'none', backgroundColor: 'transparent' } },
})

export const input = colorMode => ({
  width: '100%',
  height: '34px',
  bg: 'primary',
  color: 'text',
  fontSize: 3,
  border: theme => theme.borders.secondary[colorMode],
  borderColor: 'borderAccountInput',
  '&:hover': {
    bg: 'secondary',
  },
})
export const resultBox = {
  marginTop: '10px',
  minHeight: '194px',
  maxHeight: '194px',
  overflowY: 'scroll',
  scrollbarWidth: 'thin',
  backgroundColor: 'primary',
}

export const inputContainer = {
  width: '95.5%',
  marginTop: '10px',
  color: 'text',
}

export const accountGroupContainer = {
  minWidth: '275px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}

export const accountGroupName = {
  color: 'text',
  pl: '5px',
  overflow: 'hidden',
  whiteSpace: 'pre-wrap',
  overflowWrap: 'break-word',
  width: '210px',
}

export const searchIcon = {
  color: 'icons',
}

export const createIcon = {
  color: 'icons',
  float: 'right',
  padding: '3px 7px 5px 5px',
}

export const editIcon = {
  color: 'icons',
  float: 'right',
  padding: '3px 5px 5px 5px',
  '&:hover': {
    color: 'muted',
  },
}

export const menuItem = {
  backgroundColor: 'primary',
  '&:hover': {
    backgroundColor: 'secondary',
  },
  height: 'auto',
  paddingLeft: '10px',
}

export const account = {
  display: 'flex',
  alignItems: 'center',
  width: '90%',
}

export const cp = {
  cursor: 'pointer',
}

export const add = {
  lineHeight: '1.9',
  height: '34px',
  paddingTop: '5px',
  paddingLeft: '10px',
  textAlign: 'justify',
}
