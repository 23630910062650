export const container = {
  width: '232px',
  '& input': {
    pl: '1px',
    pr: '2px',
    color: 'text',
  },
}

export const date = {
  position: 'absolute',
  left: '170px',
  fontSize: 1,
  width: '65px',
  top: '55px',
}

export const selector = {
  bg: 'primary',
  color: 'text',
  border: 'none',
  fontSize: 4,
  height: '31px',
  width: '76.5%',
  mt: '6px',
  '& svg': {
    color: 'text',
  },
}

export const input = {
  width: '100%',
  bg: 'primary',
  color: 'text',
  border: 'none',
  fontSize: 3,
  zIndex: '999',
  '&:hover': {
    bg: 'secondary',
  },
}

export const inputBase = {
  width: '40px',
  height: '17px',
  fontSize: '13px',
  pt: '4px',
  pb: '2px',
  cursor: 'text',
}

export const orkTipography = {
  color: 'text',
  fontSize: '13px',
  marginBottom: '1px',
}

export const trading = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  marginTop: '3px',
}
