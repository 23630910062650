/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import React, { useContext, useState } from 'react'
import { I18nContext } from '../../containers/i18n'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { DialogRowElement, Label } from '../Dialog'
import { ClickAwayListener, OutlinedInput } from '@material-ui/core'
import SelectSearchOptions from './SelectSearchOptions'
import * as style from './style'

const SelectSearch = ({
  value,
  onChange,
  options,
  optionValueProp = 'value',
  optionLabelProp = 'label',
  placeholder = 'selectOption',
  searchPlaceHolder,
  menuItemHeight,
  menuItemRender,
  noneEnabled,
  label,
  rowProps,
  labelProps,
}) => {
  const { t } = useContext(I18nContext)
  const [isOpen, setIsOpen] = useState(false)
  const [boundingClientRect, setBoundingClientRect] = useState()
  const { colorMode } = useThemeUI()

  const handleOnChange = e => {
    setIsOpen(false)
    onChange(e)
  }
  const findLabelByValue = value => {
    const found = options?.find(o => o[optionValueProp] === value)
    return found && found[optionLabelProp]
  }

  const cont = (
    <>
      <OutlinedInput
        readOnly
        labelWidth={0}
        sx={style.select(colorMode)}
        value={findLabelByValue(value) || value}
        endAdornment={<ExpandMore />}
        onClick={e => {
          setBoundingClientRect(e.currentTarget.getBoundingClientRect())
          setIsOpen(true)
        }}
        placeholder={t(placeholder)}
      />
      {isOpen && (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <SelectSearchOptions
            options={options}
            valueProp={optionValueProp}
            labelProp={optionLabelProp}
            value={value}
            boundingClientRect={boundingClientRect}
            onChange={handleOnChange}
            placeholder={searchPlaceHolder}
            menuItemHeight={menuItemHeight}
            menuItemRender={menuItemRender}
            noneEnabled={noneEnabled}
          />
        </ClickAwayListener>
      )}
    </>
  )

  return label ? (
    <DialogRowElement {...rowProps}>
      <Label {...labelProps}>{label}</Label>
      {cont}
    </DialogRowElement>
  ) : (
    cont
  )
}

export default SelectSearch
