import Api from '../common/api'
import config from '../common/config'

export function fetchEnablements(keycloak) {
  const api = new Api({
    baseUrl: config().urls.baseUrl,
    defaultOptions: { headers: { Authorization: `Bearer ${keycloak.token}` } },
  })
  return {
    type: 'FETCH_ENABLEMENTS',
    payload: api.get(`/preferences/v2/enablements`),
  }
}
