/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'

import React from 'react'
import { Checkbox, ListItemText, MenuItem, Menu } from '@material-ui/core'
import * as style from './style'

const PanelTableMenu = ({
  menuStyle,
  open,
  handleOpen,
  handleChange,
  handleClose,
  setOpenAccessor,
  tabIndex,
  columns,
  accessor,
  top,
  left,
}) => {
  const { theme } = useThemeUI()

  return (
    <Menu
      PaperProps={{
        style: {
          maxHeight: 315,
          left: +left,
          top: +top + 9,
          borderRadius: 0,
          boxShadow: 'none',
          scrollbarWidth: 'thin',
          scrollbarColor: '#789 white',
        },
      }}
      anchorReference="none"
      disableAutoFocusItem
      transitionDuration={100}
      MenuListProps={{
        style: {
          padding: 0,
        },
      }}
      sx={menuStyle || style.menuColumns}
      open={open}
      onEnter={handleOpen}
      onClose={() => {
        handleClose()
        setOpenAccessor({ openAccessor: false, tabIndex })
      }}
    >
      {columns.map(c => (
        <MenuItem
          key={c.accessor}
          value={c.accessor}
          onClick={() => handleChange(c.accessor)}
          disableGutters
          sx={style.menu}
        >
          <Checkbox
            checked={accessor.includes(c.accessor)}
            style={{ color: theme.colors.checkbox }}
          />
          <ListItemText
            primary={c.Header.props.children}
            primaryTypographyProps={{ style: { color: theme.colors.text } }}
            sx={style.listItem}
          />
        </MenuItem>
      ))}
    </Menu>
  )
}

export default PanelTableMenu
