export const select = colorMode => ({
  cursor: 'pointer',
  display: 'flex',
  width: '100%',
  bg: 'primary',
  color: 'text',
  border: theme => theme.borders.primary[colorMode],
  borderColor: 'muted',
  pr: '5px',
  '&:hover': {
    bg: 'secondary',
  },
  '&:disabled': {
    border: '10px solid',
    bg: 'disabled',
    cursor: 'not-allowed',
  },
  '& input': {
    padding: '5px 15px',
    cursor: 'pointer',
  },
})
