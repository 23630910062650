/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as style from './style'
import React, { useState, useEffect } from 'react'
import {
  SignalWifi1Bar,
  SignalWifi2Bar,
  SignalWifi3Bar,
  SignalWifi4Bar,
  SignalWifiOff,
} from '@material-ui/icons'
import { clearInterval, setInterval } from 'timers'
import { Tooltip } from '@material-ui/core'

const getIconForCompatibleNavs = () => {
  const ping = navigator.connection.rtt

  if (!navigator.onLine) {
    return <SignalWifiOff sx={style.red} />
  } else {
    switch (true) {
      case ping === 0:
        return <SignalWifiOff sx={style.red} />
      case ping <= 200:
        return <SignalWifi4Bar sx={style.green} />
      case ping <= 400:
        return <SignalWifi3Bar sx={style.green} />
      case ping <= 600:
        return <SignalWifi2Bar sx={style.orange} />
      default:
        return <SignalWifi1Bar sx={style.orange} />
    }
  }
}

const getIconForOtherNavs = () => {
  return navigator.onLine ? <SignalWifi3Bar sx={style.green} /> : <SignalWifiOff sx={style.red} />
}

const getIcon = () =>
  navigator.connection?.rtt ? getIconForCompatibleNavs() : getIconForOtherNavs()

const ConnectionIndicator = ({ tooltipText }) => {
  const [, setRender] = useState({})
  useEffect(() => {
    const interval = setInterval(() => setRender({}), 5000)

    return () => clearInterval(interval)
  }, [])

  return (
    <div sx={style.connectionIconContainer}>
      <Tooltip title={tooltipText}>{getIcon()}</Tooltip>
    </div>
  )
}

export default ConnectionIndicator
