import styled from '@emotion/styled'
import MSelect from '@material-ui/core/Select'

const Select = styled(MSelect)`
  color: white;
  background-color: #283e60;
  width: 100%;
  &::after {
    border-bottom: none;
  }
`

export default Select
