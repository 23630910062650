/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui'
import * as style from './style'
import { useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import { FormControlLabel } from '@material-ui/core'
import Switch from '../Switch'
import Button from '../CustomButton'
import { I18nContext } from '../../containers/i18n'
import { CustomSelect, getOptions } from '../../common/utilities'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../Dialog'
import { changeConfirmModalRequired, hideUserSettings, setLanguage } from '../../actions/workspace'

const themeOptions = ['dark']
const languageOptions = ['en', 'es']

const UserSettings = ({
  changeConfirmModalRequired,
  hideUserSettings,
  setLanguage,
  language,
  isConfirmDialogRequired,
  open,
}) => {
  const { t, lang, changeLang } = useContext(I18nContext)
  const [theme] = useColorMode()
  // const [themeSelector, setThemeSelector] = useState(false)

  useEffect(() => {
    changeLang(language)
    //TODO show the theme selector
    /* if (keycloak.tokenParsed.member?.theme) {
       setThemeSelector(false)
     } else {
       setThemeSelector(true)
     }*/
  }, [])

  return (
    <Dialog onClose={hideUserSettings} open={open}>
      <DialogTitle settings="true" disableTypography colormode={theme}>
        <label sx={style.mainTitle}>{t('userSettingsTitle')}</label>
      </DialogTitle>
      <DialogContent sx={style.textContainer}>
        <div sx={style.interfaceTitle}>
          <label sx={style.labelTitle}>{t('interfaceSettings')}</label>
        </div>
        <div sx={style.settings}>
          <label sx={style.marginLabel}>{t('language')}</label>
          <CustomSelect
            onChange={e => {
              changeLang(e.target.value)
              setLanguage({ language: e.target.value })
            }}
            value={language || lang}
            sx={{ ...style.input(theme), ...style.w50 }}
          >
            {getOptions(t, languageOptions)}
          </CustomSelect>
        </div>
        {/* <div sx={style.settings} style={{ display: themeSelector ? 'flex' :'none' }}>
          <label sx={style.marginLabel}>{t('theme')}</label>
          <CustomSelect
            onChange={e => setTheme(e.target.value)}
            value={theme}
            sx={{ ...style.input(theme), ...style.w50 }}
          >
            {getOptions(t, themeOptions)}
          </CustomSelect>
        </div> */}
        <div sx={style.interfaceTitle}>
          <label sx={style.labelTitle}>{t('tradingSettings')}</label>
        </div>
        <div sx={style.settings}>
          <div sx={style.tradingSettings}>
            <label sx={style.marginLabel}>{t('showConfirmTransaction')}</label>
            <FormControlLabel
              control={
                <Switch checked={isConfirmDialogRequired} onChange={changeConfirmModalRequired} />
              }
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button type="cancel" halfWidth onClick={hideUserSettings}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = ({ workspace: { isConfirmDialogRequired, theme, language } }) => ({
  language,
  isConfirmDialogRequired,
  theme,
})
const mapDispatchToProps = {
  setLanguage,
  changeConfirmModalRequired,
  hideUserSettings,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings)
