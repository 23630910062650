import styled from '@emotion/styled'

export const InternalTable = styled.table`
  width: 100%;
  border: none;
  border-spacing: 0px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${props => props.theme.colors.text};

  tbody tr:nth-of-type(odd) {
    background-color: ${props => props.theme.colors.odd};
    .checkBoxCell .customCheckBox:after {
      left: 4px;
      top: 0px;
      width: 6px;
      height: 12px;
      border: 1px solid ${props => props.theme.colors.odd};
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  tbody tr:nth-of-type(even) {
    background-color: ${props => props.theme.colors.even};
    .checkBoxCell .customCheckBox:after {
      left: 4px;
      top: 0px;
      width: 6px;
      height: 12px;
      border: 1px solid ${props => props.theme.colors.even};
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  tbody tr {
    height: 40px;
    :hover {
      background-color: ${props => props.theme.colors.background};
      .checkBoxCell .customCheckBox:after {
        left: 4px;
        top: 0px;
        width: 6px;
        height: 12px;
        border: 1px solid ${props => props.theme.colors.background};
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
    /* Hide the browser's default checkbox */
    .checkBoxCell input {
      visibility: hidden;
      height: 0;
      width: 0;
      display: none;
    }

    .checkBoxCell label {
      display: flex;
      width: 100%;
      height: 100%;
      margin: auto;
    }

    .customCheckBox {
      display: inline-block;
      margin: auto;
      height: 16px;
      width: 16px;
      border: 2px solid ${props => props.theme.colors.checkbox};
      border-radius: 4px;
    }

    .checkBoxCell input:checked ~ .customCheckBox {
      background-color: ${props => props.theme.colors.checkbox};
    }

    .customCheckBox:after {
      content: '';
      position: relative;
      display: none;
    }

    .checkBoxCell input:checked ~ .customCheckBox:after {
      display: block;
    }
  }

  tbody tr td {
    text-align: center;
    height: 40px;
    border-right: 1px solid ${props => props.theme.colors.background};
    border-bottom: 1px solid ${props => props.theme.colors.background};
    border-spacing: 0px;
    min-width: 100px;
  }

  .checkBoxCell {
    min-width: 40px;
    width: 40px;
  }

  .fixed-size {
    width: 340px;
  }
`
