/** @jsx jsx */
import { jsx } from 'theme-ui'
import { connect } from 'react-redux'
import { useContext } from 'react'
import * as style from './style'
import { I18nContext } from '../../containers/i18n'
import { CustomSelect, getOptions } from '../../common/utilities'
import { updateOrkData } from '../../actions/workspace'
import { Typography } from '@material-ui/core'
import Ork from './ork'

const tradingCapacityTypes = ['DEAL', 'MATCH', 'ANY_OTHER']

const TradingCapacity = ({ data, tabIndex, updateOrkData }) => {
  const { t } = useContext(I18nContext)

  return (
    <div
      sx={{
        p: 0.5,
        m: 0,
      }}
    >
      <div sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ ...style.orkTipography }}>TC</Typography>
        <CustomSelect
          onChange={e => updateOrkData({ tradingCapacity: e.target.value, tabIndex })}
          value={data?.tradingCapacity?.toUpperCase() || ''}
          sx={{ ...style.input, ...style.tradingCapacitiy }}
        >
          {getOptions(t, tradingCapacityTypes)}
        </CustomSelect>
        <Ork
          label="C"
          tabIndex={tabIndex}
          value={data?.clientId}
          onChange={val => updateOrkData({ clientId: val, tabIndex })}
        ></Ork>
        <Ork
          label="D"
          tabIndex={tabIndex}
          value={data?.decisorId}
          onChange={val => updateOrkData({ decisorId: val, tabIndex })}
        ></Ork>
        <Ork
          label="E"
          tabIndex={tabIndex}
          value={data?.executorId}
          onChange={val => updateOrkData({ executorId: val, tabIndex })}
        ></Ork>
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  updateOrkData,
}

export default connect(null, mapDispatchToProps)(TradingCapacity)
