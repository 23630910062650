/** @jsx jsx */
import { jsx } from 'theme-ui'
import theme from '../../containers/theme'
import { isNil } from 'ramda'

const filterCaseInsensitive = ({ id, value }, row) =>
  row[id] ? row[id].toUpperCase().includes(value.toUpperCase()) : false

const styleTable = {
  getTdProps: () => ({
    style: {
      alignItems: 'center',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
    },
  }),
  getTheadProps: () => ({
    style: {
      position: 'sticky',
      top: 0,
      padding: '0px 0px',
    },
  }),
  getTheadThProps: () => ({
    style: { padding: '0px 0px', borderRight: `1px solid ${theme.colors.gray}` },
  }),
  getTheadFilterThProps: () => ({
    style: {
      borderRight: `1px solid ${theme.colors.gray}`,
    },
  }),
  getTheadFilterProps: () => ({
    style: {
      position: 'sticky',
      top: 29,
    },
  }),
  getTrProps: (state, rowInfo, column) => ({
    style: {
      height: '40px',
      textDecoration: rowInfo && rowInfo.original.disabled ? 'line-through' : 'none',
    },
  }),
}

const sxTable = (styleTable, disabled = false) => ({
  color: 'text',
  '& .-odd ': {
    backgroundColor: 'odd',
  },
  '& .-even ': {
    backgroundColor: 'even',
  },
  '& .-filters ': {
    backgroundColor: 'accent',
  },
  '& .rt-th ': {
    backgroundColor: 'accentAlt',
    whiteSpace: 'normal',
  },
  '& .rt-tr ': {
    alignItems: 'center',
  },
  '& .rt-thead > .rt-tr ': {
    alignItems: 'normal',
  },
  '& .rt-thead.-filters input': {
    backgroundColor: 'primary',
    color: 'text',
    '&:hover': {
      backgroundColor: 'secondary',
    },
  },
  '& .rt-tbody .rt-td': {
    textAlign: 'center',
  },
  mixBlendMode: disabled ? 'soft-light' : 'unset',
  ...styleTable,
})

const addColumnStyle = (label, isEmpty) => (
  <div
    sx={{
      padding: '3px',
      minHeight: isEmpty ? '30px' : '29px',
    }}
  >
    {label}
  </div>
)

const addColumnListStyle = columnList =>
  columnList.map(column => ({
    ...column,
    Header: addColumnStyle(column.Header, column.empty),
  }))

const TABLE_ID = 'qtable'
const getTableId = tabIndex => `${TABLE_ID}${tabIndex}`

const headerSelector = tabIndex =>
  document.querySelectorAll(`#${getTableId(tabIndex)} .rt-thead .rt-th .headerContent`)

const dragableColumns = ({ colAux, tabIndex, onDropColumn = () => null }) => {
  headerSelector(tabIndex).forEach((header, i) => {
    const draggable = colAux[i]?.draggable
    if (draggable) {
      header.setAttribute('draggable', draggable)
      header.ondragstart = e => {
        e.dataTransfer.setData('text/plain', i)
        e.stopPropagation()
        e.target.classList.add('dragged')
        e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.3)'
      }
      header.ondrag = e => e.stopPropagation()

      header.ondragend = e => {
        e.stopPropagation()
        e.target.style.backgroundColor = ''
      }

      header.ondragover = e => e.preventDefault()

      header.ondrop = e => {
        e.preventDefault()
        const dragIndex = e.dataTransfer.getData('text/plain')
        onDropColumn(dragIndex, i)
        e.target.parentElement.style.backgroundColor = ''
      }

      header.ondragenter = e => {
        e.preventDefault()
        e.stopPropagation()
        if (!e.target.parentElement.classList.contains('dragged')) {
          e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.3)'
        }
      }
      header.ondragleave = e => {
        e.preventDefault()
        if (!e.target.classList.contains('dragged')) {
          e.target.style.backgroundColor = ''
        }
      }
    }
  })
}

const tranformColumns = (columns, columnsFilter) => {
  let columnsTransform = [...columns]
  const transformFlag = true
  if (!isNil(columnsFilter)) {
    columnsTransform = columnsFilter
      .filter(colFil => colFil.isVisible)
      .map(colFil => columns.find(col => col.accessor === colFil.accessor))
  }
  return [transformFlag, columnsTransform]
}

export {
  filterCaseInsensitive,
  styleTable,
  sxTable,
  addColumnListStyle,
  dragableColumns,
  tranformColumns,
  getTableId,
}
