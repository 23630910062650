import React, { useContext } from 'react'
import { I18nContext } from '../../containers/i18n'
import { Field, ListContainer, ListDecoratedItem, ListItem, ListTitle, SectionTitle } from './style'

const CommonBody = ({ notification }) => {

    const {
        operationAccountNames,
        currentAccountMargin,
        maxMargin,
        warningThreshold,
        orderAffectedOperationAccountName,
        exchange,
        orderType,
        orderSide,
        orderInstrument,
        orderQuantity,
        alertType
    } = notification.marginAlert

    const { t } = useContext(I18nContext)


    let currentMarginMessage
    let affectedOrderMessage
    switch (alertType) {
        case 'MARGIN_EXCEEDED':
            currentMarginMessage = 'accountMarginRequired'
            affectedOrderMessage = 'rejectedOrder'
            break
        case 'WARNING_THRESHOLD':
        default:
            currentMarginMessage = 'currentAccountMargin'
            affectedOrderMessage = 'triggeringOrder'
    }

    return (
        <Field>
            <SectionTitle >{t('details')}</SectionTitle>
            <ListContainer>
                {currentAccountMargin ? <ListItem sx={{ fontWeight: '400' }}>{t(currentMarginMessage)(currentAccountMargin)}</ListItem> : <></>}
                {maxMargin ? <ListItem>{t('accountMaxMargin')(maxMargin)}</ListItem> : <></>}
                {warningThreshold ? <ListItem>{t('warningThreshold')(warningThreshold)}</ListItem> : <></>}
                <ListItem>
                    <ListTitle>{t('affectedOperationAccounts')}</ListTitle>
                    <ListContainer>{operationAccountNames.map((name, i) => <ListDecoratedItem key={i}>{name}</ListDecoratedItem>)}</ListContainer>
                    <ListContainer>
                        <ListTitle>{t(affectedOrderMessage)}</ListTitle>
                        {orderAffectedOperationAccountName ? <ListItem>{t('operationAccountNotification')(orderAffectedOperationAccountName)}</ListItem> : <></>}
                        {exchange ? <ListItem>{t('exchangeNotification')(exchange)}</ListItem> : <></>}
                        {orderType ? <ListItem>{t('typeNotification')(t(orderType))}</ListItem> : <></>}
                        {orderSide ? <ListItem>{t('sideNotification')(t(orderSide))}</ListItem> : <></>}
                        {orderInstrument ? <ListItem>{t('instrumentNotification')(orderInstrument)}</ListItem> : <></>}
                        {orderQuantity ? <ListItem>{t('quantityNotification')(orderQuantity)}</ListItem> : <></>}
                    </ListContainer>
                </ListItem>
            </ListContainer>
        </Field >
    )

}

export default CommonBody
