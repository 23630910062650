/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as style from './style'
import { useRef, useContext } from 'react'
import { Label, DialogRowElement } from '../Dialog'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { addDays } from 'date-fns'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputMask from 'react-input-mask'
import { I18nContext } from '../../containers/i18n'

const GTDDatePicker = ({
  label,
  onChange,
  disabled = false,
  value,
  minDate,
  maxDate,
  tabIndex,
  labelProps,
  ...props
}) => {
  minDate = minDate ? minDate : new Date()
  maxDate = maxDate ? maxDate : addDays(new Date(), 90)
  const datePickerRef = useRef()
  const { t } = useContext(I18nContext)
  return (
    <DialogRowElement {...props} sx={{ ...style.datePicker, ...props.sx }}>
      {label && <Label {...labelProps}>{label}</Label>}
      <DatePicker
        {...props}
        ref={datePickerRef}
        disabled={disabled}
        dateFormat={t('dateFormat')}
        selected={disabled ? '' : Date.parse(value)}
        tabIndex={tabIndex}
        onChange={date => onChange(date)}
        minDate={minDate}
        maxDate={maxDate}
        popperPlacement="bottom-end"
        popperModifiers={{
          offset: {
            enabled: true,
            offset: '5px, 10px',
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: 'viewport',
          },
        }}
        customInput={
          <InputMask mask="99/99/99" alwaysShowMask maskChar="-">
            {() => (
              <Input
                onClick={() => datePickerRef.current.input.onFocus()}
                disableUnderline
                endAdornment={
                  <InputAdornment disablePointerEvents>
                    <ExpandMore />
                  </InputAdornment>
                }
                sx={{
                  ...style.input,
                  cursor: 'pointer',
                  width: 'auto',
                  pl: '10px',
                  ...(disabled ? style.disabled : {}),
                }}
                type="text"
              />
            )}
          </InputMask>
        }
      />
    </DialogRowElement>
  )
}

export default GTDDatePicker
