export const direction = colorMode => ({
  textTransform: 'uppercase',
  fontSize: '12px',
  fontWeight: 'bolder',
  fontFamily: theme => theme.fonts.body[colorMode],
  textAlign: 'center',
  pt: '3px',
  borderBottom: theme => `2px solid ${theme.colors.muted}`,
})

// Reminder: ASK -> means you BUY, SELL is Bid Price!
export const setStyle = ask => ({
  cursor: 'pointer',
  position: 'absolute',
  width: '92px',
  height: '120px',
  mt: '5px',
  [ask ? 'right' : 'left']: '-7px',
  pl: '10px',
  pr: '10px',
  bg: 'primary',
  '&:hover': {
    color: 'white',
    bg: ask ? 'green' : 'red',
  },
})

export const fig = colorMode => ({
  textTransform: 'uppercase',
  fontSize: 2,
  fontWeight: 'bolder',
  fontFamily: theme => theme.fonts.priceButtons[colorMode],
})

const base = colorMode => ({
  fontFamily: theme => theme.fonts.priceButtons[colorMode],
  display: 'inline-block',
  verticalAlign: 'baseline',
})

export const fractionalPips = colorMode => ({
  ...base(colorMode),
  textAlign: 'left',
  fontSize: 5,
})

export const fractionalPipsLong = colorMode => ({
  ...fractionalPips(colorMode),
  fontSize: 4,
})

export const bigFigure = colorMode => ({
  ...base(colorMode),
  ...fractionalPips(colorMode),
  fontSize: 4,
  lineHeight: 1.5,
})

export const pip = colorMode => ({
  ...base(colorMode),
  fontSize: 8,
  mt: '-13px',
})
