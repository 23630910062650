import React from 'react'
import { withStyles, Switch } from '@material-ui/core'

const styles = theme => ({
  colorBar: {},
  colorChecked: {},
  iOSSwitchBase: {
    '&$iOSChecked': {
      color: theme.palette.common.white,
      '& + $iOSBar': {
        backgroundColor: '#45ad4d',
      },
    },
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp,
    }),
  },
  iOSChecked: {
    transform: 'translateX(15px)',
    '& + $iOSBar': {
      opacity: 1,
      border: 'none',
    },
  },
  iOSBar: {
    borderRadius: 13,
    width: 42,
    height: 26,
    marginTop: -13,
    marginLeft: -21,
    backgroundColor: '#1c2c4b',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  iOSIcon: {
    width: 24,
    height: 24,
  },
  iOSIconChecked: {
    boxShadow: theme.shadows[1],
  },
})

const Toggle = ({ classes, ...props }) => (
  <Switch
    {...props}
    disableRipple
    classes={{
      switchBase: classes.iOSSwitchBase,
      bar: classes.iOSBar,
      icon: classes.iOSIcon,
      iconChecked: classes.iOSIconChecked,
      checked: classes.iOSChecked,
    }}
  />
)

export default withStyles(styles)(Toggle)
