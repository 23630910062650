import React, { useRef } from 'react'
import style from './style.css'

import ArrowUp from './ArrowUp'
import ArrowDown from './ArrowDown'

const fill = (isHovered, color) => (isHovered ? 'white' : color)

function SplitQuoteArrow({ priceData, hover }) {
  const lastPrice = useRef(priceData)

  const isSamePrice = priceData === lastPrice.current
  const isUpMovement = priceData > lastPrice.current
  lastPrice.current = priceData

  return (
    <div className={style.priceArrowMovement}>
      {isSamePrice ? null : isUpMovement ? (
        <ArrowUp fill={fill(hover, '#81d31c')} />
      ) : (
        <ArrowDown fill={fill(hover, '#d90a0a')} />
      )}
    </div>
  )
}

export default SplitQuoteArrow
