import { tableColumns } from '../../common/utilities'
import { eurCurrencyCell, numberCell } from '../../common/utilities/tableCellUtils'

const columnsHead = t => {
  const c = tableColumns(t)

  return [
    c.getColumn({
      accessor: 'account',
      csvHeader: 'account',
    }),
    // c.getColumn({
    //   header: 'realizedPL',
    //   accessor: 'realizedPnL',
    // }),
    c.getFromToFloatColumn({
      header: 'unrealizedPL',
      accessor: 'unrealizedPnLEURValue',
      csvHeader: 'unrealizedPnlEur',
      filterProps: { type: 'number' },
      Cell: eurCurrencyCell,
    }),
    c.getFromToFloatColumn({
      header: 'realizedProfitEUR',
      accessor: 'realizedProfitEURValue',
      csvHeader: 'realizedProfitEur',
      filterProps: { type: 'number' },
      Cell: eurCurrencyCell,
    }),
    c.getFromToFloatColumn({
      header: 'deferralVariationMarginEUR',
      accessor: 'deferralVariationMarginEURValue',
      csvHeader: 'deferralVariationMarginEur',
      filterProps: { type: 'number' },
      Cell: eurCurrencyCell,
    }),
    // c.getFromToFloatColumn({
    //   header: 'pyl',
    //   accessor: 'pylFormat',
    //   filterProps: { type: 'number' },
    // }),
    c.getFromToFloatColumn({
      header: 'maxCollateral',
      accessor: 'customerAccountMaxMargin',
      csvHeader: 'maxCollateral',
      filterProps: { type: 'number' },
      Cell: numberCell,
    }),
    c.getFromToFloatColumn({
      header: 'intradayCollateral',
      accessor: 'customerAccountTotalMargin',
      csvHeader: 'intradayCollateralConsumptionEstimated',
      filterProps: { type: 'number' },
      Cell: numberCell,
    }),
    c.getFromToFloatColumn({
      header: 'remainingCollateral',
      accessor: 'remainingCollateral',
      csvHeader: 'remainingCollateralEstimated',
      filterProps: { type: 'number' },
      Cell: numberCell,
    }),
    c.getFromToFloatColumn({
      header: 'accountCollateral',
      accessor: 'operationAccountTotalMargin',
      csvHeader: 'accountCollateralEstimated',
      filterProps: { type: 'number' },
      Cell: numberCell,
    }),
  ]
}

export { columnsHead }
