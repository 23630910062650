export const container = iframeHeight => ({
  marginTop: '0px',
  backgroundColor: 'white',
  justifyContent: 'center',
  display: 'flex',
  width: '100%',
  minWidth: '300px',
  height: '100%',
  minHeight: iframeHeight + 100,
})

export const iframe = {
  minWidth: '220px',
  minHeight: '520px',
  height: '100%',
  width: '100%',
  border: 'none',
  margin: '20px',
}
