/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import React from 'react'
import { apply } from 'ramda'
import { Checkbox, Typography, FormControlLabel } from '@material-ui/core'
import { getOptions, sortDate, CustomSelect, sortCurrencyNumber } from '../utilities'

//Styles
const halfWidth = { width: '50%' }

const input = {
  width: '100%',
  bg: 'primary',
  color: 'text',
  border: 'none',
  fontSize: 3,
  '&:hover': {
    bg: 'secondary',
  },
}
const checkboxLabel = colorMode => ({
  fontFamily: theme => theme.fonts.tabs[colorMode],
  color: 'text',
  fontSize: 3,
})

//Generic

const optionsAll = 'ALL'

const setHeader = (title, headerStyle) => (
  <div sx={{ ...headerStyle, position: 'relative' }} className={`headerContent`}>
    <div>{title}</div>
    <div
      className={'dropArea'}
      sx={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        right: 0,
      }}
    />
  </div>
)

const MIN_FILTER = Number.NEGATIVE_INFINITY
const MAX_FILTER = Number.POSITIVE_INFINITY

//Data parsers
const parseFilterDate = date => new Date(date).setHours(0, 0, 0, 0)

//Filter Methods

/**
 * Filter method for a From/To column
 * - defaultFromValue: The from filter value when the "From" field is empty
 * - fnToApply: Function to apply to the filter values. The default is parseFloat
 */
const fromToFilterMethod = ({
  filter,
  row,
  defaultFromValue = MIN_FILTER,
  fnToApply = parseFloat,
}) => {
  const rowFilter = apply(fnToApply, [row[filter.id]])

  if (!filter.value?.fromValue && !filter.value?.toValue) {
    return true
  }
  return !filter.value?.toValue
    ? apply(fnToApply, [filter.value?.fromValue]) <= rowFilter
    : (apply(fnToApply, [filter.value?.fromValue]) || defaultFromValue) <= rowFilter &&
        rowFilter <= apply(fnToApply, [filter.value?.toValue])
}

/**
 * Filter method for a Date column
 */
const dateFromToFilterMethod = (filter, row) =>
  fromToFilterMethod({
    filter: filter,
    row: row,
    defaultFromValue: new Date(0),
    fnToApply: parseFilterDate,
  })

/**
 * Filter method for a From integer to integer column
 */
const integerFromToFilterMethod = (filter, row) =>
  fromToFilterMethod({
    filter: filter,
    row: row,
    fnToApply: parseInt,
  })

/**
 * Filter method for a From float to float column
 */
const floatFromToFilterMethod = (filter, row) =>
  fromToFilterMethod({
    filter: filter,
    row: row,
    fnToApply: parseFloat,
  })

/**
 * Filter method for a select column
 */
const selectFilterMethod = (filter, row) =>
  filter.value?.toUpperCase() === optionsAll || row[filter.id] === filter.value?.toUpperCase()

/**
 * Filter method for a checkBox column
 */
export const checkboxFilterMethod = (filter, row) => filter.value === row[filter.id]

//Filters

/**
 * Filter for a From/to column
 */
const fromToFilter = (filter, onChange, customProps) => (
  <>
    <input
      {...customProps}
      style={halfWidth}
      defaultValue={filter?.value?.fromValue || null}
      onChange={event =>
        onChange({
          fromValue: event.target.value,
          toValue: filter?.value?.toValue,
        })
      }
    />
    <input
      {...customProps}
      style={halfWidth}
      defaultValue={filter?.value?.toValue || null}
      onChange={event =>
        onChange({
          toValue: event.target.value,
          fromValue: filter?.value?.fromValue,
        })
      }
    />
  </>
)

/**
 * Filter for a Select column
 */
const selectFilter = (t, filter, onChange, options) => (
  <CustomSelect
    sx={input}
    onChange={event => onChange(event.target.value)}
    value={filter ? filter.value?.toUpperCase() : optionsAll}
  >
    {getOptions(t, [optionsAll, ...options])}
  </CustomSelect>
)

/**
 * Filter for a checkbox column
 */

export const checkboxFilter = (filter, onChange, label) => {
  const { theme, colorMode } = useThemeUI()
  return (
    <FormControlLabel
      control={
        <Checkbox
          sx={{ paddingY: '6px' }}
          style={{
            color: theme.colors.checkbox,
          }}
          checked={filter?.value}
          onChange={event => onChange(event.target.checked)}
        />
      }
      label={<Typography sx={checkboxLabel(colorMode)}>{label}</Typography>}
    />
  )
}

const defaultNumberFilterProps = { type: 'number', min: '0' }

/**
 * Returns an object containing the methods to get the defined column types
 */
export const tableColumns = t => {
  const getColumn = ({
    header,
    accessor,
    Cell,
    draggable = true,
    headerStyle,
    csvHeader = undefined,
    skipCsv = false,
    ...props
  }) => ({
    Header: setHeader(t(header || accessor), headerStyle),
    csvHeader: csvHeader || t(header || accessor),
    skipCsv: skipCsv || false,
    accessor: accessor || header,
    Cell: Cell || (({ value }) => (value || value === 0 ? value : '')),
    //For draggable columns
    draggable: draggable,
    headerClassName: (draggable && `draggableTableColumn`) || null,
    ...props,
  })
  const getSelectColumn = ({
    header,
    accessor,
    options,
    Cell,
    csvHeader = undefined,
    skipCsv = false,
    ...props
  }) => ({
    ...getColumn({ header, accessor, csvHeader, skipCsv, ...props }),
    filterMethod: selectFilterMethod,
    Filter: ({ filter, onChange }) => selectFilter(t, filter, onChange, options),
    Cell: Cell || (({ value }) => t(value) || ''),
    ...props,
  })
  const getDateColumn = ({
    header,
    accessor,
    csvHeader = undefined,
    skipCsv = false,
    ...props
  }) => ({
    ...getColumn({ header, accessor, csvHeader, skipCsv, ...props }),
    sortMethod: sortDate,
    filterMethod: dateFromToFilterMethod,
    Filter: ({ filter, onChange }) => fromToFilter(filter, onChange, { type: 'date' }),
    ...props,
  })
  const getFromToIntColumn = ({
    header,
    accessor,
    filterProps = defaultNumberFilterProps,
    csvHeader = undefined,
    skipCsv = false,
    ...props
  }) => ({
    ...getColumn({ header, accessor, csvHeader, skipCsv, ...props }),
    filterMethod: integerFromToFilterMethod,
    Filter: ({ filter, onChange }) => fromToFilter(filter, onChange, filterProps),
    ...props,
  })
  const getFromToFloatColumn = ({
    header,
    accessor,
    filterProps = defaultNumberFilterProps,
    csvHeader = undefined,
    skipCsv = false,
    ...props
  }) => ({
    ...getColumn({ header, accessor, csvHeader, skipCsv, ...props }),
    filterMethod: floatFromToFilterMethod,
    Filter: ({ filter, onChange }) => fromToFilter(filter, onChange, filterProps),
    ...props,
  })
  const getFromToCurrencyColumn = ({
    header,
    accessor,
    filterProps = defaultNumberFilterProps,
    csvHeader = undefined,
    skipCsv = false,
    ...props
  }) => ({
    ...getFromToFloatColumn({ header, accessor, filterProps, csvHeader, skipCsv, ...props }),
    sortMethod: sortCurrencyNumber,
    ...props,
  })

  return {
    getColumn: getColumn,
    getSelectColumn: getSelectColumn,
    getDateColumn: getDateColumn,
    getFromToIntColumn: getFromToIntColumn,
    getFromToFloatColumn: getFromToFloatColumn,
    getFromToCurrencyColumn: getFromToCurrencyColumn,
  }
}
