/** @jsx jsx */
import { jsx } from 'theme-ui'
import InputAdornment from '@material-ui/core/InputAdornment'

const endAdornmentStyle = {
  '& p': {
    textAlign: 'right',
    fontSize: 2,
    color: 'adornment',
    width: 'max-content',
  },
}
const EndAdornment = ({ children }) => {
  return (
    <InputAdornment disablePointerEvents position="end" sx={endAdornmentStyle}>
      {children}
    </InputAdornment>
  )
}

export default EndAdornment
