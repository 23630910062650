const onlyNumbers = /^\d*$/

export const allowOnlyNumbersOnKeyPress = e => !onlyNumbers.test(e.key) && e.preventDefault()
export const allowOnlyNumbersOnPaste = e => {
  !onlyNumbers.test(e.clipboardData && e.clipboardData.getData('Text')) && e.preventDefault()
}

export const allowOnlyNumbers = e => {
  switch (e.type) {
    case 'keypress':
      allowOnlyNumbersOnKeyPress(e)
      break
    case 'paste':
      allowOnlyNumbersOnPaste(e)
      break
  }
}

const onlyNumbersAndDot = /^\d*\.?\d*$/

export const allowOnlyNumbersAndDotOnKeyPress = e =>
  !onlyNumbersAndDot.test(e.key) && e.preventDefault()

export const allowOnlyNumbersAndDotOnPaste = e =>
  !onlyNumbersAndDot.test(e.clipboardData && e.clipboardData.getData('Text')) && e.preventDefault()

export const allowOnlyNumbersAndDot = e => {
  switch (e.type) {
    case 'keypress':
      allowOnlyNumbersAndDotOnKeyPress(e)
      break
    case 'paste':
      allowOnlyNumbersAndDotOnPaste(e)
      break
  }
}
