import React from 'react'

const ArrowDown = ({ fill = '#fff', size = '1em', ...props }) => (
  <svg viewBox="0 0 17.16 8.58" width={size} height={size} {...props}>
    <g>
      <path d="M12.16 0L8.58 8.58 5 0z" fill={fill} />
    </g>
  </svg>
)

export default ArrowDown
