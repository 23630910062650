import styled from '@emotion/styled'

export const Button = styled.button`
  border: none;
  font-size: 1rem;
  padding: 0px 3px;
  background-color: ${props => props.theme.colors.primary};
  border-left: 1px solid #1c2c4b;
  border-right: 1px solid #1c2c4b;
  color: ${props => props.theme.colors.text};
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: ${props => props.theme.colors.secondary};
  }
  &:disabled {
    background-color: ${props => props.theme.colors.disabled};
    & svg {
      color: grey;
    }
  }
`

export const Selector = styled.div`
  display: flex;
  margin-top: 1px;
  justify-content: center;
`

export const Container = styled.div`
  display: flex;
`

export const Input = styled.input`
  font-family: ${props => props.theme.fonts.body[props.colorMode]};
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.text};
  border: ${props => props.theme.borders.primary[props.colorMode]};
  border-right: ${props => props.theme.borders.aux[props.colorMode]};
  border-color: ${props => props.theme.colors.muted};
  width: 100%;
  font-size: 1rem;
  text-align: left;
  padding-left: 15px;
  min-width: 30px;
  &:hover {
    background-color: ${props => props.theme.colors.secondary};
  }
  &:disabled {
    background-color: ${props => props.theme.colors.disabled};
    &:hover {
      background-color: none;
    }
  }
  &:focus {
    outline: none;
  }
  & * {
    color: #fff;
  }
`
