/** @jsx jsx */
import { jsx } from 'theme-ui'
import { connect } from 'react-redux'
import Add from '@material-ui/icons/Add'
import React, { memo, useState, useContext } from 'react'
import { ClickAwayListener, Paper } from '@material-ui/core'

import { styles, setPos } from './style'
import InstrumentSearch from '../InstrumentSearch'
import { addCurrency } from '../../actions/workspace'

import { PermissionsContext } from '../../containers/PermissionsProvider'

const NewSpot = ({ instruments, tabIndex, addCurrency }) => {
  const [open, setOpen] = useState(false)
  const [{ x, y }, setPosition] = useState({ x: 0, y: 0 })

  const onSelectInstrument = (instrument, tabIndex) => {
    addCurrency(instrument, tabIndex)
    setOpen(false)
  }

  const handleClick = e => {
    const { left, top } = e.target.getBoundingClientRect()
    setOpen(true)
    setPosition({ x: left + 30, y: top + 100 })
  }

  const { hasAnyRole } = useContext(PermissionsContext)
  const isMemberTrader = hasAnyRole(['member_trader'])

  return (
    <>
      <Paper sx={styles(isMemberTrader)} onClick={handleClick} elevation={0} square>
        <Add fontSize="small" sx={{ pointerEvents: 'none' }} />
      </Paper>
      {open && (
        <div sx={setPos([x, y])}>
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <InstrumentSearch
              data={instruments}
              tabIndex={tabIndex}
              onSelectInstrument={onSelectInstrument}
            />
          </ClickAwayListener>
        </div>
      )}
    </>
  )
}

const mapStateToProps = ({ instruments: { data: instruments }, workspace }) => ({
  instruments,
  workspace,
})
const mapDispatchToProps = { addCurrency }

export default connect(mapStateToProps, mapDispatchToProps)(memo(NewSpot))
