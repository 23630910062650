export const input = {
  width: '100%',
  height: '100%',
  bg: 'primary',
  color: 'text',
  border: 'none',
  fontSize: 3,
  '&:hover': {
    bg: 'secondary',
  },
  '&:disabled': disabled,
}

export const datePicker = {
  '& [class*=react-datepicker__input-container]': {
    height: '100%',
  },
}

export const disabled = {
  bg: 'disabled',
  color: 'text',
  cursor: 'not-allowed',
  '&:hover': {
    bg: 'disabled',
  },
}
