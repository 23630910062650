import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { InputBase } from '@material-ui/core'
import { DialogRowElement } from '../Dialog'

export const Button = styled.button`
  border: none;
  font-size: 1rem;
  padding: 0px 3px;
  margin-left: 1px;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.text};
  border: ${props => props.theme.borders.primary[props.colorMode]};
  border-color: ${props => props.theme.colors.muted};
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: ${props => props.theme.colors.secondary};
  }
  &:disabled {
    background-color: ${props => props.theme.colors.disabled};
    & svg {
      color: grey;
    }
  }
`

export const Selector = styled.div`
  display: flex;
  margin-top: 1px;
  justify-content: center;
  width: 100%;
`

export const Container = styled.div`
  display: flex;
`
export const Input = styled(InputBase)`
  font-family: ${props => props.theme.fonts.body[props.colorMode]};
  min-width: 100px;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.text};
  border: ${props => props.theme.borders.primary[props.colorMode]};
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.muted};
  width: 100%;
  font-size: 1rem;
  text-align: left;
  padding: 0 15px;
  min-width: 30px;
  &:hover {
    background-color: ${props => props.theme.colors.secondary};
  }
  &:disabled {
    background-color: ${props => props.theme.colors.disabled};
    &:hover {
      background-color: none;
    }
  }
  &:focus {
    outline: none;
  }
  // & * {
  //   color: #fff;
  // }
`

export const ModalNumberSelectorContainer = styled(DialogRowElement)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
`

export const PricesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
`

export const PriceContent = styled.div`
  display: flex;
  padding-top: 0px;
  justify-content: flex-end;
  width: 100%;
`
const price = theme => css`
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.secondary};
  }
`

export const PriceLabel = styled.label`
  color: ${props => props.theme.colors.text};
  margin-top: 1px;
  background-color: ${props => props.theme.colors.items};
  font-size: 1rem;
  padding: 5px 15px;
  ${({ tag, theme }) => (tag ? 'font-size: 1.5rem; font-family: monospace;' : price(theme))};
`
