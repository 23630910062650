const defaultMenuItemHeight = 40

export const container = {
  zIndex: '999',
  position: 'fixed',
  width: '295px',
  textAlign: 'center',
  height: '235px',
  overflow: 'hidden',
  backgroundColor: 'muted',
  border: '1px grey solid',
  '*': { ':focus': { outline: 'none', backgroundColor: 'transparent' } },
}
export const containerByNumItems = ({
  numItems = 6,
  menuItemHeight = defaultMenuItemHeight,
  maxItems = 6,
}) => ({
  ...container,
  //add 1 item for search input
  height: `${Math.min(numItems + 1, maxItems) * menuItemHeight}px`,
})

export const getModalStyle = ({ left = 0, bottom = 0, width = 200 }) => ({
  position: 'fixed',
  zIndex: 999,
  left: `${left}px`,
  top: `${bottom}px`,
  //-2 pixels of border
  width: `${width - 2}px`,
})

export const searchInput = {
  width: '100%',
  pr: '0px',
  height: `${defaultMenuItemHeight}px`,
  bg: 'primary',
  color: 'text',
  border: 'none',
  fontSize: 3,
  '&:hover': {
    bg: 'secondary',
  },
}

export const resultBox = {
  maxHeight: '200px',
  overflowY: 'auto',
  scrollbarWidth: 'thin',
  backgroundColor: 'primary',
}

export const resultBoxByNumItems = ({
  numItems = 5,
  maxItems = 5,
  menuItemHeight = defaultMenuItemHeight,
}) => ({
  ...resultBox,
  maxHeight: `${Math.min(numItems, maxItems) * menuItemHeight}px`,
})

export const inputContainer = {
  color: 'text',
}

export const searchIcon = {
  color: 'text',
  mr: '10px',
}

export const menuItem = {
  color: 'text',
  '&:hover': {
    backgroundColor: 'secondary',
  },
}
export const menuItemHeight = height => ({
  ...menuItem,
  height: `${height || defaultMenuItemHeight}px`,
  py: '0px',
})

export const menuItemNone = {
  bg: 'disabled',
  color: 'noEdit',
  '&:hover': {
    backgroundColor: 'secondary',
  },
}
