const defaultState = {
  fetching: false,
  fetched: false,
  error: null,
  data: {},
  dataIds: [],
  groupBy: {},
}

export default (entity = 'ENTITY', keys = undefined, groupBy = undefined) => (
  state = defaultState,
  { type, payload } = {}
) => {
  switch (type) {
    case `FETCH_${entity}_PENDING`:
      return { ...state, fetching: true, fetched: false, error: null }
    case `FETCH_${entity}_REJECTED`:
      return { ...state, fetching: false, fetched: false, error: payload }
    case `FETCH_${entity}_FULFILLED`:
      if (entity === 'ACCOUNTS') {
        const derivativeAccounts = payload.derivativeOperationAccounts || []
        const spotAccounts = payload.spotOperationAccounts || []

        const data = [...derivativeAccounts, ...spotAccounts].reduce((acc, entry) => {
          return { ...acc, [getKey(keys, entry)]: { ...entry } }
        }, {})
        const dataIds = Object.keys(data)

        let groups = {}
        if (groupBy) {
          groups = [...derivativeAccounts, ...spotAccounts].reduce(
            (acc, entry) => ({
              ...acc,
              [getKey(groupBy, entry)]: { data: [], keys: [...groupBy] },
            }),
            {}
          )
          ;[...derivativeAccounts, ...spotAccounts].forEach((p, i) => {
            const getGroup = entry => getKey(groupBy, entry)
            const g = getGroup(p)
            if (groups[g]) {
              groups[g].data.push(dataIds[i])
            }
          })
        }

        return {
          ...state,
          fetching: false,
          fetched: true,
          error: null,
          data,
          dataIds,
          groupBy: groups,
        }
      } else {
        const data = payload.reduce((acc, entry) => {
          return { ...acc, [getKey(keys, entry)]: { ...entry } }
        }, {})
        const dataIds = Object.keys(data)

        let groups = {}
        if (groupBy) {
          groups = payload.reduce(
            (acc, entry) => ({
              ...acc,
              [getKey(groupBy, entry)]: { data: [], keys: [...groupBy] },
            }),
            {}
          )

          payload.forEach((p, i) => {
            const getGroup = entry => getKey(groupBy, entry)
            const g = getGroup(p)
            if (groups[g]) {
              groups[g].data.push(dataIds[i])
            }
          })
        }

        return {
          ...state,
          fetching: false,
          fetched: true,
          error: null,
          data,
          dataIds,
          groupBy: groups,
        }
      }
    default:
      return state
  }
}

function getKey(keys, entry) {
  let key = ''
  keys.forEach((k, index) => {
    if (entry[k] == null) {
      return
    }
    if (index !== 0) {
      key += ':'
    }
    key += entry[k]
  })
  return key
}
