/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as style from './style'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { I18nContext } from '../../containers/i18n'
import logo from '../../../img/logo_BME-FX.png'

const StaticLegalPage = props => {
  const { t } = useContext(I18nContext)

  return (
    <>
      <Helmet>
        <title>
          {props.title} | {t('title')}
        </title>
      </Helmet>

      <div sx={style.header}>
        <img src={logo} alt="BME FX - a SIX company" />
      </div>

      <div>
        <div sx={style.content}>{props.children}</div>
      </div>
    </>
  )
}

export default StaticLegalPage
