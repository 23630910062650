/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import React, { useState } from 'react'
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons'

import {
  ModalNumberSelectorContainer,
  PricesContainer,
  Button,
  Container,
  Input,
  Selector,
  PriceLabel,
  PriceContent,
} from './style'
import { Label } from '../Dialog'
import { FormattedNumberInput } from '../FormattedNumber'

const fixNumber = (num, decimals) => num && parseFloat((+num).toFixed(decimals))

const setVisibility = cond => ({ visibility: cond ? 'visible' : 'hidden' })

const AskBidPrices = ({ topPosition, label, onChange, askPrice, bidPrice, value, disabled }) => {
  const opositeByPosition = condition => (topPosition ? condition : !condition)
  const isAskVisible = askPrice && opositeByPosition(askPrice > value)
  const isBidVisible = bidPrice && opositeByPosition(bidPrice >= value)
  const hiddenPriceStyle = { order: topPosition ? -1 : 1 }
  const { theme } = useThemeUI()

  return (
    <PricesContainer>
      <PriceContent style={setVisibility(isAskVisible)} sx={!isAskVisible && hiddenPriceStyle}>
        <Label style={setVisibility(false)}>{label}</Label>
        <PriceLabel onClick={() => !disabled && onChange(askPrice)} theme={theme}>
          {askPrice}
        </PriceLabel>
        <PriceLabel tag>A</PriceLabel>
      </PriceContent>

      <PriceContent style={setVisibility(isBidVisible)} sx={!isBidVisible && hiddenPriceStyle}>
        <Label style={setVisibility(false)}>{label}</Label>
        <PriceLabel onClick={() => !disabled && onChange(bidPrice)} theme={theme}>
          {bidPrice}
        </PriceLabel>
        <PriceLabel tag>B</PriceLabel>
      </PriceContent>
    </PricesContainer>
  )
}

const NumberSelector = ({
  label,
  onChange,
  increment = 1,
  decimals = 0,
  askPrice,
  bidPrice,
  value,
  inputProps,
  buttonProps,
  ...props
}) => {
  const showPrices = value != null && (askPrice || bidPrice)
  const [prevValidValue, setPrevValidValue] = useState(value)
  const handleOnChange = value => {
    onChange(value)
    value && setPrevValidValue(value)
  }
  const handleChangeEvent = e => {
    handleOnChange(e.target.value)
  }
  const { colorMode } = useThemeUI()

  return (
    <ModalNumberSelectorContainer {...props}>
      {showPrices && (
        <AskBidPrices
          topPosition
          label={label}
          onChange={onChange}
          askPrice={fixNumber(askPrice, decimals)}
          bidPrice={fixNumber(bidPrice, decimals)}
          value={value}
          disabled={props.disabled}
        ></AskBidPrices>
      )}

      <Selector>
        {label && <Label>{label}</Label>}
        <Input
          {...inputProps}
          value={value ? value : ''}
          onChange={handleChangeEvent}
          inputProps={{
            maxLength: 50,
            decimals: decimals,
            style: {
              padding: 0,
            },
          }}
          fullWidth={true}
          inputComponent={FormattedNumberInput}
          name="number-selector"
          id="number-selector-input"
          //colorMode={colorMode}
        />
        <Container>
          <Button
            {...buttonProps}
            disabled={value <= increment}
            onClick={() => handleOnChange(fixNumber(+value - +increment, decimals))}
            colorMode={colorMode}
          >
            <ArrowDropDown />
          </Button>
          <Button
            {...buttonProps}
            onClick={() => handleOnChange(fixNumber(+value + +increment, decimals))}
            colorMode={colorMode}
          >
            <ArrowDropUp />
          </Button>
        </Container>
      </Selector>

      {showPrices && (
        <AskBidPrices
          label={label}
          onChange={onChange}
          askPrice={askPrice}
          bidPrice={bidPrice}
          value={value}
          disabled={props.disabled}
        ></AskBidPrices>
      )}
    </ModalNumberSelectorContainer>
  )
}

export default NumberSelector
