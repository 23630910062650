import { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { updateDynamicSubscriptions } from '../actions/marketData'
import { accountsSelector } from '../selectors/accounts'
import { createSelector } from 'reselect'

function Resuscriber({
  //State
  accounts,
  //Dispatch
  updateDynamicSubscriptions,
}) {
  const notInitialRender = useRef(false)

  useEffect(() => {
    // Resuscribe only if this is not the initial render
    if (notInitialRender.current) {
      updateDynamicSubscriptions(accounts)
    } else {
      notInitialRender.current = accounts !== null
    }
  }, [accounts])

  return null
}

const mapStateToProps = (state, ownProps) =>
  createSelector(accountsSelector, accounts => ({ accounts }))(state, ownProps)

const mapDispatchToProps = {
  updateDynamicSubscriptions,
}

export default connect(mapStateToProps, mapDispatchToProps)(Resuscriber)
