/** @jsx jsx */
import { jsx } from 'theme-ui'
import { connect } from 'react-redux'
import React, { useState, useContext } from 'react'
import Menu from '@material-ui/core/Menu'
import { MenuItem } from '../common/utilities'
import PersonPin from '@material-ui/icons/PersonPin'
import IconButton from '@material-ui/core/IconButton'
import { showUserSettings, saveWorkspace } from '../actions/workspace'
import { I18nContext } from '../containers/i18n'

const ITEM_HEIGHT = 145
const tabOptions = ['preferences', 'account', 'logout']

const UserMenu = ({ option, keycloak, workspace, showUserSettings }) => {
  const { t } = useContext(I18nContext)
  const [anchorEl, setAnchor] = useState(null)

  const handleUserMenu = option => {
    switch (option) {
      case 'logout':
        keycloak
          .updateToken(30)
          .success(() => saveWorkspace({ token: keycloak.token, data: workspace }))
        keycloak.logout()
        break
      case 'account':
        keycloak.accountManagement()
        break
      case 'preferences':
        showUserSettings()
        break
      default:
        break
    }
    setAnchor(null)
  }

  return (
    <div>
      <IconButton
        aria-label={t('more')}
        aria-owns={anchorEl && 'long-menu'}
        aria-haspopup="true"
        onClick={e => setAnchor(e.currentTarget)}
        color="inherit"
        sx={{ '&:hover': { backgroundColor: 'transparent' } }}
      >
        <PersonPin sx={{ color: 'topBarElements' }} />
      </IconButton>
      <Menu
        id="long-menu"
        disableAutoFocusItem
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchor(null)}
        option={option}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT,
            width: 200,
            borderRadius: 0,
            boxShadow: 'none',
          },
        }}
        MenuListProps={{ disablePadding: true }}
      >
        {tabOptions.map(option => (
          <MenuItem key={option} onClick={() => handleUserMenu(option)}>
            {t(option)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

const mapStateToProps = ({ workspace }) => ({ workspace })

const mapDispatchToProps = { showUserSettings, saveWorkspace }

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu)
