const yymm = new Intl.DateTimeFormat('en-GB', { year: '2-digit', month: '2-digit' })

const short = new Intl.DateTimeFormat('en-GB', {
  day: '2-digit',
  month: '2-digit',
  year: '2-digit',
})

const long = new Intl.DateTimeFormat('en-GB', {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
})

const longWithTime = new Intl.DateTimeFormat('en-GB', {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
})

const longMilisWithTime = new Intl.DateTimeFormat('en-GB', {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  fractionalSecondDigits: 3,
})

export const fmtDateyyyMMdd = data => {
  const date = new Date(data)
  const day = ('0' + date.getDate()).slice(-2)
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  return date.getFullYear() + month + day
}

export const parseDateFromyyyMMdd = dateString => {
  if (!dateString || dateString.length < 8) {
    return null
  }
  const y = parseInt(dateString.substr(0, 4)),
    m = parseInt(dateString.substr(4, 2) - 1), //months are 0-based in js
    d = parseInt(dateString.substr(6, 2))
  const D = new Date(y, m, d)
  return D.getFullYear() === y && D.getMonth() === m && D.getDate() === d ? D : null
}

export const fmtDate = (date, newDate = new Date(date)) => (date ? short.format(newDate) : '')
export const fmtDateShort = (date, newDate = new Date(date)) => (date ? yymm.format(newDate) : '')
export const fmtDateLong = (date, newDate = new Date(date)) =>
  date ? longWithTime.format(newDate) : ''
export const fmtDateLongMili = (date, newDate = new Date(date)) =>
  date ? longMilisWithTime.format(newDate) : ''
export const fmtDateLongFromyyyMMdd = (date, newDate = parseDateFromyyyMMdd(date)) =>
  date ? long.format(newDate) : ''
