/** @jsx jsx */
import { jsx } from 'theme-ui'
import InputBase from '@material-ui/core/InputBase'
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons'
import EndAdornment from '../EndAdornment'
import InputAdornment from '@material-ui/core/InputAdornment'

import * as style from './style'

const ml = { marginLeft: '-1px' }

const calcPrice = (price, decimals, oldPrice) => {
  if (price?.match(/^([0-9]+\.?[0-9]*)$/)) {
    const reg = new RegExp(`^[0-9]+[\.]+[0-9]{${decimals + 1},}$`)
    if (price.match(reg)) {
      const [int, dec] = price.split('.')
      return !dec ? Number(int).toFixed(decimals) : `${int}.${dec.substring(0, decimals)}`
    }
    return price
  }
  return price?.match(/^[0-9]+(\.?[0-9]*)*$/) || price === '' ? price : String(oldPrice)
}

const incrementPrice = (direction, price, increment, decimals = 0) => {
  const result =
    direction === 'up'
      ? (+price + +increment).toFixed(decimals)
      : (+price - +increment).toFixed(decimals)
  return +result < 0 ? `0.${'0'.repeat(decimals)}` : result
}

const PriceSelector = ({
  editPrice,
  type,
  price,
  updateTradePanel,
  enableEditPrice,
  instrument,
  tabIndex,
  disabled,
  endAdornmentText,
  maxLength = '10',
  currentPrice,
}) => {
  const showStartAdornment = !(!disabled && editPrice) || !price
  const startAdornmentChild = price && (
    <span sx={{ position: 'relative' }}>
      <span>{price.bigFigure}</span>
      <span sx={style.pip}>{price.pips}</span>
      <span>{price.fractionalPips}</span>
    </span>
  )
  const endAdornment = endAdornmentText && <EndAdornment>{endAdornmentText}</EndAdornment>
  const priceComplete = price?.bigFigure + price?.pips + price?.fractionalPips
  const { priceDecimals = 1, minPriceIncrement = 0.1 } = instrument || {}
  return (
    <div sx={style.numbers}>
      {
        <InputBase
          inputProps={{
            maxLength: maxLength,
          }}
          startAdornment={
            showStartAdornment &&
            startAdornmentChild && (
              <InputAdornment disablePointerEvents>{startAdornmentChild}</InputAdornment>
            )
          }
          endAdornment={endAdornment}
          sx={
            !disabled
              ? {
                  ...style.input,
                  ...(showStartAdornment && { '& input': { color: 'transparent' } }),
                }
              : { ...style.input, ...style.inputBaseDisabled }
          }
          disabled={disabled}
          invalid={price?.error ? price.error : undefined}
          value={priceComplete || ''}
          onChange={e =>
            updateTradePanel({
              [type]: calcPrice(e.target.value, priceDecimals || 6, priceComplete),
              tabIndex,
            })
          }
          onFocus={() => enableEditPrice(true)}
          onBlur={() => {
            currentPrice === priceComplete &&
              !isNaN(priceComplete) &&
              updateTradePanel({
                [type]: priceComplete ? Number(priceComplete).toFixed(priceDecimals) : 0,
                tabIndex,
              })
            enableEditPrice(false)
          }}
        />
      }
      <div sx={style.container}>
        <button
          sx={{ ...style.button, ...ml, ...(disabled && { cursor: 'not-allowed' }) }}
          disabled={price?.error || disabled}
          onClick={() =>
            updateTradePanel({
              [type]: incrementPrice(
                'down',
                priceComplete,
                instrument ? minPriceIncrement : 1,
                priceDecimals
              ),
              tabIndex,
            })
          }
        >
          <ArrowDropDown fontSize="large" />
        </button>
        <button
          sx={{ ...style.button, ...(disabled && { cursor: 'not-allowed' }) }}
          disabled={price?.error || disabled}
          onClick={() =>
            updateTradePanel({
              [type]: incrementPrice(
                'up',
                priceComplete,
                instrument ? minPriceIncrement : 1,
                priceDecimals
              ),
              tabIndex,
            })
          }
        >
          <ArrowDropUp fontSize="large" />
        </button>
      </div>
    </div>
  )
}

export default PriceSelector
