export const topBar = {
  backgroundColor: 'topBarBack',
}

export const drawerOpen = {
  backgroundColor: 'drawerOpen',
  height: '100%',
}

export const drawerHide = {
  backgroundColor: 'drawerHide',
  height: '100%',
}

export const menuButtonStyle = {
  fill: 'topBarElements',
  width: '25px',
}

export const logo = {
  height: '50px',
  position: 'relative',
  top: '2px',
  left: '-10px',
  fill: 'topBarElements',
}

export const logoBm = {
  height: '30px',
  fill: 'topBarElements',
}

export const logoBmeDark = {
  height: '50px',
  position: 'relative',
  top: '2px',
  left: '-10px',
  fill: 'topBarElements',
}

export const logoIgf = {
  height: '30px',
  fill: 'topBarElements',
  position: 'absolute',
  right: '792px',
  top: '17px',
}

export const logoMtx = {
  height: '30px',
  fill: 'topBarElements',
  position: 'absolute',
  top: '17px',
  right: '590px',
}

export const accountList = colorMode => ({
  width: '150px',
  padding: '6px 14px',
  fontSize: 2,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: 'text',
  border: theme => theme.borders.primary[colorMode],
  borderColor: 'muted',
})

export const input = {
  width: '100%',
  bg: 'primary',
  color: 'text',
  border: 'none',
  fontSize: 3,
  '&:hover': {
    bg: 'secondary',
  },
}

export const anyadirMenu = {
  height: '18px',
  fill: 'topBarElements',
}

export const addWorkspace = {
  position: 'absolute',
  right: '47px',
  bottom: '8px',
  '&:hover': {
    backgroundColor: 'none',
  },
}

export const logout = {
  position: 'absolute',
  right: '0px',
  bottom: '7px',
}

export const root = {
  flexGrow: 1,
  zIndex: 1,
  overflow: 'hidden',
  display: 'flex',
  backgroundColor: 'topBarBack',
  height: '100vh',
}

// Material Stuff
export const drawerWidth = 240

export const appBar = theme => ({
  zIndex: 900 + 1,
  height: '60px',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
})

export const appBarShift = theme => ({
  width: '100%',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
})

export const menuButtonBackground = {
  marginTop: '-16px',
  width: '60px',
  marginRight: '10px',
  height: '60px',
  display: 'flex',
  justifyContent: 'center',
  cursor: 'pointer',
}

export const menuButton = {
  height: '25px',
}

export const rotation = {
  transform: 'rotate(-90deg)',
}

export const hide = {
  display: 'none',
}

export const drawerPaper = theme => ({
  position: 'relative',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  zIndex: 900,
  width: drawerWidth,
  overflow: 'hidden',
  backgroundColor: 'inherit',
  border: 'none',
  marginTop: '-5px',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
})

export const drawerPaperClose = theme => ({
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: theme.spacing.unit * 7,
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing.unit * 9,
  },
  backgroundColor: 'inherit',
  border: 'none',
  marginTop: '-5px',
})

export const toolbar = theme => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '0 8px',
  ...theme.mixins.toolbar,
})

export const content = theme => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.default,
  padding: '11px 12px',
})

export const changeTheme = {
  position: 'absolute',
  right: '200px',
  width: '150px',
}

const style = theme => {
  theme.zIndex.drawer = 900
  return {
    drawerPaper: {
      position: 'relative',
      flexShrink: 0,
      whiteSpace: 'nowrap',
      zIndex: theme.zIndex.drawer,
      width: drawerWidth,
      overflow: 'hidden',
      backgroundColor: 'inherit',
      border: 'none',
      marginTop: '-5px',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing.unit * 7,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing.unit * 9,
      },
      backgroundColor: 'inherit',
      border: 'none',
      marginTop: '-5px',
    },
  }
}

export const setPos = ([x, y]) => ({
  position: 'fixed',
  zIndex: 999,
  left: `${x}px`,
  top: `${y}px`,
})

export const account = {
  display: 'flex',
  position: 'absolute',
  right: '356px',
  bg: 'primary',
  '&:hover': {
    bg: 'secondary',
  },
}

export const moreAccounts = colorMode => ({
  color: 'text',
  paddingRight: '4px',
  paddingTop: '2px',
  border: theme => theme.borders.primary[colorMode],
  borderColor: 'muted',
})

export const hedgingSim = {
  display: 'flex',
  position: 'absolute',
  right: '110px',
  a: {
    width: '130px',
    height: '33px',
  },
}

export const kidButton = {
  display: 'flex',
  position: 'absolute',
  right: '265px',
  button: {
    width: '65px',
    height: '33px',
  },
}

export default style
