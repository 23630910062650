import React from 'react'

const ArrowUp = ({ fill = '#fff', size = '1em', ...props }) => (
  <svg viewBox="0 0 17.16 8.58" width={size} height={size} {...props}>
    <g>
      <path d="M5 8.58L8.58 0l3.58 8.58z" fill={fill} />
    </g>
  </svg>
)

export default ArrowUp
