const defaultState = {
  connected: false,
  data: {},
  dataIds: [],
  accountsWithPositions: [],
  positionSubscribed: false,
  calculatedPositions: [],
  calculatedAccountPositions: [],
  accountsSummaries: [],
  count: 0,
}

export default (state = defaultState, { type, payload } = {}) => {
  switch (type) {
    case 'POSITION_SOCKET_CONNECTED':
      return {
        ...state,
        connected: true,
      }
    case 'POSITION_SOCKET_DISCONNECTED':
      return {
        ...state,
        connected: false,
      }
    case 'POSITION_LIST_DATA':
      return {
        ...state,
        data: payload.positions,
        dataIds: payload.positionIds,
        accountsWithPositions: payload.accountsWithPositions,
      }
    case 'POSITION_LIST_UPDATE':
      const updatedData = { ...state.data, [payload.key]: payload.position }
      if (!state.dataIds.includes(payload.key)) {
        return {
          ...state,
          data: updatedData,
          dataIds: Object.keys(updatedData),
          accountsWithPositions: state.accountsWithPositions.includes(payload.position.account)
            ? state.accountsWithPositions
            : [...state.accountsWithPositions, payload.position.account],
        }
      }
      return {
        ...state,
        data: updatedData,
      }
    case 'SET_POSITION_TABS':
      return {
        ...state,
        count: payload,
      }
    case 'POSITIONS_SUBSCRIBED':
      return {
        ...state,
        positionSubscribed: true,
      }
    case 'POSITIONS_UNSUBSCRIBED':
      return {
        ...state,
        data: [],
        dataIds: [],
        positionSubscribed: false,
        calculatedPositions: [],
        calculatedAccountPositions: [],
        accountsSummaries: [],
      }
    case 'SET_CALCULATED_POSITIONS':
      return {
        ...state,
        calculatedPositions: payload.calculatedPositions,
        calculatedAccountPositions: payload.calculatedAccountPositions,
        accountsSummaries: payload.accountsSummaries,
      }
    default:
      return state
  }
}
