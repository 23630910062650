/** @jsx jsx */
import { jsx } from 'theme-ui'
import { I18nContext } from '../../containers/i18n'
import { useContext, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import * as style from './style'

const MarketDepth = () => {
  const { t } = useContext(I18nContext)

  const [iframeHeight, setIframeHeight] = useState(null)

  const iframeSize = useCallback(node => {
    if (node) {
      const height = node.getBoundingClientRect().height
      if (iframeHeight !== height) {
        setIframeHeight(height)
      }
    }
  }, [])

  return (
    <div sx={style.container(iframeHeight)}>
      <iframe src={t('marketDepthUrl')} scrolling="no" sx={style.iframe} ref={iframeSize} />
    </div>
  )
}

export default connect()(withStyles(style, { withTheme: true })(MarketDepth))
