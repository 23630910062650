/** @jsx jsx */
import { jsx } from 'theme-ui'
import StaticLegalPage from '../../components/StaticLegalPage'

const CookiePolicy = () => {
  return (
    <StaticLegalPage title="Política de Cookies">
      <h2>Política de Cookies</h2>

      <p>
        Este sitio web utiliza cookies. Las cookies son ficheros creados en el navegador del usuario
        de esta Página Web para registrar su actividad de navegación en este sitio web.
      </p>
      <p>
        Las cookies utilizadas en esta Página Web permiten registrar su actividad de navegación para
        la elaboración de informes estadísticos.
      </p>
      <p>
        La información recogida será empleada por la Sociedad para conocer la frecuencia y modo de
        uso de la Web a partir de los datos de conexión y actividad de los visitantes. La
        información recabada no identifica, en modo alguno, ni puede identificar personalmente al
        usuario ni cualquier otra información privada.
      </p>
      <p>
        Para recabar esta información y tratarla de manera estadísticamente la Sociedad emplea la
        herramienta Google Analytics, propiedad de Google con el fin único de obtener información
        anónima de los usuarios de esta Web. Puede encontrar las políticas de privacidad y de Google
        Analytics en el{' '}
        <a
          href="https://www.google.com/intl/es/policies/privacy/"
          target="_blank"
          rel="nofollow noopener"
        >
          centro de privacidad de Google
        </a>{' '}
        y el{' '}
        <a
          href="https://tools.google.com/dlpage/gaoptout?hl=es"
          target="_blank"
          rel="nofollow noopener"
        >
          complemento de inhabilitación de Google Analytics
        </a>
        .
      </p>
      <p>
        No obstante, para proceder a la navegación no es necesaria la instalación de cookies. El
        usuario puede no aceptarlas, procediendo a configurar en su navegador la opción de bloqueo o
        eliminación, en su caso, de las citadas cookies.
      </p>
    </StaticLegalPage>
  )
}

export default CookiePolicy
