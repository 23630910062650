/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

const IndeterminateLoader = ({ visible = true }) => {
  return visible ? (
    <div
      sx={{
        minWidth: '100px',
        minHeight: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress sx={{ color: 'secondary' }} />
    </div>
  ) : null
}

export default IndeterminateLoader
