/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import { connect } from 'react-redux'
import React, { useState, useContext, memo } from 'react'

import * as style from './style'
import SplitQuoteArrow from '../SplitQuoteArrow'
import { renderPips } from '../../common/utilities'
import { I18nContext } from '../../containers/i18n'
import { showErrorDialog } from '../../actions/workspace'
import { makeOrderWithConfirm, makeOrder } from '../../actions/orders'
import { PermissionsContext } from '../../containers/PermissionsProvider'
import { accountsSelector } from '../../selectors/accounts'
import { confirmDialogSelector, currentWorkspaceSelector } from '../../selectors/workspace'
import { createSelector } from 'reselect'
import { sendOrder } from '../../common/utilities/orderUtils'


const PriceButton = ({
  ask,
  priceData,
  instrument,
  isConfirmDialogRequired,
  spotIndex,
  tabIndex,
  currentWorkspace,
  makeOrderWithConfirm,
  makeOrder,
  keycloak,
  showErrorDialog,
  currentAccount,
}) => {
  const { t } = useContext(I18nContext)
  const [hover, setHover] = useState(false)
  const { hasAnyRole } = useContext(PermissionsContext)

  const direction = ask ? 'BUY' : 'SELL'
  const action = ask ? 'BUYACTION' : 'SELLACTION'
  const price = priceData !== '' ? renderPips(priceData, instrument) : ''
  const base = price.bigFigure ? instrument?.baseCurrency : ''

  const { colorMode } = useThemeUI()

  const handleModal = message =>
    showErrorDialog({
      title: t('error').toUpperCase(),
      type: 'ERROR',
      message: message,
    })

  const make = payload => {
    sendOrder({
      payload,
      currentAccount,
      instrument,
      hasAnyRole,
      t,
      isConfirmDialogRequired,
      makeOrderWithConfirm,
      makeOrder,
      keycloak,
      handleModal,
    })
  }

  return (
    <>
      <div
        sx={style.setStyle(ask)}
        onClick={() => {
          const { amountToOperate, currency, clientId, decisorId, executorId, tradingCapacity } = currentWorkspace.tabs[tabIndex].spots[spotIndex]

          make({
            side: direction,
            price: priceData,
            orderType: 'MARKET',
            quantity: amountToOperate,
            currency,
            clientId,
            decisorId,
            executorId,
            tradingCapacity,
            title: t('confirmOrder'),
          })
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div sx={style.direction(colorMode)}>
          {t(action)} {base}
        </div>
        <span sx={style.bigFigure(colorMode)}>
          <span sx={style.fig(colorMode)}>{price.bigFigure}</span>
          <br />
          <span sx={style.pip(colorMode)}>{price.pips}</span>
          <span
            sx={
              price?.fractionalPips?.length > 1
                ? style.fractionalPipsLong(colorMode)
                : style.fractionalPips(colorMode)
            }
          >
            {price.fractionalPips}
          </span>
        </span>
        {price.bigFigure && <SplitQuoteArrow hover={hover} priceData={priceData} />}
      </div>
    </>
  )
}


const mapStateToProps = (state, ownProps) =>
  createSelector(
    currentWorkspaceSelector,
    confirmDialogSelector,
    accountsSelector,
    (
      currentWorkspace,
      isConfirmDialogRequired,
      currentAccount
    ) => ({
      currentWorkspace,
      isConfirmDialogRequired,
      currentAccount
    })
  )(state, ownProps)

const mapDispatchToProps = {
  makeOrderWithConfirm,
  makeOrder,
  showErrorDialog,
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(PriceButton))
