/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui'
import baseTheme from '../../../containers/theme'

import React, { useState } from 'react'
import { Settings as SettingsIcon } from '@material-ui/icons'

const Settings = ({ onClick }) => {
  const [theme] = useColorMode()
  const [isOpen, setIsOpen] = useState(true)
  const backColor = isOpen ? {} : { backgroundColor: baseTheme.icon[theme] }
  return (
    <SettingsIcon
      onMouseDown={() => {
        setIsOpen(!isOpen)
        onClick(isOpen)
      }}
      style={{
        width: '17px',
        height: '26px',
        fill: 'white',
        padding: '4px 8px',
        position: 'relative',
        top: '8px',
        left: '1px',
        cursor: 'pointer',
        ...backColor,
      }}
    />
  )
}

export default Settings
