const notAllowed = 'not-allowed'

const spbt = {
  display: 'flex',
  justifyContent: 'space-between',
}

export const numbers = {
  ...spbt,
  height: '52px',
}

export const container = {
  ...spbt,
  textAlign: 'center',
  width: '21%',
  height: '42px',
  my: '5px',
}

export const button = {
  height: '42px',
  width: '48.6%',
  border: 'none',
  bg: 'primary',
  color: 'text',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    bg: 'secondary',
  },
  '&:focus': {
    outline: 0,
  },
  '&:disabled': {
    bg: 'disabled',
    color: 'noEdit',
  },
}

export const pip = {
  fontWeight: 'bold',
  fontSize: '1.5rem',
}

export const input = {
  alignItems: 'center',
  bg: 'primary',
  border: 'none',
  color: 'text',
  display: 'flex',
  fontSize: 4,
  my: '5px',
  p: '11px 14px',
  width: '69%',
  '&:hover': {
    bg: 'secondary',
  },
  '& svg': {
    color: 'text',
  },
  '&:invalid': {
    border: 'solid 2px #f44336',
    p: '16.5px 12px',
  },
}

export const inputBaseDisabled = {
  bg: 'disabled',
  '&:hover': {
    bg: 'disabled',
    cursor: notAllowed,
  },
  '& input': {
    color: 'transparent',
    cursor: notAllowed,
  },
  '& span': {
    color: 'text',
    cursor: notAllowed,
  },
  cursor: notAllowed,
}
