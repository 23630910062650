import styled from '@emotion/styled'

export const Input = styled.input`
  font-family: ${props => props.theme.fonts.body[props.colorMode]};
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.text};
  border: ${props => props.theme.borders.primary[props.colorMode]};
  border-right: ${props => props.theme.borders.aux[props.colorMode]};
  border-color: ${props => props.theme.colors.muted};
  width: 100%;
  font-size: 1rem;
  text-align: left;
  padding-left: 15px;
  min-width: 30px;

  &:hover {
    background-color: ${props => props.theme.colors.secondary};
  }
  &:disabled {
    background-color: ${props => props.theme.colors.disabled};
    &:hover {
      background-color: none;
    }
  }
  &:focus {
    outline: none;
  }
  & * {
    color: #fff;
  }
`
