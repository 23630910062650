export const customButton = {
  margin: '10px',
}

export const checkText = {
  cursor: 'pointer',
  fontSize: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  paddingRight: '2px',
}

export const labelTitle = {
  fontSize: '20px',
  fontWeight: '400',
  color: 'modalTitle',
  top: '-3px',
  position: 'relative',
}

export const text = {
  fontSize: 1,
}

export const checkBox = {
  paddingRight: '2px',
  color: theme => `${theme.colors.checkboxModal} !important`,
}

export const message = {
  textTransform: 'uppercase',
}
