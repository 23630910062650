/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import * as React from 'react'
import { Button, Menu } from '@material-ui/core'

const DropdownButton = ({ buttonType, label, onClick, onClose, children, anchorEl, ...props }) => {
  const open = Boolean(anchorEl)
  const { theme, colorMode } = useThemeUI()

  return (
    <>
      <Button
        id="kid-button"
        aria-controls={open ? 'kid-button' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={onClick}
        sx={{ variant: `buttons.${buttonType}` }}
      >
        {label}
      </Button>
      <Menu
        disableAutoFocusItem={true}
        id="kid-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{
          disablePadding: true,
          style: {
            padding: 0,
            color: 'white',
          },
        }}
        PaperProps={{
          style: {
            borderRadius: 0,
            boxShadow: 'none',
            border: theme.borders.primary[colorMode],
            borderColor: theme.colors.muted,
          },
        }}
      >
        {children}
      </Menu>
    </>
  )
}

export default DropdownButton
