export const findInstrument = (instrument, tick, account) => {
  if (!instrument) {
    return undefined
  }

  if (instrument?.marketNeedsAccount) {
    return tick?.[instrument.exchangeId]?.[instrument.symbol]?.[account]
  } else {
    return tick?.[instrument.exchangeId]?.[instrument.symbol]?.['']
  }
}

export const findInstrumentWithMarketSymbol = (exchangeId, symbol, tick, instruments, account) => {
  const instrument = instruments?.[`${exchangeId}:${symbol}`]
  if (!instrument) {
    return undefined
  }

  if (instrument?.marketNeedsAccount) {
    return tick?.[instrument.exchangeId]?.[instrument.symbol]?.[account]
  } else {
    return tick?.[instrument.exchangeId]?.[instrument.symbol]?.['']
  }
}

export const getEurExchangeRate = (quote, tick, market, instruments, account) => {
  if (quote === 'EUR') {
    return 1
  }
  const quoteEuro = findInstrumentWithMarketSymbol(
    market,
    `${quote}EUR`,
    tick,
    instruments,
    account
  )
  if (quoteEuro) {
    const bidPlusAskEurUsd = +quoteEuro.askPrice + +quoteEuro.bidPrice
    return bidPlusAskEurUsd ? bidPlusAskEurUsd / 2 : 0
  }

  const euroQuote = findInstrumentWithMarketSymbol(
    market,
    `EUR${quote}`,
    tick,
    instruments,
    account
  )
  if (euroQuote) {
    const bidPlusAskEurUsd = +euroQuote.askPrice + +euroQuote.bidPrice
    return bidPlusAskEurUsd ? 1 / (bidPlusAskEurUsd / 2) : 0
  }

  const tradeEurUsd = findInstrumentWithMarketSymbol(market, 'EURUSD', tick, instruments, account)
  if (!tradeEurUsd) return 0
  const bidPlusAskTradeEurUsd = +tradeEurUsd.askPrice + +tradeEurUsd.bidPrice

  const tradeQuoteUsDollar =
    quote !== 'USD' &&
    findInstrumentWithMarketSymbol(market, `${quote}USD`, tick, instruments, account)
  if (tradeQuoteUsDollar && tradeEurUsd) {
    const bidPlusAskTradeQuote = +tradeQuoteUsDollar.askPrice + +tradeQuoteUsDollar.bidPrice
    return bidPlusAskTradeQuote && bidPlusAskTradeEurUsd
      ? (bidPlusAskTradeQuote / 2) * (1 / (bidPlusAskTradeEurUsd / 2))
      : 0
  }

  const tradeUsDollarQuote = findInstrumentWithMarketSymbol(
    market,
    `USD${quote}`,
    tick,
    instruments,
    account
  )
  if (tradeUsDollarQuote && tradeEurUsd) {
    const bidPlusAskTradeUsDollarQuote =
      +tradeUsDollarQuote.askPrice + +tradeUsDollarQuote.bidPrice || 0
    return bidPlusAskTradeUsDollarQuote && bidPlusAskTradeEurUsd
      ? (1 / (bidPlusAskTradeUsDollarQuote / 2)) * (1 / (bidPlusAskTradeEurUsd / 2))
      : 0
  }
  return 0
}
