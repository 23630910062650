/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'

const burgerBtn = style => (
  <svg sx={style} viewBox="0 0 33.17 28.72">
    <path sx={style} d="M0,28.72H33.17V23H0ZM0,17.23H33.17V11.49H0ZM0,0V5.74H33.17V0Z" />
  </svg>
)

const leftArrow = style => (
  <div style={{ transform: 'rotate(-90deg)', marginLeft: '28px' }}>
    <svg sx={style} viewBox="0 0 17.37 10.73">
      <path sx={style} d="M15.33,10.73,8.69,4.1,2,10.73l-2-2L8.69,0l8.68,8.69Z" />
    </svg>
  </div>
)

// Aplicados stilos directamente con sx
const logo = style => (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 163.1 56.7"
    sx={{ enableBackground: 'new 0 0 163.1 56.7', ...style }}
  >
    <g>
      <path
        sx={{ fill: '#FFFFFF' }}
        d="M57.2,48c1.2,0,1.8,0.7,1.8,2v3.8h-0.9L58,53.1l0,0c-0.2,0.3-0.4,0.5-0.6,0.7c-0.4,0.1-0.7,0.2-1,0.2
		c-0.5,0-0.8-0.2-1.1-0.5c-0.2-0.3-0.4-0.8-0.4-1.3c0-0.6,0.2-1,0.5-1.3s0.9-0.5,1.6-0.5h0.8v-0.3c0-0.4-0.1-0.6-0.2-0.8
		C57.5,49.1,57.3,49,57,49c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.4,0.2-0.7,0.3l-0.4-0.9C55.9,48.2,56.5,48,57.2,48z M57.8,51.2h-0.5
		c-0.4,0-0.7,0.1-0.9,0.3s-0.3,0.4-0.3,0.7c0,0.6,0.2,0.8,0.7,0.8c0.3,0,0.5-0.1,0.7-0.4c0.2-0.2,0.3-0.6,0.3-1V51.2z"
      />
      <path
        sx={{ fill: '#FFFFFF' }}
        d="M66,51.8c0,0.4-0.1,0.8-0.3,1.2c-0.2,0.3-0.5,0.6-0.8,0.8s-0.8,0.3-1.2,0.3c-0.3,0-0.7,0-1-0.1
		s-0.6-0.2-0.9-0.3v-1.3c0.3,0.2,0.6,0.3,0.9,0.4c0.3,0.1,0.6,0.1,0.9,0.1c0.3,0,0.6-0.1,0.8-0.3s0.3-0.4,0.3-0.7s-0.1-0.5-0.3-0.7
		c-0.2-0.2-0.5-0.4-1-0.6c-0.3-0.2-0.6-0.3-0.8-0.5s-0.4-0.4-0.6-0.7c-0.1-0.3-0.2-0.6-0.2-1c0-0.6,0.2-1.1,0.6-1.5
		c0.4-0.4,0.9-0.6,1.6-0.6c0.3,0,0.6,0,1,0.1c0.3,0.1,0.6,0.2,0.9,0.3l-0.4,1.1c-0.5-0.3-1-0.4-1.4-0.4c-0.3,0-0.5,0.1-0.7,0.2
		c-0.2,0.2-0.2,0.4-0.2,0.6c0,0.2,0,0.4,0.1,0.5s0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.3,0.7,0.4c0.5,0.3,0.9,0.6,1.2,0.9
		C65.9,50.8,66,51.3,66,51.8z"
      />
      <path
        sx={{ fill: '#FFFFFF' }}
        d="M69.3,53.9h-2.8v-0.7l0.8-0.3v-5.6L66.5,47v-0.7h2.8V47l-0.8,0.3v5.6l0.8,0.3V53.9z"
      />
      <path
        sx={{ fill: '#FFFFFF' }}
        d="M74.7,53.9h-1.4l-1.2-2.8l-1.3,2.8h-1.3l1.9-3.9l-1.7-3.7H71l1.1,2.6l1.1-2.6h1.3L72.8,50L74.7,53.9z"
      />
      <path
        sx={{ fill: '#FFFFFF' }}
        d="M79.2,54c-0.7,0-1.3-0.2-1.7-0.7c-0.4-0.5-0.6-1.2-0.6-2.2c0-0.9,0.2-1.7,0.6-2.2s1-0.8,1.7-0.8
		c0.3,0,0.6,0,0.8,0.1c0.2,0.1,0.4,0.1,0.6,0.3l-0.3,1c-0.2-0.1-0.3-0.2-0.5-0.2s-0.3-0.1-0.5-0.1c-0.7,0-1.1,0.7-1.1,2
		s0.4,1.9,1.1,1.9c0.2,0,0.4,0,0.6-0.1s0.4-0.2,0.6-0.3v1C80.2,53.9,79.7,54,79.2,54z"
      />
      <path
        sx={{ fill: '#FFFFFF' }}
        d="M85.7,51c0,0.6-0.1,1.1-0.2,1.5c-0.2,0.4-0.4,0.8-0.8,1.1c-0.3,0.3-0.8,0.4-1.3,0.4s-0.9-0.1-1.2-0.4
		c-0.3-0.3-0.6-0.6-0.8-1.1c-0.2-0.5-0.3-1-0.3-1.6c0-0.9,0.2-1.6,0.6-2.2c0.4-0.5,1-0.8,1.7-0.8s1.2,0.3,1.7,0.8
		C85.5,49.3,85.7,50.1,85.7,51z M82.4,51c0,0.6,0.1,1.1,0.2,1.5c0.2,0.3,0.4,0.5,0.8,0.5s0.6-0.2,0.8-0.5c0.2-0.3,0.2-0.8,0.2-1.5
		c0-0.6-0.1-1.1-0.2-1.4c-0.2-0.3-0.4-0.5-0.8-0.5s-0.6,0.2-0.8,0.5C82.5,49.9,82.4,50.4,82.4,51z"
      />
      <path
        sx={{ fill: '#FFFFFF' }}
        d="M92.1,48c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.4,0.9,0.4,1.6v3.8h-1.2v-3.5c0-0.9-0.2-1.3-0.7-1.3
		c-0.3,0-0.6,0.2-0.7,0.5s-0.2,0.7-0.2,1.3v3h-1.2v-3.5c0-0.4-0.1-0.8-0.2-1s-0.3-0.3-0.6-0.3c-0.4,0-0.6,0.2-0.8,0.5
		c-0.1,0.3-0.2,0.8-0.2,1.5v2.8h-1.2v-5.7h1l0.1,0.8h0.1c0.1-0.3,0.3-0.5,0.6-0.6c0.2-0.1,0.5-0.2,0.8-0.2c0.4,0,0.7,0.1,0.9,0.2
		c0.2,0.2,0.4,0.4,0.5,0.6h0.1C91,48.3,91.4,48,92.1,48z"
      />
      <path
        sx={{ fill: '#FFFFFF' }}
        d="M97.4,48c0.6,0,1,0.3,1.4,0.8c0.3,0.5,0.5,1.2,0.5,2.2c0,0.9-0.2,1.7-0.5,2.2S98,54,97.4,54
		c-0.3,0-0.6-0.1-0.8-0.2s-0.4-0.4-0.6-0.6l0,0c0,0.2,0,0.3,0,0.5c0,0.1,0,0.3,0,0.4v2.4h-1.2v-8.3h1L96,49l0,0
		C96.4,48.3,96.8,48,97.4,48z M97.1,49.1c-0.4,0-0.6,0.1-0.8,0.4c-0.2,0.3-0.2,0.7-0.2,1.4v0.2c0,0.6,0.1,1.1,0.2,1.4
		c0.2,0.3,0.4,0.5,0.8,0.5c0.6,0,1-0.6,1-1.9c0-0.6-0.1-1.1-0.2-1.5C97.6,49.2,97.4,49.1,97.1,49.1z"
      />
      <path
        sx={{ fill: '#FFFFFF' }}
        d="M102.2,48c1.2,0,1.8,0.7,1.8,2v3.8h-0.9l-0.2-0.8l0,0c-0.2,0.3-0.4,0.5-0.6,0.7c-0.2,0.1-0.5,0.2-0.9,0.2
		c-0.5,0-0.8-0.2-1.1-0.5c-0.2-0.3-0.4-0.8-0.4-1.3c0-0.6,0.2-1,0.5-1.3c0.4-0.3,0.9-0.5,1.6-0.5h0.8V50c0-0.4-0.1-0.6-0.2-0.8
		s-0.3-0.3-0.6-0.3c-0.2,0-0.4,0-0.6,0.1s-0.4,0.2-0.7,0.3l-0.4-0.9C100.9,48.2,101.5,48,102.2,48z M102.8,51.2h-0.5
		c-0.4,0-0.7,0.1-0.9,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.6,0.2,0.8,0.7,0.8c0.3,0,0.5-0.1,0.7-0.4c0.2-0.2,0.3-0.6,0.3-1
		C102.8,51.6,102.8,51.2,102.8,51.2z"
      />
      <path
        sx={{ fill: '#FFFFFF' }}
        d="M107.7,48c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.4,0.8,0.4,1.5v3.8h-1.2v-3.5c0-0.4-0.1-0.7-0.2-1
		c-0.1-0.2-0.3-0.3-0.6-0.3c-0.4,0-0.7,0.2-0.8,0.5c-0.2,0.3-0.2,0.8-0.2,1.5v2.8h-1.2v-5.7h1l0.1,0.8h0.1c0.1-0.3,0.3-0.5,0.6-0.6
		C107.2,48.1,107.4,48,107.7,48z"
      />
      <path
        sx={{ fill: '#FFFFFF' }}
        d="M109.9,48.2h1.3l0.8,3c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0.1,0.4,0.1,0.6l0,0c0-0.2,0.1-0.4,0.1-0.6
		c0-0.2,0.1-0.4,0.2-0.6l0.8-3h1.3l-1.9,6.4c-0.2,0.6-0.4,1.1-0.7,1.4c-0.3,0.3-0.7,0.4-1.2,0.4c-0.1,0-0.2,0-0.4,0
		c-0.1,0-0.2,0-0.3-0.1v-1c0.1,0,0.2,0,0.2,0.1c0.1,0,0.2,0,0.2,0c0.2,0,0.4-0.1,0.6-0.2c0.1-0.2,0.3-0.4,0.4-0.8l0.1-0.5
		L109.9,48.2z"
      />
      <g>
        <polygon
          sx={{ fill: '#8E8E8D' }}
          points="84.2,40.7 94.4,24.1 82.3,24.1 82.3,16.8 94.4,16.8 84.2,0.3 93.1,0.3 105.2,20.5 92.6,40.7 		"
        />
        <polygon sx={{ fill: '#FFFFFF' }} points="114.7,40.7 106.3,40.7 100.6,31.4 104.8,24.6 		" />
        <polygon sx={{ fill: '#FFFFFF' }} points="100.5,8.9 105.8,0.3 114.7,0.3 104.8,16.2 		" />
      </g>
      <polygon
        sx={{ fill: '#FFFFFF' }}
        points="52.1,33.5 71.5,33.5 71.5,28.9 56.9,28.9 56.9,22.8 67.4,22.8 67.4,18.3 56.9,18.3 56.9,12.1 71.5,12.1 71.5,7.5 52.1,7.5 52.1,18.3 56.9,18.3 56.9,22.8 52.1,22.8"
      />
      <path
        sx={{ fill: '#FFFFFF' }}
        d="M5.1,22.5h9.4c2.1,0,3.7,1.4,3.7,3.3c0,1.8-1.6,3.2-3.7,3.2H5.1V22.5z M5.1,12.1h8.5c2.1,0,3.2,1.4,3.2,2.9
		c0,1.6-1.1,2.9-3.2,2.9H5.1V12.1z M0.3,33.5H15c4.5,0,8-2.9,8-7.5c0-3.3-1.9-5.5-4.6-6.3c1.9-0.9,3.2-2.8,3.2-5.2
		c0-4.6-3.5-6.9-7.5-6.9H0.3V18h4.8v4.5H0.3V33.5z"
      />
      <polygon
        sx={{ fill: '#FFFFFF' }}
        points="26.2,33.5 31,33.5 31,17.4 37.7,24.3 44.4,17.4 44.4,33.5 49.2,33.5 49.2,7 37.7,18.5 26.2,7 	"
      />
    </g>
    <g>
      <path
        sx={{ fill: '#8E8E8D' }}
        d="M131.3,15.1h-2v-3.5h12.6c1.5,0,2.1,0.7,2.1,2.1v3.4h-3.7v-1.3c0-0.4-0.2-0.7-0.7-0.7h-4.3v6.3h7.1v3.5h-7.1
		v8.9h-4.1L131.3,15.1L131.3,15.1z"
      />
      <path
        sx={{ fill: '#8E8E8D' }}
        d="M151.6,22.5l-4.1-6.7c-0.4-0.7-0.7-0.7-1.3-0.7h-0.9v-3.5h2.6c1.5,0,2.1,0.3,2.8,1.6l2.1,3.7
		c0.7,1.3,1.4,2.7,1.4,2.7h0.1c0,0,0.6-1.4,1.3-2.7l2.2-3.7c0.7-1.3,1.4-1.6,2.9-1.6h2.5v3.5h-0.9c-0.7,0-0.9,0.1-1.3,0.7l-4.1,6.7
		l6.9,11.2H159l-3.4-5.8c-0.7-1.3-1.4-2.6-1.4-2.6h-0.1c0,0-0.6,1.3-1.3,2.6l-3.4,5.8h-4.6L151.6,22.5z"
      />
    </g>
  </svg>
)

const logoBm = style => (
  <svg viewBox="0 0 146 21" sx={{ enableBackground: 'new 0 0 146 21', ...style }}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#000"
        d="M34.4875365,14.9821328 C34.067974,14.7731172 33.7559818,14.7313906 32.5639036,14.7313906 L31.5609349,14.7313906 L31.5609349,17.6591406 L32.6251536,17.6591406 C34.4040833,
        17.6591406 35.1769818,17.2204375 35.1769818,16.2163203 C35.1769818,15.6731094 34.9270052,15.2118203 34.4875365,14.9821328 Z M32.5011224,9.29239063 L31.5609349,
        9.29239063 L31.5609349,12.0333281 L32.5011224,12.0333281 C33.6101302,12.0333281 34.1954505,11.5528984 34.1954505,10.6525234 C34.1954505,9.77396875 33.6101302,9.29239063 32.5011224,9.29239063 Z
        M28.0773411,6.53154688 L33.4539427,6.53154688 C36.0057708,6.53154688 37.3861927,7.80746094 37.3861927,10.1713281 C37.3861927,11.5724219 36.9474896,12.4088672 35.858388,13.0585
       C37.8256615,13.4340391 38.7038333,14.5009375 38.7038333,16.4682109 C38.7038333,18.9775469 36.9053802,20.4835313 33.8937943,20.4835313 L28.0773411,20.4835313 L28.0773411,6.53154688 L28.0773411,6.53154688 Z"
      />

      <path
        fill="#000"
        d="M138.636271,6.53154688 L138.636271,12.1990859 C139.537411,11.1532422 140.31031,10.7995234 141.711021,10.7995234 C142.841466,10.7995234 143.739544,11.1532422 144.409083,
      11.8446016 C145.07824,12.5141406 145.287255,13.1630078 145.287255,14.4599766 L145.287255,20.4835313 L141.941474,20.4835313 L141.941474,15.6933984 C141.941474,14.7313906 141.921185,
      14.5426641 141.732458,14.1866484 C141.522294,13.7479453 140.978701,13.4558594 140.392232,13.4558594 C139.285521,13.4558594 138.636271,14.1667422 138.636271,15.4001641 L138.636271,20.4835313
      L135.29049,20.4835313 L135.29049,6.53154688 L138.636271,6.53154688 Z"
      />

      <path
        fill="#000"
        d="M133.648224,14.0825234 C133.000505,13.6434375 132.51931,13.4757656 131.912935,13.4757656 C130.593763,13.4757656 129.612615,14.4389219 129.612615,15.735125 C129.612615,
      17.1163125 130.552036,18.0568828 131.954279,18.0568828 C132.560271,18.0568828 133.103482,17.8486328 133.648224,17.4294531 L133.648224,20.2748984 C132.79149,20.6504375 132.141857,20.7974375 
      131.285122,20.7974375 C128.398716,20.7974375 126.119833,18.6000938 126.119833,15.8185781 C126.119833,12.8697734 128.356607,10.7149219 131.430591,10.7149219 C132.267419,10.7149219 132.894849,
      10.8611563 133.648224,11.2386094 L133.648224,14.0825234 Z"
      />

      <path
        fill="#000"
        d="M121.557857,11.0081563 L121.557857,12.5554844 C122.332286,11.3634063 123.230747,10.8829766 124.675482,10.8829766 C124.904786,10.8829766
      124.987091,10.8829766 125.322818,10.9032656 L125.322818,14.1039609 C124.780372,13.8317813 124.464935,13.7682344 123.900669,13.7682344 C122.332286,13.7682344 
      121.557857,14.6678438 121.557857,16.4245703 L121.557857,20.4835313 L118.212458,20.4835313 L118.212458,11.0081563 L121.557857,11.0081563 Z"
      />

      <path
        fill="#000"
        d="M108.359247,15.735125 C108.359247,16.9272031 109.34231,17.9106484 110.53324,17.9106484 C111.727232,17.9106484 112.688091,16.9482578 112.688091,15.7557969
       C112.688091,14.5426641 111.727232,13.5810391 110.53324,13.5810391 C109.320872,13.5810391 108.359247,14.5426641 108.359247,15.735125 Z M115.930896,11.0081563 L115.930896,20.4835313 
       L112.583583,20.4835313 L112.583583,19.4373047 C111.852411,20.3782578 110.931747,20.7974375 109.656599,20.7974375 C106.957771,20.7974375 104.865701,18.5798047 104.865701,15.735125 
       C104.865701,12.8697734 106.937099,10.69425 109.635161,10.69425 C110.846763,10.69425 111.746372,11.0717031 112.583583,11.9284375 L112.583583,11.0081563 L115.930896,11.0081563 Z"
      />

      <path
        fill="#000"
        d=" M88.7603958,6.53154688 L92.3366302,6.53154688 L95.1602552,13.9787813 L98.1523177,6.53154688 L101.770661,6.53154688 L103.863115,20.4835313 L100.24324,20.4835313
       L99.2192161,12.4505938 L95.8516146,20.4835313 L94.4064974,20.4835313 L91.1862786,12.4505938 L89.9942005,20.4835313 L86.3961458,20.4835313 L88.7603958,6.53154688 Z"
      />

      <path
        fill="#000"
        d="M77.1630911,15.735125 C77.1630911,16.9272031 78.1446224,17.9106484 79.3382318,17.9106484 C80.5306927,17.9106484 81.4927005,16.9482578 81.4927005,15.7557969
      C81.4927005,14.5426641 80.5306927,13.5810391 79.3382318,13.5810391 C78.1254818,13.5810391 77.1630911,14.5426641 77.1630911,15.735125 Z M84.7343568,11.0081563 L84.7343568,20.4835313
      L81.3870443,20.4835313 L81.3870443,19.4373047 C80.6558724,20.3782578 79.735974,20.7974375 78.4600599,20.7974375
      C75.7619974,20.7974375 73.6699271,18.5798047 73.6699271,15.735125 C73.6699271,12.8697734 75.7405599,10.69425 78.4386224,10.69425 C79.652138,10.69425 80.5509818,11.0717031 81.3870443,11.9284375 
      L81.3870443,11.0081563 L84.7343568,11.0081563 Z"
      />

      <path
        fill="#000"
        d="M72.142888,14.0825234 C71.4944036,13.6434375 71.0116771,13.4757656 70.4056849,13.4757656 C69.0880443,13.4757656 68.104599,14.4389219 68.104599,15.735125 
      C68.104599,17.1163125 69.0463177,18.0568828 70.4477943,18.0568828 C71.0537865,18.0568828 71.597763,17.8486328 72.142888,17.4294531 L72.142888,20.2748984 C71.2838568,20.6504375 70.6353724,20.7974375 69.7774896,20.7974375 C66.8914661,20.7974375 64.6118177,18.6000938
      64.6118177,15.8185781 C64.6118177,12.8697734 66.8512708,10.7149219 69.9252552,10.7149219 C70.7609349,10.7149219 71.3891302,10.8611563 72.142888,11.2386094 L72.142888,14.0825234 Z"
      />

      <path
        fill="#000"
        d="M56.4743724,11.0081563 L56.4743724,12.1990859 C57.3739818,11.1532422 58.1468802,10.7995234
      59.549888,10.7995234 C60.6799505,10.7995234 61.5780286,11.1532422 62.2475677,11.8446016 C62.9174896,12.5141406 63.1253568,13.1630078 63.1253568,14.4599766 L63.1253568,20.4835313 L59.7791927,20.4835313 L59.7791927,15.6933984 C59.7791927,14.7313906 59.7589036,14.5426641
      59.5705599,14.1866484 C59.3611615,13.7479453 58.8183333,13.4558594 58.2318646,13.4558594 C57.1232396,13.4558594 56.4743724,14.1667422 56.4743724,15.4001641 L56.4743724,20.4835313 L53.1285911,20.4835313 L53.1285911,11.0081563 L56.4743724,11.0081563 Z"
      />

      <path
        fill="#000"
        d="M43.2765286,15.735125 C43.2765286,16.9272031 44.2584427,17.9106484 45.4524349,17.9106484 C46.6433646,17.9106484 47.604224,16.9482578 47.604224,15.7557969 C47.604224,14.5426641 
      46.6433646,13.5810391 45.4524349,13.5810391 C44.2389193,13.5810391 43.2765286,14.5426641 43.2765286,15.735125 Z M50.8470286,11.0081563 L50.8470286,20.4835313 L47.5012474,20.4835313 
      L47.5012474,19.4373047 C46.7685443,20.3782578 45.8478802,20.7974375 44.5727318,20.7974375 C41.8739036,20.7974375 39.7818333,18.5798047 39.7818333,15.735125 C39.7818333,12.8697734 41.8543802,10.69425 44.5524427,10.69425 C45.7644271,10.69425
      46.6651849,11.0717031 47.5012474,11.9284375 L47.5012474,11.0081563 L50.8470286,11.0081563 Z"
      />

      <path
        fill="#00806C"
        d="M20.390625,20.25 L16.0377251,9.4481911 L11.685591,20.25 L20.390625,20.25 Z M8.70426815,20.25 L4.35213407,9.4481911 L0,20.25 L8.70426815,20.25 Z 
      M14.6029683,0 L10.2508342,10.8025732 L5.89716847,0 L14.6029683,0 L14.6029683,0 Z"
      />
    </g>
  </svg>
)

const logoBmeDark = style => (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 42.4 21.4"
    sx={{ enableBackground: 'new 0 0 42.4 21.4', ...style }}
  >
    <g>
      <polygon
        fill="#8E8E8D"
        points="31.1,15 34.9,8.8 30.4,8.8 30.4,6.1 34.9,6.1 31.1,0 34.4,0 38.9,7.5 34.2,15 	"
      />
      <g>
        <g>
          <path
            fill="#002851"
            d="M16.9,17.7c0.5,0,0.8,0.3,0.8,0.9v1.7h-0.4L17.2,20h0c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.4,0.1
				c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.2-0.2-0.3-0.2-0.6c0-0.3,0.1-0.5,0.2-0.6c0.2-0.1,0.4-0.2,0.7-0.2l0.3,0v-0.1
				c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.1-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.3,0.1L16,17.9
				C16.3,17.8,16.6,17.7,16.9,17.7z M17.1,19.1l-0.2,0c-0.2,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.2,0.1,0.4,0.3,0.4
				c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.3,0.1-0.4V19.1z"
          />

          <path
            fill="#002851"
            d="M20.8,19.4c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.3-0.4,0.3c-0.2,0.1-0.3,0.1-0.6,0.1c-0.2,0-0.3,0-0.4,0
				c-0.1,0-0.3-0.1-0.4-0.1v-0.6c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.3,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3
				c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.2-0.4-0.3c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.3s-0.1-0.3-0.1-0.5
				c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.2c0.1,0,0.3,0,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1l-0.2,0.5c-0.2-0.1-0.4-0.2-0.6-0.2
				c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0,0.2c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.2
				c0.2,0.1,0.4,0.3,0.5,0.4S20.8,19.1,20.8,19.4z"
          />

          <path
            fill="#002851"
            d="M22.2,20.3H21V20l0.3-0.1v-2.5L21,17.3v-0.3h1.2v0.3l-0.3,0.1v2.5l0.3,0.1V20.3z"
          />

          <path
            fill="#002851"
            d="M24.6,20.3H24L23.5,19l-0.6,1.3h-0.6l0.8-1.7l-0.8-1.6H23l0.5,1.2l0.5-1.2h0.6l-0.8,1.6L24.6,20.3z"
          />

          <path
            fill="#002851"
            d="M26.6,20.4c-0.3,0-0.6-0.1-0.7-0.3c-0.2-0.2-0.3-0.5-0.3-1c0-0.4,0.1-0.7,0.3-1c0.2-0.2,0.4-0.4,0.8-0.4
				c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0.1,0.3,0.1l-0.2,0.4c-0.1,0-0.2-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0c-0.3,0-0.5,0.3-0.5,0.9
				c0,0.6,0.2,0.8,0.5,0.8c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.3-0.1v0.5C27.1,20.3,26.8,20.4,26.6,20.4z"
          />

          <path
            fill="#002851"
            d="M29.5,19c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.4-0.3,0.5c-0.2,0.1-0.3,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.2
				c-0.2-0.1-0.3-0.3-0.3-0.5c-0.1-0.2-0.1-0.4-0.1-0.7c0-0.4,0.1-0.7,0.3-1c0.2-0.2,0.4-0.4,0.8-0.4c0.3,0,0.6,0.1,0.7,0.3
				S29.5,18.6,29.5,19z M28,19c0,0.3,0,0.5,0.1,0.6c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.1-0.4,0.1-0.7
				c0-0.3,0-0.5-0.1-0.6c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.3,0.2C28.1,18.5,28,18.7,28,19z"
          />

          <path
            fill="#002851"
            d="M32.4,17.7c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.4,0.2,0.7v1.7h-0.5v-1.6c0-0.4-0.1-0.6-0.3-0.6
				c-0.2,0-0.3,0.1-0.3,0.2c-0.1,0.1-0.1,0.3-0.1,0.6v1.3h-0.5v-1.6c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.1-0.1-0.2-0.1
				c-0.2,0-0.3,0.1-0.3,0.2c-0.1,0.2-0.1,0.4-0.1,0.6v1.3h-0.5v-2.6h0.4l0.1,0.3h0c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.1,0.4-0.1
				c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.3h0C31.9,17.8,32.1,17.7,32.4,17.7z"
          />

          <path
            fill="#002851"
            d="M34.7,17.7c0.3,0,0.5,0.1,0.6,0.3s0.2,0.6,0.2,1c0,0.4-0.1,0.7-0.2,1c-0.1,0.2-0.3,0.3-0.6,0.3
				c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.2-0.3-0.3h0c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2v1.1h-0.5v-3.7H34l0.1,0.3h0
				C34.3,17.8,34.5,17.7,34.7,17.7z M34.6,18.2c-0.2,0-0.3,0.1-0.3,0.2c-0.1,0.1-0.1,0.3-0.1,0.6v0.1c0,0.3,0,0.5,0.1,0.6
				c0.1,0.1,0.2,0.2,0.4,0.2c0.3,0,0.4-0.3,0.4-0.9c0-0.3,0-0.5-0.1-0.6C34.8,18.2,34.7,18.2,34.6,18.2z"
          />

          <path
            fill="#002851"
            d="M36.8,17.7c0.5,0,0.8,0.3,0.8,0.9v1.7h-0.4L37.2,20h0c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.4,0.1
				c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.2-0.2-0.3-0.2-0.6c0-0.3,0.1-0.5,0.2-0.6c0.2-0.1,0.4-0.2,0.7-0.2l0.3,0v-0.1
				c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.1-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.3,0.1L36,17.9
				C36.3,17.8,36.5,17.7,36.8,17.7z M37.1,19.1l-0.2,0c-0.2,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.2,0.1,0.4,0.3,0.4
				c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.3,0.1-0.4V19.1z"
          />

          <path
            fill="#002851"
            d="M39.3,17.7c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.4,0.2,0.7v1.7h-0.5v-1.6c0-0.2,0-0.3-0.1-0.4
				s-0.1-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.1,0.4-0.1,0.7v1.3h-0.5v-2.6h0.4l0.1,0.3h0c0.1-0.1,0.1-0.2,0.3-0.3
				C39.1,17.7,39.2,17.7,39.3,17.7z"
          />

          <path
            fill="#002851"
            d="M40.3,17.8h0.6l0.4,1.4c0,0.1,0,0.2,0.1,0.3c0,0.1,0,0.2,0,0.3h0c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0.1-0.3
				l0.4-1.4h0.6l-0.9,2.9c-0.1,0.3-0.2,0.5-0.3,0.6c-0.1,0.1-0.3,0.2-0.5,0.2c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0v-0.5
				c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2,0.2-0.4l0.1-0.2L40.3,17.8z"
          />
        </g>

        <polygon fill="#002851" points="42.4,15 39.3,15 37.2,11.6 38.7,9 		" />

        <polygon fill="#002851" points="37.1,3.2 39.1,0 42.4,0 38.7,5.9 		" />

        <polygon
          fill="#002851"
          points="19.2,12.3 26.4,12.3 26.4,10.6 21,10.6 21,8.4 24.9,8.4 24.9,6.7 21,6.7 21,4.4 26.4,4.4 26.4,2.7 
			19.2,2.7 19.2,6.7 21,6.7 21,8.4 19.2,8.4 		"
        />

        <g>
          <path
            fill="#002851"
            d="M1.8,8.2h3.5c0.8,0,1.4,0.5,1.4,1.2c0,0.7-0.6,1.2-1.4,1.2H1.8V8.2z M1.8,4.4h3.2c0.8,0,1.2,0.5,1.2,1.1
				c0,0.6-0.4,1.1-1.2,1.1H1.8V4.4z M0,12.3h5.5c1.7,0,3-1.1,3-2.8c0-1.2-0.7-2-1.7-2.3c0.7-0.3,1.2-1,1.2-1.9
				c0-1.7-1.3-2.6-2.8-2.6H0v3.8h1.8v1.7H0V12.3z"
          />

          <polygon
            fill="#002851"
            points="9.6,12.3 11.4,12.3 11.4,6.4 13.9,8.9 16.4,6.4 16.4,12.3 18.1,12.3 18.1,2.5 13.9,6.8 9.6,2.5 			
				"
          />
        </g>
      </g>
    </g>
  </svg>
)

const logoIgf = style => (
  <svg viewBox="0 0 1365 389.36" sx={{ enableBackground: 'new 0 0 146 21', ...style }}>
    <g>
      <path
        fill="#145e62"
        d="M493.21,19.25V6.93h170.84v12.32h-8c-18.14,0-30.16,1.54-35.87,4.71-5.6,3.04-9.44,8.65-11.31,16.96-1.98,8.39-2.87,
        28.2-2.87,59.43v184.54c0,29.52,.65,48.89,2.03,58.08,1.33,9.17,4.98,15.97,10.92,20.38,5.92,4.37,17.44,6.71,34.23,
      6.71h10.87v12.27h-170.84v-12.27h10.87c15.98,0,26.98-2.07,33.08-6.11,6.15-4.13,10.04-10.49,11.73-19.03,1.69-8.62,
      2.5-28.59,2.5-60.04V100.36c0-29.95-.81-49.22-2.5-58-1.69-8.67-5.24-14.7-11.03-17.99-5.58-3.39-17.93-5.12-36.73-5.12h-7.92Z"
      />

      <path
        fill="#145e62"
        d="M909.06,221.94h162.14v12.38c-17.8,0-30.03,1.86-36.36,5.43-6.39,3.6-10.5,9.65-12.56,18.1-2.01,8.54-3.05,28.95-3.05,
        61.45v40.12c-4.41-.69-8.39-1.08-11.99-1.08-14.23,0-32.87,4.37-55.99,13.18-31.38,11.81-60.45,17.84-87.17,17.84-53.86,
      0-98.51-17.62-134.19-53.05-35.66-35.26-53.49-80.16-53.49-134.47s17.18-100.79,51.3-141.26C761.91,20.25,808.47,0,867.22,
      0c50.65,0,92.49,18.42,125.54,55.35,6.02-12.99,9.83-27.09,11.39-42.19h12.43v125.9h-12.43c-5.34-38.62-20.05-69.34-44.13-91.93-24.16-22.66-53.39-33.98-87.79-33.98s-66.32,
      13.19-92.8,39.43c-26.35,26.2-39.54,72.86-39.54,139.91s12.51,114,37.82,141.89c25.23,27.87,56.64,41.8,
      93.94,41.8,19.21,0,36.62-3.98,52.21-11.91,15.72-7.87,26.41-17.53,32.19-28.95,5.63-11.4,8.47-25.7,8.47-43.04,
      0-20.4-1.3-33.89-4.01-40.77-2.63-6.76-6.7-11.34-12.28-13.67-5.58-2.43-15.54-3.54-30.32-3.54h-8.86v-12.38Z"
      />

      <path
        fill="#145e62"
        d="M1362.47,6.93l2.53,93.51h-12.2c-2.61-20.62-8.42-37.58-17.41-50.94-9.02-13.47-18.38-21.85-28.33-25.14-9.75-3.39-26.33-5.12-49.66-5.12h-73.82V178.82h35.89c23.09,
        0,38.45-3.8,46.14-11.38,7.64-7.61,11.37-22.94,11.37-45.86h12.38V253.68h-12.38c0-16.06-1.59-28.85-4.88-38.25-3.23-9.28-8.18-15.75-14.7-19.12-6.49-3.52-19.13-5.15-37.93-5.15h-35.89v93.54c0,
        29.72,.73,49.09,2.24,58.28,1.46,9.08,5.29,15.97,11.21,20.38,6,4.37,17.41,6.71,34.62,6.71h12.8v12.27h-175.38v-12.27h11.65c16.19,
        0,27.4-2.07,33.6-6.11,6.15-4.13,10.19-10.49,12.09-19.03,1.69-8.62,2.58-28.59,
        2.58-60.04V100.36c0-29.95-.89-49.22-2.66-58-1.88-8.67-5.63-14.7-11.21-17.99-5.79-3.39-18.22-5.12-37.59-5.12h-6.7V6.93h291.64Z"
      />
    </g>

    <path
      fillRule="evenodd"
      d="M0,7.26V383.38H375.94V7.26H0ZM301.78,314.5h-101.9v-37.75l1.98-1.04c17.73-10.13,26.85-17.73,35.89-30.24,11.39-15.84,
      16.97-33.61,16.97-54.14,0-42.46-28.6-74.53-66.52-74.53s-67.07,31.35-67.07,74.53c0,36.3,16.73,63.47,51.48,83.09l1.82,
      1.17v38.9H73.98v-40.16h42.41c-26.62-22.38-39.78-50.53-39.78-85.56,0-64.19,47.89-112.5,111.31-112.5,63.42,.28,
      111.28,48.7,111.28,112.5,0,34.84-13.22,62.95-39.88,85.56h42.46v40.16Z"
    />
  </svg>
)

const logoMtx = style => (
  <svg viewBox="0 0 361.16 60.9" sx={{ ...style }}>
    <defs>
      <linearGradient
        id="gradient1"
        x1="76.91"
        y1="14.86"
        x2="94.93"
        y2="14.86"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#23acae" />
        <stop offset="1" stopColor="#e7ede7" />
      </linearGradient>
      <linearGradient
        id="gradient2"
        x1="13.31"
        y1="26.18"
        x2="94.93"
        y2="26.18"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#23acae" />
        <stop offset="0.8" stopColor="#fef8f3" />
        <stop offset="1" stopColor="#98d0d4" />
      </linearGradient>
    </defs>
    <path
      fill="#1f140f"
      d="M118.51,33l9.1-33h.76L144.9,20.83,160.5,0h.78l9.16,33H160.5l-3.72-14.6L145,34.19h-.69l-12.64-16L127.85,33Z"
    />

    <path fill="#1f140f" d="M189.2,33V1h27V7.63H199.36v5.78h14.42V20H199.36V26.3h17.08V33Z" />
    <path fill="#1f140f" d="M245.71,33V7.72H231.78V1h38.27V7.72H256.17V33Z" />

    <path fill="#1f140f" d="M287.57,33V1H298V33Z" />

    <path
      fill="#1f140f"
      d="M316.09,33l15.75-16.54L316.3,1h12.79l9,9.13L346.62,1H358L343.9,15.82,361.16,33H348.28L337.64,22.17,327.54,33Z"
    />

    <path
      fill="#1f140f"
      d="M126.8,52.3a3.45,3.45,0,0,0-1.26-.86,4.16,4.16,0,0,0-1.59-.29,4.11,4.11,0,0,0-1.55.28,3.58,3.58,0,0,0-1.25.84,
      4.32,4.32,0,0,0-.91,1.41,4.93,4.93,0,0,0,0,3.44,4.22,4.22,0,0,0,.91,1.41,3.72,3.72,0,0,0,1.25.84,4.32,4.32,0,0,0,
      1.55.28,3.93,3.93,0,0,0,2.72-1A4.2,4.2,0,0,0,128,56.05h-3.78V54.79h5.22v.31a7.85,7.85,0,0,1-.18,1.73,4.77,4.77,0,0,
      1-.55,1.41,5.06,5.06,0,0,1-1.94,2,5.6,5.6,0,0,1-2.79.68,6.11,6.11,0,0,1-2.13-.37,5.19,5.19,0,0,1-2.92-2.9,6.13,6.13,
      0,0,1,0-4.46,5,5,0,0,1,1.22-1.81,4.88,4.88,0,0,1,1.7-1.1,6.42,6.42,0,0,1,4.24,0,4.92,4.92,0,0,1,1.68,1.07Z"
    />

    <path
      fill="#1f140f"
      d="M136.79,60.69V50.11h5.49v1.25h-4.12v3.3h4.12v1.22h-4.12v3.56h4.12v1.25Z"
    />

    <path
      fill="#1f140f"
      d="M151,57.66a2,2,0,0,0,.59,1.47,2.2,2.2,0,0,0,1.56.53,2.26,2.26,0,0,0,1.47-.45,1.46,1.46,0,0,0,.56-1.19,1.59,1.59,0,0,
      0-.43-1.16A4.91,4.91,0,0,0,153,56l-.33-.11a6.52,6.52,0,0,1-2-1A2.36,2.36,0,0,1,150,54a3.12,3.12,0,0,1-.23-1.22,2.67,2.67,
      0,0,1,.87-2.09A3.35,3.35,0,0,1,153,49.9a3.5,3.5,0,0,1,2.46.84,3.29,3.29,0,0,1,1,2.35h-1.39a2.51,2.51,0,0,0-.63-1.53,2.22,
      2.22,0,0,0-2.78,0,1.6,1.6,0,0,0-.52,1.25,1.64,1.64,0,0,0,.14.71,1.21,1.21,0,0,0,.41.51,5.23,5.23,0,0,0,1.35.59l.42.14a6.14,
      6.14,0,0,1,2.47,1.33,2.62,2.62,0,0,1,.69,1.89,2.71,2.71,0,0,1-1,2.12,3.67,3.67,0,0,1-2.48.84,3.53,3.53,0,0,1-2.45-.85,3.43,
      3.43,0,0,1-1.07-2.34Z"
    />

    <path fill="#1f140f" d="M165.88,60.69V51.36h-2.7V50.11H170v1.25h-2.7v9.33Z" />

    <path fill="#1f140f" d="M176.83,60.69V50.11h1.38V60.69Z" />

    <path
      fill="#1f140f"
      d="M191.08,60.9a5.79,5.79,0,0,1-2.12-.37,4.87,4.87,0,0,1-1.71-1.1A5.26,5.26,0,0,1,186,57.62a5.77,5.77,0,0,1-.42-2.22,5.86,
      5.86,0,0,1,.42-2.23,5.48,5.48,0,0,1,1.23-1.81A4.85,4.85,0,0,1,189,50.27a6.23,6.23,0,0,1,4.23,0,4.72,4.72,0,0,1,1.7,1.09,5.2,
      5.2,0,0,1,1.23,1.81,5.86,5.86,0,0,1,.42,2.23,6,6,0,0,1-.41,2.22,5.41,5.41,0,0,1-1.22,1.81,5.06,5.06,0,0,1-1.71,1.1A5.81,5.81,
      0,0,1,191.08,60.9Zm0-1.25a4.5,4.5,0,0,0,1.59-.28,3.57,3.57,0,0,0,1.26-.84,4.08,4.08,0,0,0,.91-1.4,4.67,4.67,0,0,0,.31-1.73,4.61,
      4.61,0,0,0-.31-1.72,4.25,4.25,0,0,0-.92-1.41,3.57,3.57,0,0,0-1.27-.84,4.54,4.54,0,0,0-3.14,0,3.57,3.57,0,0,0-1.26.84,4,4,0,0,0-.9,
      1.39,5,5,0,0,0,0,3.47,4.06,4.06,0,0,0,.9,1.4,3.76,3.76,0,0,0,1.26.84A4.39,4.39,0,0,0,191.07,59.65Zm-.86-10.5,1.38-2.37H193L191,49.15Z"
    />

    <path
      fill="#1f140f"
      d="M204.1,60.69V50.11h1.29l5.13,7.31a6,6,0,0,1,.41.65,6.87,6.87,0,0,1,.36.77c-.09-.32-.15-.63-.2-.91a5.52,5.52,0,0,
      1-.06-.77v-7h1.34V60.69h-1.12l-5.36-7.64a6.47,6.47,0,0,1-.44-.7,3.65,3.65,0,0,1-.23-.54,8.34,8.34,0,0,1,.17,1,6.85,6.85,
      0,0,1,.06.88v7Z"
    />

    <path
      fill="#1f140f"
      d="M231.92,50.11a6.77,6.77,0,0,1,4.53,1.34A5,5,0,0,1,238,55.4a5,5,0,0,1-1.55,3.94,6.72,6.72,0,0,1-4.53,1.35h-2.19V50.11Zm-.8,
      1.25v8.08h1a4.85,4.85,0,0,0,3.35-1,3.87,3.87,0,0,0,1.12-3,3.88,3.88,0,0,0-1.12-3,4.9,4.9,0,0,0-3.35-1Z"
    />

    <path fill="#1f140f" d="M245.46,60.69V50.11H251v1.25h-4.13v3.3H251v1.22h-4.13v3.56H251v1.25Z" />

    <path
      fill="#1f140f"
      d="M268.27,60.69V50.11h2.41a4.6,4.6,0,0,1,2.95.84,2.86,2.86,0,0,1,1.05,2.37,3.23,3.23,0,0,1-.89,2.34,3.08,3.08,0,0,1-2.3.92h-.08l3.73,
      4.11H273.4l-3.76-4.31v4.31Zm1.37-5.15h1a3,3,0,0,0,2-.57,2,2,0,0,0,.68-1.61,1.74,1.74,0,0,0-.69-1.49,3.36,3.36,0,0,0-2-.51h-.92Z"
    />

    <path fill="#1f140f" d="M282.34,60.69V50.11h1.37V60.69Z" />

    <path
      fill="#1f140f"
      d="M291.73,60.69V50.11h5.49v1.25H293.1v3.3h4.12v1.22H293.1v3.56h4.12v1.25Z"
    />

    <path
      fill="#1f140f"
      d="M306,57.66a2,2,0,0,0,.59,1.47,2.2,2.2,0,0,0,1.56.53,2.26,2.26,0,0,0,1.47-.45,1.46,1.46,0,0,0,.56-1.19,1.59,1.59,0,0,0-.43-1.16,
      4.91,4.91,0,0,0-1.79-.89l-.33-.11a6.52,6.52,0,0,1-2-1A2.36,2.36,0,0,1,305,54a3.12,3.12,0,0,1-.23-1.22,2.67,2.67,0,0,1,.87-2.09,3.35,
      3.35,0,0,1,2.33-.78,3.5,3.5,0,0,1,2.46.84,3.29,3.29,0,0,1,1,2.35H310a2.51,2.51,0,0,0-.63-1.53,2.22,2.22,0,0,0-2.78,0,1.6,1.6,0,0,0-.52,
      1.25,1.64,1.64,0,0,0,.14.71,1.21,1.21,0,0,0,.41.51,5.23,5.23,0,0,0,1.35.59l.42.14a6.14,6.14,0,0,1,2.47,1.33,2.62,2.62,0,0,1,.69,1.89,
      2.71,2.71,0,0,1-1,2.12,3.67,3.67,0,0,1-2.48.84,3.53,3.53,0,0,1-2.45-.85,3.43,3.43,0,0,1-1.07-2.34Z"
    />

    <path
      fill="#1f140f"
      d="M326.89,52.3a3.63,3.63,0,0,0-1.26-.86,4.16,4.16,0,0,0-1.59-.29,4.07,4.07,0,0,0-1.55.28,3.49,3.49,0,0,0-1.25.84,4.17,4.17,0,0,0-.91,
      1.41,4.93,4.93,0,0,0,0,3.44,4.07,4.07,0,0,0,.91,1.41,3.63,3.63,0,0,0,1.25.84,4.28,4.28,0,0,0,1.55.28,4,4,0,0,0,2.72-1,4.19,4.19,0,0,0,
      1.33-2.64h-3.78V54.79h5.22v.31a8.53,8.53,0,0,1-.17,1.73,5.06,5.06,0,0,1-.55,1.41,5.13,5.13,0,0,1-1.94,2,5.6,5.6,0,0,1-2.79.68,6.11,6.11,
      0,0,1-2.13-.37,5,5,0,0,1-1.71-1.09A5.31,5.31,0,0,1,319,57.63a6.27,6.27,0,0,1,0-4.46,5.22,5.22,0,0,1,1.22-1.81,5,5,0,0,1,1.71-1.1,6,6,0,0,
      1,2.13-.36,5.94,5.94,0,0,1,2.1.36,4.87,4.87,0,0,1,1.69,1.07Z"
    />

    <path
      fill="#1f140f"
      d="M341.73,60.9a5.79,5.79,0,0,1-2.12-.37,5.06,5.06,0,0,1-1.71-1.1,5.26,5.26,0,0,1-1.22-1.81,5.77,5.77,0,0,1-.42-2.22,5.68,5.68,
      0,0,1,.43-2.23,5.31,5.31,0,0,1,1.22-1.81,4.85,4.85,0,0,1,1.71-1.09,6.23,6.23,0,0,1,4.23,0,4.72,4.72,0,0,1,1.7,1.09,5.09,5.09,0,0,
      1,1.24,1.81,6.05,6.05,0,0,1,.41,2.23,5.77,5.77,0,0,1-.41,2.22,5.41,5.41,0,0,1-1.22,1.81,5.06,5.06,0,0,1-1.71,1.1A5.81,5.81,0,0,1,
      341.73,60.9Zm0-1.25a4.5,4.5,0,0,0,1.59-.28,3.57,3.57,0,0,0,1.26-.84,4.08,4.08,0,0,0,.91-1.4,4.67,4.67,0,0,0,.31-1.73,4.61,4.61,
      0,0,0-.31-1.72,4.1,4.1,0,0,0-.92-1.41,3.57,3.57,0,0,0-1.27-.84,4.51,4.51,0,0,0-3.13,0,3.61,3.61,0,0,0-1.27.84,4.16,4.16,0,0,0-.9,
      1.39,5,5,0,0,0,0,3.47,4.21,4.21,0,0,0,.9,1.4,3.71,3.71,0,0,0,1.27.84A4.33,4.33,0,0,0,341.72,59.65Z"
    />

    <path
      fill="#1f140f"
      d="M355.58,57.66a2,2,0,0,0,.59,1.47,2.2,2.2,0,0,0,1.56.53,2.26,2.26,0,0,0,1.47-.45,1.46,1.46,0,0,0,.56-1.19,1.59,1.59,0,
      0,0-.43-1.16,4.91,4.91,0,0,0-1.79-.89l-.33-.11a6.52,6.52,0,0,1-2-1,2.36,2.36,0,0,1-.67-.9,3.12,3.12,0,0,1-.23-1.22,2.67,
      2.67,0,0,1,.87-2.09,3.35,3.35,0,0,1,2.33-.78,3.5,3.5,0,0,1,2.46.84,3.29,3.29,0,0,1,1,2.35H359.6a2.51,2.51,0,0,0-.63-1.53,
      2.22,2.22,0,0,0-2.78,0,1.6,1.6,0,0,0-.52,1.25,1.64,1.64,0,0,0,.14.71,1.21,1.21,0,0,0,.41.51,5.23,5.23,0,0,0,1.35.59l.42.14a6.14,
      6.14,0,0,1,2.47,1.33,2.62,2.62,0,0,1,.69,1.89,2.71,2.71,0,0,1-1,2.12,3.67,3.67,0,0,1-2.48.84,3.53,3.53,0,0,1-2.45-.85,3.43,3.43,
      0,0,1-1.07-2.34Z"
    />

    <path
      fill="url(#gradient1)"
      d="M88.44,29.71,94.93,0l-18,29.68A90.52,90.52,0,0,1,88.44,29.71Z"
    />

    <path
      fill="url(#gradient2)"
      d="M76.91,29.68,94.93,0,13.31,52.36c11.28-6.75,22.95-12.77,35.37-17A108.92,108.92,0,0,1,76.91,29.68Z"
    />

    <path
      fill="#23acae"
      d="M76.91,29.68,68.06,44.27,81.62,60.9l6.82-31.19A90.52,90.52,0,0,0,76.91,29.68Z"
    />

    <path
      fill="#98d0d4"
      d="M68.06,44.27l8.85-14.59a108.92,108.92,0,0,0-28.23,5.7c-12.42,4.21-24.09,10.23-35.37,17L0,60.9Z"
    />
  </svg>
)

const workspace = style => (
  <svg viewBox="0 0 33.17 33.17" sx={style}>
    <g>
      <path
        sx={style}
        d="M0 18.43h14.74V0H0zm0 14.74h14.74v-11H0zm18.43 0h14.74V14.74H18.43zm0-33.17v11.06h14.74V0z"
      />
    </g>
  </svg>
)

const menuWidget = style => (
  <svg sx={style} viewBox="0 0 45.14 31.6">
    <path
      sx={style}
      d="M27.09,9H0v4.51H27.09Zm0-9H0V4.51H27.09Zm9,18.06V9H31.6v9h-9v4.51h9v9h4.51v-9h9V18.06ZM0,22.57H18.06V18.06H0Z"
    />
  </svg>
)

const library = {
  burgerBtn,
  leftArrow,
  logo,
  logoBm,
  logoIgf,
  logoBmeDark,
  logoMtx,
  workspace,
  menuWidget,
}

export default library
