/** @jsx jsx */
import { jsx } from 'theme-ui'
import { COOKIE_POLICY } from '../../common/routes'
import StaticLegalPage from '../../components/StaticLegalPage'

const PrivacyPolicy = () => {
  return (
    <StaticLegalPage title="Política de Privacidad">
      <h2>POLÍTICA DE PRIVACIDAD</h2>
      <p>
        La presente Política de Privacidad resulta aplicable e informa a los usuarios web y otros
        interesados sobre el tratamiento de los datos personales que puedan ser recabados en el
        marco de su relación contractual o societaria con las sociedades del Grupo BME o por medio
        de los sitios web de las sociedades del Grupo BME.
      </p>
      <h3>RESPONSABLE DEL TRATAMIENTO Y DATOS DE CONTACTO</h3>

      <p>
        A los efectos de la presente Política de Privacidad tendrá la condición de Responsable del
        Tratamiento la sociedad del Grupo BME con la cual el interesado mantenga una relación
        contractual, societaria o análoga que requiera el tratamiento de sus datos personales. Así,
        las sociedades que integran el Grupo BME y que podrán ostentar dicha condición son:
      </p>
      <p>
        <strong>
          BME BOLSAS Y MERCADOS ESPAÑOLES, SOCIEDAD HOLDING DE MERCADO Y SISTEMAS FINANCIEROS, S.A.,
        </strong>
        , con domicilio en Plaza de la Lealtad, 1, 28014 Madrid.
      </p>
      <hr></hr>
      <p>
        <strong>BME CLEARING, S.A.,</strong>, con domicilio en Plaza de la Lealtad, 1, 28014 Madrid.
      </p>
      <hr></hr>
      <p>
        <strong>BME POST TRADE SERVICES, S.A.</strong>, con domicilio en Plaza de la Lealtad, 1,
        28014 Madrid.
      </p>

      <hr></hr>
      <p>
        <strong>BME REGULATORY SERVICES, S.A.U.</strong>, con domicilio en Plaza de la Lealtad, 1,
        28014 Madrid.
      </p>

      <hr></hr>
      <p>
        <strong>BME INNTECH, S.A.U.</strong>, con domicilio en Plaza de la Lealtad, 1, 28014 Madrid.
      </p>

      <hr></hr>
      <p>
        <strong>BME RENTA FIJA, S.A.U.</strong>, con domicilio en Plaza de la Lealtad, 1, 28014
        Madrid.
      </p>

      <hr></hr>
      <p>
        <strong>BME SERVICIOS CORPORATIVOS, S.A.</strong>, con domicilio en Plaza de la Lealtad, 1,
        28014 Madrid.
      </p>

      <hr></hr>
      <p>
        <strong>
          IBERCLEAR - Sociedad de Gestión de los Sistemas de Registro Compensación y Liquidación De
          Valores, S.A.U.
        </strong>
        , con domicilio en Plaza de la Lealtad, 1, 28014 Madrid.
      </p>

      <hr></hr>
      <p>
        <strong>INSTITUTO BME, S.L.U.</strong>, con domicilio en Plaza de la Lealtad, 1, 28014
        Madrid.
      </p>

      <hr></hr>
      <p>
        <strong>MEFF TECNOLOGIA Y SERVICIOS, S.A.U.</strong>, con domicilio en Plaza de la Lealtad,
        1, 28014 Madrid.
      </p>

      <hr></hr>
      <p>
        <strong>MEFF SOCIEDAD RECTORA DEL MERCADO DE PRODUCTOS DERIVADOS, S.A.U.</strong>, con
        domicilio en Plaza de la Lealtad, 1, 28014 Madrid.
      </p>

      <hr></hr>
      <p>
        <strong>SOCIEDAD DE BOLSAS, S.A.U.</strong>, con domicilio en Plaza de la Lealtad, 1, 28014
        Madrid.
      </p>

      <hr></hr>
      <p>
        <strong>SOCIEDAD RECTORA DE LA BOLSA DE VALORES DE MADRID, S.A.U.</strong>, con domicilio en
        Plaza de la Lealtad, 1, 28014 Madrid.
      </p>

      <hr></hr>
      <p>
        <strong>SOCIEDAD RECTORA DE LA BOLSA DE VALORES DE BILBAO, S.A.U.,</strong>, con domicilio
        en C/Jose Mª. Olabarri, 1, 48001 Bilbao.
      </p>
      <hr></hr>

      <p>
        <strong>SOCIEDAD RECTORA DE LA BOLSA DE VALORES DE BARCELONA, S.A.U.</strong>, con domicilio
        en Paseo de Gracia, 19, 08007 Barcelona.
      </p>
      <hr></hr>

      <p>
        <strong>SOCIEDAD RECTORA DE LA BOLSA DE VALORES DE VALENCIA, S.A.U.</strong>, con domicilio
        en Calle del Pintor Sorolla, 23, 46002 Valencia.
      </p>

      <p>
        Sin perjuicio de los domicilios anteriores, las sociedades del Grupo BME pueden ser
        contactadas a través del correo electrónico info@grupobme.es
      </p>

      <h3>DATOS OBJETO DE TRATAMIENTO</h3>

      <p>
        El Grupo BME tratará los datos personales que los interesados (p.ej. nombre, apellidos,
        email, teléfono, etc.) puedan facilitar en el marco de la relación a través de los distintos
        canales habilitados por las distintas sociedades (ej. web, correo electrónico, canales de
        contacto...) o bien como consecuencia de su relación contractual o societaria con cualquiera
        de las sociedades del Grupo BME.
      </p>

      <p>
        Además, por el hecho de navegar en esta página web y mediante el uso de cookies, Grupo BME
        recaba datos de los usuarios (como el tipo de navegador utilizado, el sistema operativo o la
        dirección IP), con el objeto de mejorar su navegación y gestionar de manera efectiva el
        portal, pudiendo obtener información más detallada en la
        <a href={COOKIE_POLICY}>Política de Cookies</a>
        del sitio web.
      </p>

      <h3>FINALIDADES Y LEGITIMACIÓN DEL TRATAMIENTO </h3>

      <p>
        Grupo BME tratará los datos personales para la correcta gestión, desarrollo y ejecución de
        la relación con los interesados, así como para cualesquiera trámites adicionales que puedan
        derivarse de la misma. Entre otras, Grupo BME tratará los datos recabados para las
        siguientes finalidades:
      </p>

      <ul>
        <li>
          Atender las solicitudes de información y peticiones planteadas por los propios interesados
          a través de los canales habilitados, como el correo electrónico facilitado en la web. Este
          tratamiento se encuentra legitimado en el consentimiento otorgado por el interesado
          mediante su remisión.
        </li>
        <li>
          Valorar las candidaturas e información facilitada con el objeto de participar en los
          procesos de selección, encontrándose legitimado en el consentimiento otorgado por el
          interesado mediante su participación.
        </li>
        <li>
          Gestionar la inscripción y asistencia a eventos ofertados y organizados por Grupo BME o
          sus colaboradores. Este tratamiento se legitima en el consentimiento otorgado por el
          interesado mediante su inscripción.
        </li>
        <li>
          Formalizar el alta, matrícula, participación, remisión de documentación y material
          formativo, así como otros trámites derivados de la relación como alumno de Instituto BME
          necesarios para la impartición de los cursos solicitados, legitimado en el consentimiento
          mostrado por el alumno con su matrícula e interés legítimo de Grupo BME para el
          mantenimiento de la relación.
        </li>
        <li>
          Gestionar la relación, contratos y facturación con los proveedores necesarios para la
          prestación de los servicios y actividades del Grupo BME, legitimado en la ejecución del
          contrato con los mismos.
        </li>
        <li>
          Remitir comunicaciones, newsletters e información sobre campañas, productos y servicios
          del Grupo BME sobre la base del consentimiento mostrado por el interesado con su solicitud
          y, en su caso, en el interés legítimo del Grupo BME de mantenerles informados en el marco
          de la relación.
        </li>
        <li>
          Cumplir los requerimientos de la CNMV, autoridades administrativas y/o judiciales y
          obligaciones legales del Grupo BME, legitimado en el cumplimiento de las obligaciones
          legales exigibles a las sociedades.
        </li>
        <li>
          Garantizar la salud y seguridad del personal, interesados e instalaciones del Grupo BME
          mediante, entre otros, la instalación de sistemas de videovigilancia y control de accesos;
          basado en el interés legítimo, e interés público en su caso, de velar por la seguridad.
        </li>
        <li>
          Mantenimiento de la relación contractual con los miembros y clientes y prestación de los
          servicios ofrecidos por cualquiera de las sociedades del Grupo BME.
        </li>
        <li>
          Grabar y registrar las llamadas telefónicas y comunicaciones electrónicas, legitimado en
          el cumplimiento de las obligaciones legales exigibles a las entidades (entre otras:
          Directiva MiFID I y MiFID II, Reglamento MiFIR), así como sobre la base del interés
          legítimo de BME de garantizar la calidad del servicio, verificar la veracidad e integridad
          de las comunicaciones y a efectos probatorios.
        </li>
        <li>
          Los datos recabados a través de la utilización de cookies se tratarán para permitir el
          efectivo funcionamiento de la página web, basado en el interés legítimo de las compañías
          del Grupo BME y en su consentimiento mostrado con la aceptación o rechazo de las mismas.
        </li>
      </ul>

      <p>
        Asimismo, se informa a los interesados de la necesidad de facilitar todos los datos de
        carácter obligatorio identificados como tal en el marco de la relación con el Grupo BME para
        poder cumplir con las finalidades anteriormente indicadas. El hecho de no facilitar los
        datos obligatorios podrá dar lugar a la imposibilidad de cumplir tales finalidades.
      </p>

      <h3>CONSERVACIÓN DE LOS DATOS</h3>

      <p>
        Los datos personales de los interesados son conservados durante el tiempo estrictamente
        necesario para cumplir de manera efectiva con las finalidades indicadas y, en todo caso,
        siempre que resulten idóneos, adecuados y pertinentes para la finalidad para la cual fueron
        recabados.
      </p>
      <p>
        En particular, y en aquellos casos en los que el interesado haya otorgado su consentimiento,
        Grupo BME conservará los datos personales en tanto en cuanto el interesado no retire su
        consentimiento o muestre su oposición. La retirada del consentimiento del interesado no
        afectará a la legitimidad del tratamiento de datos realizado hasta el momento por Grupo BME,
        siendo completamente lícito hasta la fecha de la retirada.
      </p>
      <p>
        En cuanto a la conservación de la información relativa a los datos de navegación y cookies,
        puede obtener más información en nuestra Política de Cookies.
      </p>

      <p>
        Lo dispuesto en este apartado sin perjuicio de que, en aras de cumplir con las obligaciones
        legales del Grupo BME, los datos sean debidamente bloqueados tras la retirada del
        consentimiento, ejercicio de derechos o consecución de la finalidad perseguida, para atender
        posibles responsabilidades o requerimientos derivados de la relación con el interesado.
      </p>

      <h3>DESTINATARIOS Y TRANSFERENCIAS INTERNACIONALES DE DATOS</h3>

      <p>
        Los datos recabados de interesados del Grupo BME no serán comunicados a terceros salvo
        obligación legal o necesidad para la correcta ejecución de la relación existente. Sin
        perjuicio de ello, y en base al interés legítimo del Grupo BME, los datos podrán ser
        comunicados a SIX GROUP SERVICES Ltd. con fines administrativos internos, empresa matriz del
        Grupo BME ubicada en Suiza, país declarado con nivel de protección adecuado.
      </p>

      <p>
        Adicionalmente, sin perjuicio de las transferencias previstas en la Política de Cookies y en
        caso de emplear para las actividades del Grupo BME prestadores de servicios ubicados fuera
        del Espacio Económico Europeo, los datos de interesados podrán ser objeto de transferencia
        internacional, entre otros, a Estados Unidos, adoptando en todo caso Grupo BME medidas
        adecuadas de seguridad para garantizar su protección y confidencialidad.
      </p>

      <h3>EJERCICIO DE DERECHOS</h3>

      <p>
        En cualquier momento, los interesados podrán ejercer los siguientes derechos reconocidos por
        la normativa vigente de protección de datos:
      </p>

      <ul>
        <li>
          Derecho de Acceso: permite al usuario conocer qué información se tiene, de dónde se ha
          obtenido, a quién se ha facilitado y con qué usos se ha tratado.
        </li>
        <li>
          Derecho de Rectificación: permite al usuario rectificar algún dato erróneo o
          desactualizado.
        </li>
        <li>
          Derecho de Supresión: permite al usuario solicitar que se dejen de tratar sus datos.
        </li>
        <li>
          Derecho de Limitación: permite al usuario restringir el tratamiento de sus datos, pero de
          forma que sean conservados para alguna finalidad posterior (una reclamación judicial, por
          ejemplo).
        </li>
        <li>
          Derecho de Portabilidad: permite al usuario obtener una copia de sus datos en forma
          electrónica y, en determinadas circunstancias, solicitar que sean comunicados a otro
          prestador de servicios.
        </li>
        <li>
          Derecho de Oposición: permite al usuario oponerse al interés legítimo de Grupo BME para
          solicitar el cese del tratamiento de sus datos.
        </li>
        <li>
          Derecho a Retirar el Consentimiento, en cualquier momento, respecto de tratamiento de sus
          datos personales, sin que ello afecte a la licitud del mismo.
        </li>
      </ul>

      <p>
        En caso de que el tratamiento de datos resulte necesario para ejercitar o atender
        reclamaciones, cumplir con obligaciones legales exigibles al Grupo BME, no se cumplan los
        requisitos del RGPD o deban prevalecer intereses de terceros y/o del Grupo BME, la solicitud
        de ejercicio de su derecho podrá ser desestimada de forma motivada.
      </p>
      <p>
        El ejercicio de estos derechos deberá dirigirse al Delegado de Protección de Datos del Grupo
        BME.
      </p>
      <p>
        El interesado podrá ejercitar sus derechos cumplimentando el siguiente formulario:
        <a
          href="https://privacyportal-ch.onetrust.com/webform/a8a308f1-1ad4-4b5d-8b15-487e80eb979b/7f2e48fe-9212-4540-8737-02497afe52d7"
          target="_blank"
          rel="nofollow noopener"
        >
          LINK
        </a>
      </p>

      <p> Igualmente, el interesado podrá dirigir sus solicitudes de ejercicio de derechos a: </p>

      <p>
        Delegado de Protección de Datos Grupo BME <br />
        Plaza de la Lealtad, 1 <br />
        28014 Madrid <br />
        España
        <br />
        protecciondedatos@grupobme.es
      </p>

      <p>
        El ejercicio de derechos debe ejercitarse directamente por el interesado indicando qué
        derecho desea ejercitar y la compañía del Grupo BME a la que dirige su solicitud. Las
        solicitudes deberán estar acompañadas de DNI o documento análogo acreditativo de identidad
      </p>
      <p>
        En caso de no haber obtenido una respuesta satisfactoria al ejercicio de derechos, los
        interesados podrán presentar una reclamación ante la Agencia Española de Protección de
        Datos, a través de www.aepd.es.
      </p>

      <h3>MEDIDAS DE SEGURIDAD </h3>

      <p>
        Grupo BME ha adoptado las medidas técnicas y organizativas apropiadas para garantizar un
        nivel de seguridad adecuado a los datos personales tratados, garantizando en todo momento la
        confidencialidad, integridad y disponibilidad de los mismos y evitando su pérdida,
        alteración, tratamiento o acceso no autorizado.
      </p>

      <h3>MODIFICACIÓN DE LA POLÍTICA DE PRIVACIDAD </h3>
      <p>
        Grupo BME se reserva el derecho a modificar la presente Política de Privacidad, siempre de
        acuerdo con los términos permitidos por la legislación española vigente y con la
        correspondiente comunicación a los interesados mediante la publicación en el sitio web o por
        cualquier otro medio de comunicación o difusión que se considere oportuno. Los interesados
        se comprometen a revisar la presente Política ante posibles cambios o modificaciones en su
        contenido.
      </p>

      <p> Fecha de actualización: enero 2022. </p>
    </StaticLegalPage>
  )
}

export default PrivacyPolicy
