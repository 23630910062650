import { searchAccount } from '../common/utilities'
import { createSelector } from 'reselect'

export const oldHydratedInstrumentSelector = state => {
  const instrumentList = state.instruments
  const marketList = state.markets.data
  const currentAccount = state.workspace.currentAccount
  const accountGroups = state.workspace.accountGroups
  const accounts = state.accounts.data

  const result = []

  console.warn(currentAccount)
  console.warn(accountGroups)
  console.warn(accounts)

  // instrumentList.dataIds.map(id => {
  //     const instrument = instrumentList.data[id]
  //     const market = marketList[instrument.exchangeId];
  //     const selectedAccount = searchAccount(currentAccount, accountGroups, accounts, instrument)

  //     instrument.marketNeedsAccount = market && market.configuration && market.configuration.allowedFeatures ? market.configuration.allowedFeatures.includes('ORK') : false
  //     instrument.selectedAccount = selectedAccount

  //     result.push(instrument)
  // });

  const result1 = instrumentList.dataIds.reduce((acc, id) => {
    const instrument = instrumentList.data[id]
    const market = marketList[instrument.exchangeId]
    const selectedAccount = searchAccount(currentAccount, accountGroups, accounts, instrument)

    instrument.marketNeedsAccount = market?.configuration?.allowedFeatures
      ? market.configuration.allowedFeatures.includes('ORK')
      : false
    instrument.selectedAccountId = selectedAccount?.id

    return { ...acc, [id]: { ...instrument } }
  }, {})

  console.warn(result1)

  return result1
}

// const marketConfigForInstrument = Object.values(markets).find(
//   market => market.exchangeId === instrument?.exchangeId
// )
// const hasOrkFeature = marketConfigForInstrument
//   ? marketConfigForInstrument.configuration.allowedFeatures.includes('ORK')
//   : false

export const selectInstruments = state => state.instruments
export const selectMarkets = state => state.markets.data
export const selectCurrentAccount = state => state.workspace.currentAccount
export const selectAccountGroups = state => state.workspace.accountGroups
export const selectAccounts = state => state.accounts.data

export const hydratedInstrumentSelector = createSelector(
  selectInstruments,
  selectMarkets,
  selectCurrentAccount,
  selectAccountGroups,
  selectAccounts,
  (instruments, markets, currentAccount, accountGroups, accounts) => {
    const result1 = instruments.dataIds.reduce((acc, id) => {
      const instrument = instruments.data[id]
      const market = markets[instrument.exchangeId]
      const selectedAccount = searchAccount(currentAccount, accountGroups, accounts, instrument)
      const allowedFeatures = market?.configuration?.allowedFeatures || []
      const allowedSubscriptions = market?.configuration?.allowedMarketDataSubscriptions || []
      const allowedTimeInForces = market?.configuration?.allowedTimeInForces || {}
      const allowedOrderTypes = market?.configuration?.allowedOrderTypes || []
      const allowedRequestTypes = market?.configuration?.allowedRequestTypes || []

      instrument.marketNeedsAccount = allowedFeatures.includes(
        'REQUIRES_ACCOUNT_FOR_DATA_SUBSCRIPTION'
      )
      instrument.marketHasOrk = allowedFeatures.includes('ORK')
      instrument.marketAllowsQuantityCurrency = allowedFeatures.includes('QUANTITY_CURRENCY')
      instrument.allowedFeatures = allowedFeatures
      instrument.allowedSubscriptions = allowedSubscriptions
      instrument.allowedTimeInForces = allowedTimeInForces
      instrument.allowedOrderTypes = allowedOrderTypes
      instrument.allowedRequestTypes = allowedRequestTypes
      instrument.selectedAccountId = selectedAccount?.id

      return { ...acc, [id]: { ...instrument } }
    }, {})

    console.warn(result1)

    return result1
  }
)

export const instrumentIdSelector = (_, ownProps) => {
  //TODO: Fix ids
  if (!ownProps.instrument.id.startsWith(ownProps.instrument.exchangeId)) {
    return ownProps.instrument.exchangeId + ':' + ownProps.instrument.id
  } else {
    return ownProps.instrument.id
  }
}

export const instrumentSelector = (state, ownProps) => {
  const instruments = hydratedInstrumentSelector(state, ownProps)
  const instrumentId = instrumentIdSelector(state, ownProps)
  return instruments[instrumentId]
}

export const instrumentsGroupsSelector = state => state.instruments.groupBy
