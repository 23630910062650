/** @jsx jsx */
import { jsx } from 'theme-ui'
import { columnsHead, defaultSorted } from './columnsHead'

import React, { useEffect, useContext, useMemo } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'ramda'

import { PanelTable } from '../Table'
import 'react-table/react-table.css'
import { getTradeList } from '../../actions/orders'
import { setAccessor, setOpenAccessor } from '../../actions/workspace'
import { fmtDateLong } from '../../common/utilities'
import { I18nContext } from '../../containers/i18n'

const TradeList = ({ keycloak, getTradeList, tradeList, tabIndex, instruments, accounts }) => {
  const { t } = useContext(I18nContext)

  useEffect(() => {
    getTradeList(keycloak.token)
  }, [])

  const getSubType = (operationAccount, symbol) => {
    const instrument = instruments.data[`${accounts[operationAccount]?.exchangeId}:${symbol}`]
    return instrument?.securitySubType ? instrument.securitySubType : instrument?.securityType || ''
  }

  const memoizedTableData = useMemo(
    () =>
      !tradeList || isEmpty(tradeList)
        ? []
        : tradeList.map(t => ({
            ...t,
            formatDate: fmtDateLong(t.date),
            productSubTypeFormat: getSubType(t.operationAccount, t.symbol),
            formatQuantity: +t.quantity,
            formatPrice: +t.price,
            formatAccount: accounts[t.operationAccount]?.name || '',
          })),
    [tradeList, t]
  )

  return (
    <PanelTable
      rows={memoizedTableData}
      defaultSorted={defaultSorted}
      getColumns={() => columnsHead(t, tabIndex)}
      tabIndex={tabIndex}
    />
  )
}

const mapStateToProps = ({
  order: { tradeList },
  instruments,
  workspace: { workspaces, currentWorkspace },
  accounts: { data: accounts },
}) => ({
  tradeList,
  instruments,
  workspaces,
  currentWorkspace,
  accounts,
})
const mapDispatchToProps = { getTradeList, setAccessor, setOpenAccessor }

export default connect(mapStateToProps, mapDispatchToProps)(TradeList)
