/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui'
import { connect } from 'react-redux'
import React, { useContext, useState, useEffect } from 'react'

import { omit, isEmpty } from 'ramda'
import CustomButton from '../CustomButton'
import { Content } from './style'
import * as style from './style'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContentRow,
  DialogRowElement,
  Label,
} from '../Dialog'
import DialogInput from '../DialogInput'
import SelectSearch from '../SelectSearch'
import { I18nContext } from '../../containers/i18n'
import {
  showErrorDialog,
  updateAccountGroup,
  addAccountGroup,
  deleteAccountGroup,
} from '../../actions/workspace'

const fieldsValidation = ({ groupName, accountsByMarket }) => {
  const validations = [
    {
      isValid: !isEmpty(groupName),
      messageLiteral: 'validateAccountGroup_NotEmptyName',
    },
    {
      isValid: !isEmpty(accountsByMarket),
      messageLiteral: 'validateAccountGroup_NotEmptyAccounts',
    },
  ]

  return {
    isValid: validations.every(e => e.isValid),
    errorMessageLiterals: validations.filter(e => !e.isValid).map(e => e.messageLiteral),
  }
}

const ModalAccountGroup = ({
  open,
  title,
  data,
  onSave = () => {},
  onUpdate = () => {},
  onAdd = () => {},
  onDelete = () => {},
  onClose,
  //connect
  markets,
  marketIds,
  accounts,
  accountsGroupBy,
  showErrorDialog,
  updateAccountGroup,
  addAccountGroup,
  deleteAccountGroup,
  accountGroups,
}) => {
  const { t } = useContext(I18nContext)

  const [colorMode] = useColorMode()

  const handleModal = groupName =>
    showErrorDialog({
      title: t('modalTitleDeleteAccount')(groupName),
      type: 'ERROR',
      message: t('modalDeleteAccountGroup')(groupName),
      onConfirm: handleDelete,
      confirmLiteral: 'delete',
    })

  const [groupName, setGroupName] = useState(data?.name || '')
  const [accountsByMarket, setAccountsByMarket] = useState({})

  const emptyFields = () => {
    setGroupName('')
    setAccountsByMarket({})
  }

  useEffect(() => {
    setGroupName(data?.name || '')
    setAccountsByMarket(data?.accounts || {})
  }, [data])

  const isSaveButtonDisabled = false

  const validateFields = () => {
    const validation = fieldsValidation({ groupName, accountsByMarket })
    !validation.isValid &&
      showErrorDialog({
        title: t('validateAccountGroup_title'),
        type: 'ERROR',
        message: validation.errorMessageLiterals.map(e => t(e)).join('.\n'),
      })
    return validation.isValid
  }

  const handleClose = () => {
    emptyFields()
    onClose()
  }

  const handleCancel = () => {
    setGroupName('')
    setAccountsByMarket({})
    handleClose()
  }

  const handleDelete = () => {
    deleteAccountGroup({ id: data.id })
    onDelete(data.id)
    handleClose()
  }
  const handleSave = () => {
    const isUpdate = +data?.id === 0 || +data?.id
    //if is new account, the id is 1 more than the greater id
    const calculatedId = isUpdate ? data.id : Math.max(0, ...accountGroups.map(b => +b.id)) + 1
    const saveData = {
      id: calculatedId,
      name: groupName,
      type: 'group',
      accounts: accountsByMarket,
    }
    const saveAction = isUpdate ? updateAccountGroup : addAccountGroup
    saveAction(saveData)
    onSave(saveData)
    isUpdate ? onUpdate(saveData) : onAdd(saveData)
    handleClose()
  }

  const accountsToOptions = (accountIds, t) =>
    accountIds &&
    accountIds.map(accountId => ({
      value: accountId,
      label: `${accounts[accountId].client?.shortName || t('member')}: ${accounts[accountId].name}`,
    }))

  return (
    open && (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="edit-order-dialog-title" disableTypography colormode={colorMode}>
          {title || t('accountGroups')}
        </DialogTitle>

        <Content sx={{ minWidth: '700px' }}>
          <DialogContentRow>
            <DialogInput
              label={t('accountGroupName')}
              value={groupName}
              maxLength={100}
              onChange={value => setGroupName(value)}
              labelProps={{ sx: { minWidth: '250px' } }}
              colorMode={colorMode}
            />
          </DialogContentRow>
          <DialogContentRow sx={{ flexFlow: 'column' }}>
            <DialogRowElement>
              <Label
                sx={{
                  ...style.headLabel,
                  minWidth: '250px',
                }}
              >
                {t('market')}
              </Label>
              <Label sx={{ ...style.headLabel, width: '100%' }}>{t('accounts')}</Label>
            </DialogRowElement>
            {marketIds?.map(marketId => (
              <SelectSearch
                placeholder="selectAccount"
                noneEnabled
                labelProps={{ sx: { minWidth: '250px' } }}
                label={`${markets[marketId].marketName} - ${markets[marketId].segmentName}`}
                options={accountsToOptions(accountsGroupBy[markets[marketId].exchangeId]?.data, t)}
                value={accountsByMarket[markets[marketId].exchangeId]?.label || ''}
                onChange={e => {
                  setAccountsByMarket({
                    ...omit([markets[marketId].exchangeId], accountsByMarket),
                    ...(e.value && { [markets[marketId].exchangeId]: e }),
                  })
                }}
              />
            ))}
          </DialogContentRow>
        </Content>

        <DialogActions>
          <CustomButton
            type="cancel"
            size="large"
            width="27%"
            disabled={isSaveButtonDisabled}
            onClick={() => {
              validateFields() && handleSave()
            }}
          >
            {t('save')}
          </CustomButton>
          {data?.id && (
            <CustomButton
              type="cancel"
              size="large"
              width="27%"
              onClick={() => handleModal(groupName)}
            >
              {t('delete')}
            </CustomButton>
          )}
          <CustomButton type="cancel" size="large" width="27%" onClick={handleCancel}>
            {t('cancel')}
          </CustomButton>
        </DialogActions>
      </Dialog>
    )
  )
}

const mapStateToProps = ({
  markets: { data: markets, dataIds: marketIds },
  accounts: { data: accounts, groupBy: accountsGroupBy },
  workspace: { accountGroups },
}) => ({
  markets,
  marketIds,
  accountsGroupBy,
  accounts,
  accountGroups,
})

const mapDispatchToProps = {
  showErrorDialog,
  updateAccountGroup,
  addAccountGroup,
  deleteAccountGroup,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAccountGroup)
