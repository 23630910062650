import styled from '@emotion/styled'
import FormLabel from '@material-ui/core/FormLabel'

import { DialogContent } from '../Dialog'

export const Label = styled(FormLabel)`
  font-size: 15px;
  font-weight: 500;
`

export const infoLabel = { color: 'black' }
export const errorLabel = { color: 'red' }
export const warningLabel = { color: 'orange' }

export const Content = styled(DialogContent)`
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  min-height: max-content;
`

export const Field = styled.div`
  min-width: 350px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
`
