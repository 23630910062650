const largeNumbers = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'K' }, //thousand
  { value: 1e6, symbol: 'M' }, //Million
]

export const shortenNumber = (num, digits = 2) => {
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for (i = largeNumbers.length - 1; i > 0; i--) {
    if (num >= largeNumbers[i].value) {
      break
    }
  }
  return `${(num / largeNumbers[i].value).toFixed(digits).replace(rx, '$1')} ${
    largeNumbers[i].symbol
  }`
}
