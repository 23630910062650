/** @jsx jsx */
import { jsx } from 'theme-ui'
import Button from '@material-ui/core/Button'

const CustomButton = ({ type, halfWidth, noMargin, ...props }) => (
  <Button
    variant="contained"
    color="default"
    {...props}
    sx={{
      borderRadius: 0,
      boxShadow: 'none',
      m: noMargin ? 0 : '8px',
      variant: `buttons.${type}`,
      width: props.width,
    }}
  />
)

export default CustomButton
