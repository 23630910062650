/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import 'react-table/react-table.css'

import { PanelTable } from '../Table'
import { I18nContext } from '../../containers/i18n'
import { columnsHead } from './columnsHead'
import { setPositionsTabs } from '../../actions/positions'

const AccountSummaryList = ({ accountsSummaries, tabIndex, workspaces, setPositionsTabs }) => {
  const { t } = useContext(I18nContext)

  useEffect(() => {
    setPositionsTabs()
  }, [workspaces])

  return (
    <PanelTable rows={accountsSummaries} getColumns={() => columnsHead(t)} tabIndex={tabIndex} />
  )
}

const mapStateToProps = ({
  positions: { accountsSummaries },
  accounts: { dataIds: accountIds },
  workspace: { workspaces },
}) => ({
  accountsSummaries,
  accountIds,
  workspaces,
})

const mapDispatchToProps = {
  setPositionsTabs,
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountSummaryList)
