/** @jsx jsx */
import { jsx } from 'theme-ui'

import { connect } from 'react-redux'
import React, { useState, useContext, memo } from 'react'
import * as style from './style'
import AmountSelector from '../AmountSelector'
import { I18nContext } from '../../containers/i18n'
import { CustomSelect, getOptions } from '../../common/utilities'
import { updateAmountToOperate, updateCurrency, changeTradingCapacity } from '../../actions/workspace'
import SpotOrk from './spotOrk'
import PermissionsVisible from '../../containers/PermissionsProvider/PermissionsVisible'
import OrkVisible from './orkVisible'

const tradingCapacityTypes = ['ANY_OTHER', 'MATCH', 'DEAL']
const wh = { width: '32%', height: '21px' }

const AmountToOperate = ({
  changeTradingCapacity,
  currencyData,
  spotIndex,
  tabIndex,
  updateAmountToOperate,
  updateCurrency,
  instrument,
}) => {
  const { t } = useContext(I18nContext)
  const [tradingCapacity, setTradingCapacity] = useState('ANY_OTHER')

  return (
    <div sx={style.container}>
      <AmountSelector
        value={currencyData?.amountToOperate}
        updateFn={updateAmountToOperate}
        updateCurrency={updateCurrency}
        spotIndex={spotIndex}
        tabIndex={tabIndex}
        styles={{ height: '36px' }}
        endAdornmentText={t('QTY')}
        currencies={instrument?.marketAllowsQuantityCurrency ? [instrument.baseCurrency, instrument.quoteCurrency] : []}
        currency={instrument?.marketAllowsQuantityCurrency ? (currencyData?.currency || instrument.baseCurrency) : null}
      />
      {instrument?.marketHasOrk && (
        <PermissionsVisible anyRoles={['member_trader']}>
          <OrkVisible instrumentType={instrument?.securityType}>
            <div sx={style.trading}>
              <CustomSelect
                sx={{ ...style.input, ...wh, mt: '1px', pl: '0px', fontSize: '14px', pt: '2px' }}
                value={currencyData.tradingCapacity || tradingCapacity}
                onChange={e => {
                  changeTradingCapacity({
                    tradingCapacity: e.target.value,
                    spotIndex,
                    tabIndex,
                  })
                  setTradingCapacity(e.target.value)
                }}
              >
                {getOptions(t, tradingCapacityTypes)}
              </CustomSelect>
              <SpotOrk
                label="C"
                type="clientId"
                tabIndex={tabIndex}
                spotIndex={spotIndex}
                data={currencyData?.clientId || ''}
              ></SpotOrk>
              <SpotOrk
                label="D"
                type="decisorId"
                tabIndex={tabIndex}
                spotIndex={spotIndex}
                data={currencyData?.decisorId || ''}
              ></SpotOrk>
              <SpotOrk
                label="E"
                type="executorId"
                tabIndex={tabIndex}
                spotIndex={spotIndex}
                data={currencyData?.executorId || ''}
              ></SpotOrk>
            </div>
          </OrkVisible>
        </PermissionsVisible>
      )}
    </div>
  )
}

const mapDispatchToProps = { updateAmountToOperate, updateCurrency, changeTradingCapacity }

export default connect(null, mapDispatchToProps)(memo(AmountToOperate))
