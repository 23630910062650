/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useContext, useState } from 'react'
import * as style from './style'
import { I18nContext } from '../../containers/i18n'
import { InputBase, Typography } from '@material-ui/core'
import { allowOnlyNumbers } from '../../common/utilities'

const Ork = ({ label, tLabel, value = '', onChange }) => {
  const { t } = useContext(I18nContext)
  const [inputValue, setInputValue] = useState(value)

  return (
    <div sx={style.orkContainer}>
      <Typography sx={{ ...style.orkTipography }}>{label || t(tLabel)}</Typography>
      <InputBase
        inputProps={{
          maxLength: '10',
        }}
        sx={{
          ...style.input,
          ...style.orkInput,
        }}
        onBlur={e => e.target.value !== value && onChange(e.target.value)}
        onChange={e => setInputValue(e.target.value)}
        value={inputValue ? inputValue : ''}
        onPaste={allowOnlyNumbers}
        onKeyPress={allowOnlyNumbers}
      />
    </div>
  )
}

export default Ork
