import React from 'react'
import { Button, Table, Paper, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'

import style from './style.css'

const RfqTable = ({ action, bids, asks, bidKeys, askKeys }) => (
  <Paper className={style.tableFormat}>
    <Table padding="dense">
      <TableHead>
        <TableRow>
          <TableCell>Banks</TableCell>
          <TableCell>diff</TableCell>
          <TableCell />
          <TableCell />
          <TableCell>diff</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {bidKeys.map((key, index) => (
          <TableRow key={key}>
            <TableCell>{bids[key].party}</TableCell>
            <TableCell>{bids[key].diff1}</TableCell>
            <TableCell>
              <Button
                variant="contained"
                size="small"
                onClick={() => action(bids[key].quoteId, bids[key].quoteMsgId, 'SELL')}
              >
                {bids[key].bidPrice}
              </Button>
            </TableCell>
            <TableCell>
              <Button
                variant="contained"
                size="small"
                onClick={() =>
                  action(asks[askKeys[index]].quoteId, asks[askKeys[index]].quoteMsgId, 'BUY')
                }
              >
                {asks[askKeys[index]].offerPrice}
              </Button>
            </TableCell>
            <TableCell>{asks[askKeys[index]].diff2}</TableCell>
            <TableCell>{asks[askKeys[index]].party}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Paper>
)

export default RfqTable
