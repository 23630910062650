import { tableColumns } from '../../common/utilities'

const columnsHead = t => {
  const c = tableColumns(t)

  return [
    c.getColumn({ accessor: 'market', csvHeader: 'market' }),
    c.getColumn({ accessor: 'client', csvHeader: 'client' }),
    c.getFromToFloatColumn({ accessor: 'account', csvHeader: 'account' }),
    c.getColumn({ accessor: 'fixId', csvHeader: 'fixId' }),
    c.getColumn({ accessor: 'accountId', csvHeader: 'accountId' }),
  ]
}

export { columnsHead }
