/** @jsx jsx */
import { jsx } from 'theme-ui'
import { columnsHead } from './columnsHead'

import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import 'react-table/react-table.css'

import { PanelTable } from '../Table'
import { I18nContext } from '../../containers/i18n'
import { setPositionsTabs } from '../../actions/positions'

const PositionList = ({
  calculatedPositions,
  tabIndex,
  allAccounts = false,
  workspaces,
  setPositionsTabs,
}) => {
  const { t } = useContext(I18nContext)

  useEffect(() => {
    setPositionsTabs()
  }, [workspaces])

  return (
    <PanelTable rows={calculatedPositions} getColumns={() => columnsHead(t)} tabIndex={tabIndex} />
  )
}

const mapStateToProps = (
  { positions: { calculatedPositions, calculatedAccountPositions } },
  ownProps
) => ({
  calculatedPositions: ownProps.allAccounts ? calculatedPositions : calculatedAccountPositions,
})

const mapDispatchToProps = {
  setPositionsTabs,
}

export default connect(mapStateToProps, mapDispatchToProps)(PositionList)
