import React, { useState } from 'react'

export const PermissionsContext = React.createContext()

const anyElements = (roles, rolesToCheck) => rolesToCheck?.some(r => roles?.includes(r))
const allElements = (roles, rolesToCheck) => rolesToCheck?.every(r => roles?.includes(r))

//expect(anyElements(["ADMIN", "USER"], ["TRADER"])).toBe(false);

const PermissionsProvider = ({ children }) => {
  const [roles, setRoles] = useState([])

  const hasAnyRole = rolesToCheck => anyElements(roles, rolesToCheck)
  const hasAllRoles = rolesToCheck => allElements(roles, rolesToCheck)

  const providerValue = {
    hasAnyRole,
    hasAllRoles,
    setRoles,
  }

  return <PermissionsContext.Provider value={providerValue}>{children}</PermissionsContext.Provider>
}

export default PermissionsProvider
