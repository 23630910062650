import React from 'react'
import FormattedNumberText, { FormattedCurrency } from '../../components/FormattedNumber'

export const numberCell = ({ value }) => <FormattedNumberText value={value} />

export const currencyCell = (value, currency) => (
  <FormattedCurrency value={value} currency={currency} />
)

export const baseCurrencyCell = ({ value, original }) => currencyCell(value, original.base)
export const quoteCurrencyCell = ({ value, original }) => currencyCell(value, original.quote)
export const eurCurrencyCell = ({ value }) => currencyCell(value, 'EUR')
