export const menuColumns = {
  maxHeight: '400px',
}

export const listItem = {
  color: 'text',
}

export const menu = {
  backgroundColor: 'accentAlt',
  '&:hover': {
    backgroundColor: theme => theme.colors.table.menu.bgHover,
  },
  '&:focus': {
    backgroundColor: 'accentAlt',
  },
}

export const container = {
  marginTop: '0px',
}

export const sxTable = (colorMode, disabled = false) => ({
  color: 'text',
  '& .-odd ': {
    backgroundColor: 'odd',
  },
  '& .-even ': {
    backgroundColor: 'even',
  },
  '& .-filters ': {
    backgroundColor: 'accent',
  },
  '& .rt-th ': {
    backgroundColor: 'accentAlt',
  },
  '& .rt-th .headerContent': {
    // Removed background to be able to show the current sort column
    //backgroundColor: 'accentAlt',
    borderRight: '1px solid',
    borderRightColor: 'background',
    padding: '3px',

    //whiteSpace: 'normal',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& div.rt-td': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .rt-thead.-filters input': {
    backgroundColor: 'primary',
    color: 'text',
    border: theme => theme.borders.secondary[colorMode],
    borderColor: 'muted',
    '&:hover': {
      backgroundColor: 'secondary',
    },
  },
  mixBlendMode: disabled ? 'soft-light' : 'unset',
})

export const thead = {
  position: 'sticky',
  top: 0,
  padding: '0px 0px',
  boxShadow: 'none',
}
