/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import { Select, Input, MenuItem as MMenuItem } from '@material-ui/core'
import styled from '@emotion/styled'

const select = {
  borderRadius: 0,
  borderStyle: 'none',
  '& svg': {
    fill: 'text',
  },
}

const customSelect = (theme, colorMode) => ({
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
  MenuListProps: {
    disablePadding: true,
    style: {
      padding: 0,
      color: 'white',
    },
  },
  PaperProps: {
    style: {
      borderRadius: 0,
      boxShadow: 'none',
      border: theme.borders.primary[colorMode],
      borderColor: theme.colors.muted,
    },
  },
})

export const CustomSelect = ({ ...props }) => {
  const { theme, colorMode } = useThemeUI()

  return (
    <Select
      sx={select}
      MenuProps={customSelect(theme, colorMode)}
      input={
        <Input
          disableUnderline
          sx={{
            borderStyle: 'none',
            '&:focus': {
              bg: 'transparent',
              outline: 'none',
            },
          }}
        />
      }
      {...props}
    />
  )
}

export const MenuItem = styled(MMenuItem)`
  background-color: ${props => props.theme.colors.items};
  color: ${props => props.theme.colors.text};
  &:hover {
    background-color: ${props => props.theme.colors.secondary};
  }
  &:focus {
    background-color: ${props => props.theme.colors.secondary};
  }
  &.customSelected {
    background-color: ${props => props.theme.colors.secondary};
    &:focus {
      background-color: ${props => props.theme.colors.secondary};
    }
  }
`
