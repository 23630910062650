import { useContext } from 'react'
import { PermissionsContext } from '../PermissionsProvider'

const PermissionsVisible = ({ children, allRoles, anyRoles }) => {
  const { hasAnyRole, hasAllRoles } = useContext(PermissionsContext)

  const isAnyRoles = anyRoles && hasAnyRole(anyRoles)
  const isAllRoles = allRoles && hasAllRoles(allRoles)
  const isVisible = isAnyRoles || isAllRoles

  return isVisible ? children : null
}

export default PermissionsVisible

/* ejemplos de uso
    <PermissionsVisible anyRoles={["user"]}>
        <button>Botón solo para user</button>
    </PermissionsVisible>
    <PermissionsVisible anyRoles={["user", "admin"]}>
        <button>Botón para user o admin</button>
    </PermissionsVisible>
    <PermissionsVisible allRoles={["user", "uma_authorization"]}>
        <button>Botón para user y uma_authorization</button>
    </PermissionsVisible>
    <PermissionsVisible allRoles={["user", "admin"]}>
        <button>Botón para user y admin</button>
    </PermissionsVisible>
*/
