export const paper = {
  display: 'flex',
  flexDirection: 'column',
  color: 'text',
  '*': { ':focus': { outline: 'none' } },
}

export const container = {
  position: 'absolute',
  top: '50px',
  left: '16px',
  right: '16px',
}

export const currency = colorMode => ({
  fontFamily: theme => theme.fonts.body[colorMode],
  fontWeight: 400,
  fontSize: 4,
  lineHeight: '12px',
})

export const close = {
  cursor: 'pointer',
  position: 'absolute',
  right: '10px',
  top: '15px',
}

export const spot = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  minWidth: '250px',
  m: '5px 10px 5px 0px',
  bg: 'muted',
}

export const title = {
  fontSize: 2,
  letterSpacing: 0,
}

export const mainTitle = colorMode => ({
  fontFamily: theme => theme.fonts.body[colorMode],
  p: '5px 5px 5px 10px',
  bg: 'accentAlt',
  letterSpacing: '1px',
  border: theme => theme.borders.primary[colorMode],
  borderColor: 'muted',
})

export const amount = {
  mt: '135px',
  ml: '9px',
  mb: '9px',
}
