/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'

import { useContext, useEffect, useMemo, useRef } from 'react'
import { connect } from 'react-redux'
import { shortenNumber, getTranslationFromKeys } from '../../common/utilities'
import { setFilters, setExpandedRows, setSelectedInstruments } from '../../actions/workspace'
import { I18nContext } from '../../containers/i18n'
import { subscribeSymbolDynamic, unsubscribeSymbolDynamic } from '../../actions/marketData'
import Price from '../Price'
import { ExternalTable, InternalTable, Expander } from '../NativeTable'
import { createSelector } from 'reselect'
import { hydratedInstrumentSelector, instrumentsGroupsSelector } from '../../selectors/instruments'

function PriceList({
  //Own
  openFilter = false,
  expandedRows = [],
  selectedInstruments = {},
  filters: { selected = false },
  //Props
  tabIndex,
  //State
  instruments,
  instrumentsGroups,
  tick,
  trade,
  //Dispatch
  subscribeSymbolDynamic,
  unsubscribeSymbolDynamic,
  setFilters,
  setExpandedRows,
  setSelectedInstruments,
}) {
  const { t } = useContext(I18nContext)
  const { colorMode, theme } = useThemeUI()
  const subscribedInstruments = useRef([])

  const addSubscribedInstrument = instrumentId => {
    if (!subscribedInstruments.current.includes(instrumentId)) {
      subscribedInstruments.current.push(instrumentId)
      return true
    }
    return false
  }

  const removeSubscribedInstrument = instrumentId => {
    const index = subscribedInstruments.current.indexOf(instrumentId)
    if (index !== -1) {
      subscribedInstruments.current.splice(index, 1)
      return true
    }
    return false
  }

  // Build the default state of component with the actual instrument groups only if expandedRows not contains data
  useEffect(() => {
    if (JSON.stringify(expandedRows) === '{}') {
      const instrumentGroupKeys = Object.keys(instrumentsGroups)
      const initialExpandedRows = instrumentGroupKeys.reduce(
        (acc, value) => ({ ...acc, [value]: false }),
        {}
      )
      const initialSelectedInstruments = instrumentGroupKeys.reduce(
        (acc1, instrumentGroupKey) => ({
          ...acc1,
          [instrumentGroupKey]: instrumentsGroups[instrumentGroupKey].data.reduce(
            (acc2, instrumentKey) => ({ ...acc2, [instrumentKey]: false }),
            {}
          ),
        }),
        {}
      )
      setExpandedRows({ tabIndex: tabIndex, expandedRows: initialExpandedRows })
      setSelectedInstruments({
        tabIndex: tabIndex,
        selectedInstruments: initialSelectedInstruments,
      })
    }
  }, [])

  // // Subscriptions
  // useEffect(() => {
  //   const entries = Object.entries(expandedRows)
  //     .filter(instrumentTypeId => instrumentTypeId[1])
  //     .map(instrumentTypeId => instrumentTypeId[0])
  //   if (selected) {
  //     entries.forEach(instrumentTypeId => subscribeOnUncheck(instrumentTypeId, true))
  //   } else {
  //     entries.forEach(instrumentTypeId => subscribeOnExpanded(instrumentTypeId))
  //   }

  //   // Cleanup function
  //   return () => {
  //     // Unsubscribe all here
  //     entries.forEach(instrumentTypeId => unsubscribeOnMinimize(instrumentTypeId))
  //   }
  // }, [])

  // Handle subscription on mount
  useEffect(() => {
    const entries = Object.entries(expandedRows)
      .filter(instrumentTypeId => instrumentTypeId[1])
      .map(instrumentTypeId => instrumentTypeId[0])
    if (selected) {
      entries.forEach(instrumentTypeId => subscribeOnUncheck(instrumentTypeId, true))
    } else {
      entries.forEach(instrumentTypeId => subscribeOnExpanded(instrumentTypeId))
    }
  }, [])

  function isSelected(instrument, instrumentTypeId) {
    return (
      selectedInstruments[instrumentTypeId] && selectedInstruments[instrumentTypeId][instrument.id]
    )
  }

  function processInstruments(instrumentTypeId, action, onlyChecked = false) {
    function shouldIncludeInstrument(instrumentId) {
      if (!selected) {
        return true
      }

      const isSelectedInstrument = isSelected(instruments[instrumentId], instrumentTypeId)

      if (onlyChecked) {
        return isSelectedInstrument
      } else {
        return !isSelectedInstrument
      }
    }

    if (instrumentsGroups?.[instrumentTypeId]?.data) {
      instrumentsGroups[instrumentTypeId]?.data
        .filter(instrumentId => shouldIncludeInstrument(instrumentId))
        .forEach(instrumentId => {
          action(instrumentId)
        })
    }
  }

  function subscribeOnExpanded(instrumentTypeId = '') {
    processInstruments(instrumentTypeId, subscribe)
  }

  function subscribe(instrumentId) {
    const instrument = instruments[instrumentId]
    if (!instrument) {
      console.error('Instrument %s not found', instrumentId)
      return
    }
    const instrumentHasTicks = instrument?.allowedSubscriptions?.includes('TICK') || false
    const instrumentHasTrades = instrument?.allowedSubscriptions?.includes('TRADE') || false
    const wasNotSubscribed =
      (instrumentHasTicks || instrumentHasTrades) && addSubscribedInstrument(instrument.id)

    //Only ask for subscriptions if we were not already subscribed
    instrumentHasTrades &&
      wasNotSubscribed &&
      subscribeSymbolDynamic({ instrument, subscriptionType: 'trade' })
    instrumentHasTicks &&
      wasNotSubscribed &&
      subscribeSymbolDynamic({ instrument, subscriptionType: 'tick' })
  }

  function unsubscribe(instrumentId) {
    const instrument = instruments[instrumentId]

    const instrumentHasTicks = instrument?.allowedSubscriptions?.includes('TICK') || false
    const instrumentHasTrades = instrument?.allowedSubscriptions?.includes('TRADE') || false
    const wasSubscribed =
      (instrumentHasTicks || instrumentHasTrades) && removeSubscribedInstrument(instrument.id)

    //Only remove the subscription if we were subscribed
    instrumentHasTrades &&
      wasSubscribed &&
      unsubscribeSymbolDynamic({ instrument, subscriptionType: 'trade' })
    instrumentHasTicks &&
      wasSubscribed &&
      unsubscribeSymbolDynamic({ instrument, subscriptionType: 'tick' })
  }

  function unsubscribeOnMinimize(instrumentTypeId = '') {
    processInstruments(instrumentTypeId, instrumentId => {
      unsubscribe(instrumentId)
    })
  }

  function subscribeOnUncheck(instrumentTypeId = '', onlyChecked = false) {
    processInstruments(instrumentTypeId, subscribe, onlyChecked)
  }

  function unsubscribeOnCheck(instrumentTypeId = '') {
    processInstruments(
      instrumentTypeId,
      instrumentId => {
        unsubscribe(instrumentId)
      },
      false
    )
  }

  // Expanded instruments by marketId, securityType and securitySubType
  const updateExpanded = instrumentTypeId => {
    const newExpanded = { ...expandedRows }
    newExpanded[instrumentTypeId] = !newExpanded[instrumentTypeId]
    setExpandedRows({ tabIndex: tabIndex, expandedRows: newExpanded })
    // Handle subscriptions on expanded elements
    if (newExpanded[instrumentTypeId]) {
      subscribeOnExpanded(instrumentTypeId)
    } else {
      unsubscribeOnMinimize(instrumentTypeId)
    }
  }

  // Selected instruments by marketId, securityType and securitySubType
  const updateSelected = (instrumentTypeId, instrumentId, checked) => {
    const newSelected = { ...selectedInstruments }

    newSelected[instrumentTypeId] = {
      ...newSelected[instrumentTypeId],
      [instrumentId]: checked,
    }

    setSelectedInstruments({ tabIndex: tabIndex, selectedInstruments: newSelected })
    // Handle unsubscription when selected filter is on and instrument is unseleted
    if (selected && !checked) {
      unsubscribe(instrumentId)
    }
  }

  function updateFilters(checked) {
    setFilters({ tabIndex: tabIndex, filters: { selected: checked } })
  }

  // Handle subscriptions on filter change
  useEffect(() => {
    const instrumentTypeIdList = Object.entries(expandedRows)
      .filter(instrumentTypeId => instrumentTypeId[1])
      .map(instrumentTypeId => instrumentTypeId[0])
    if (selected) {
      instrumentTypeIdList.forEach(instrumentTypeId => unsubscribeOnCheck(instrumentTypeId))
    } else {
      instrumentTypeIdList.forEach(instrumentTypeId => subscribeOnUncheck(instrumentTypeId))
    }
  }, [selected])

  // Build array of groups
  const instrumentTypesList = useMemo(() => Object.keys(instrumentsGroups), [instruments])
  // Build array with the translation text for each group
  const instrumentTypesNameList = useMemo(
    () => instrumentTypesList.map(i => getTranslationFromKeys(i.split(':'), t)),
    [instruments]
  )

  function getSubComponentData(instrumentType) {
    const safePrice = (currency, key, decimals) =>
      currency?.[key] ? Number(currency[key]).toFixed(decimals) : ''
    const askPriceFixed = (tick, instrument) =>
      safePrice(tick, 'askPrice', instrument?.priceDecimals)
    const bidPriceFixed = (tick, instrument) =>
      safePrice(tick, 'bidPrice', instrument?.priceDecimals)

    const build = i => {
      if (selected) {
        if (!selectedInstruments?.[instrumentType]?.[i]) {
          return null
        }
      }
      const accountId = instruments[i].marketNeedsAccount
        ? instruments[i].selectedAccountId || ''
        : ''
      const currentTick = tick[instruments[i].exchangeId]?.[instruments[i].symbol]?.[accountId]
      const currentTrade = trade[instruments[i].exchangeId]?.[instruments[i].symbol]?.[accountId]

      return {
        ...instruments[i],
        askPrice: askPriceFixed(currentTick, instruments[i]),
        bidPrice: bidPriceFixed(currentTick, instruments[i]),
        askVolume: currentTick?.askVolume,
        bidVolume: currentTick?.bidVolume,
        lastTrade: expandedRows[instrumentType]
          ? safePrice(currentTrade, 'price', instruments[i].priceDecimals)
          : '',
        key: instruments[i].id,
      }
    }
    return instrumentsGroups[instrumentType].data.map(i => build(i)).filter(data => data !== null)
  }

  return (
    <ExternalTable theme={theme} colorMode={colorMode}>
      {/* Header */}
      <thead>
        {openFilter ? (
          <tr className="filterRow">
            <th colSpan={7} className="filter">
              <label className="filterCheckBoxLabel">
                <input
                  type="checkbox"
                  id={'selected'}
                  className="filterCheckBox"
                  onChange={e => updateFilters(e.target.checked)}
                  checked={selected}
                />
                <div className="customCheckBox"></div>
                {t('selected')}
              </label>
            </th>
          </tr>
        ) : null}
        <tr>
          <th className="checkBoxHeader"></th>
          <th></th>
          <th>{t('bidVolume')}</th>
          <th>{t('bidPrice')}</th>
          <th>{t('askPrice')}</th>
          <th>{t('askVolume')}</th>
          <th>{t('lastTrade')}</th>
        </tr>
      </thead>
      {/* Body */}
      <tbody>
        {instrumentTypesList.map((instrumentTypeId, index) => {
          return (
            <tr key={instrumentTypeId}>
              <td colSpan={7}>
                <Expander
                  expanded={expandedRows[instrumentTypeId]}
                  onClick={() => updateExpanded(instrumentTypeId)}
                  text={instrumentTypesNameList[index]}
                  theme={theme}
                />
                {/* if instrument group is expanded render instruments */}
                {expandedRows[instrumentTypeId] ? (
                  <InternalTable theme={theme}>
                    <tbody>
                      {getSubComponentData(instrumentTypeId).map(sc => (
                        <tr key={sc.key}>
                          <td className="checkBoxCell">
                            <label>
                              <input
                                type="checkbox"
                                onChange={e =>
                                  updateSelected(instrumentTypeId, sc.id, e.target.checked)
                                }
                                checked={selectedInstruments[instrumentTypeId]?.[sc.id] || false}
                              />
                              <div className="customCheckBox" />
                            </label>
                          </td>
                          <td>{sc.name}</td>
                          <td>{sc.bidVolume ? shortenNumber(sc.bidVolume) : ''}</td>
                          <td>{<Price value={sc.bidPrice} instrument={sc} />}</td>
                          <td>{<Price value={sc.askPrice} instrument={sc} />}</td>
                          <td>{sc.askVolume ? shortenNumber(sc.askVolume) : ''}</td>
                          <td>{sc.lastTrade}</td>
                        </tr>
                      ))}
                    </tbody>
                  </InternalTable>
                ) : null}
              </td>
            </tr>
          )
        })}
      </tbody>
    </ExternalTable>
  )
}

const tickSelector = state => state.marketData.tick
const tradeSelector = state => state.marketData.trade
const openFilterSelector = (state, props) =>
  state.workspace.workspaces[state.workspace.currentWorkspace]?.tabs[props.tabIndex]?.openFilter
const expandedRowsSelector = (state, props) =>
  state.workspace.workspaces[state.workspace.currentWorkspace]?.tabs[props.tabIndex]?.expandedRows
const selectedInstrumentsSelector = (state, props) =>
  state.workspace.workspaces[state.workspace.currentWorkspace]?.tabs[props.tabIndex]
    ?.selectedInstruments
const filtersSelector = (state, props) =>
  state.workspace.workspaces[state.workspace.currentWorkspace]?.tabs[props.tabIndex]?.filters

const mapStateToProps = (state, ownProps) =>
  createSelector(
    hydratedInstrumentSelector,
    instrumentsGroupsSelector,
    tickSelector,
    tradeSelector,
    openFilterSelector,
    expandedRowsSelector,
    selectedInstrumentsSelector,
    filtersSelector,
    (
      instruments,
      instrumentsGroups,
      tick,
      trade,
      openFilter,
      expandedRows,
      selectedInstruments,
      filters
    ) => ({
      openFilter,
      expandedRows,
      selectedInstruments,
      filters,
      instruments,
      instrumentsGroups,
      tick,
      trade,
    })
  )(state, ownProps)

const mapDispatchToProps = {
  subscribeSymbolDynamic,
  unsubscribeSymbolDynamic,
  setFilters,
  setExpandedRows,
  setSelectedInstruments,
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceList)
