/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'

import { useEffect, useContext, useMemo } from 'react'
import { connect } from 'react-redux'

import PriceCell, { sides } from './priceCell'
import QuantityCell from './quantityCell'
import {
  updateQuoteInstrumentData,
  setFilters,
  setExpandedRows,
  setSelectedInstruments,
} from '../../actions/workspace'
import { I18nContext } from '../../containers/i18n'
import { subscribeSymbolDynamic, unsubscribeSymbolDynamic } from '../../actions/marketData'
import { showErrorDialog } from '../../actions/workspace'
import { makeOrderWithConfirm, makeOrder } from '../../actions/orders'
import { getTranslationFromKeys } from '../../common/utilities'
import { PermissionsContext } from '../../containers/PermissionsProvider'
import PermissionsVisible from '../../containers/PermissionsProvider/PermissionsVisible'
import TradingCapacity from './tradingCapacity'
import { ExternalTable, InternalTable, Expander } from '../NativeTable'
import { createSelector } from 'reselect'
import { hydratedInstrumentSelector, instrumentsGroupsSelector } from '../../selectors/instruments'
import { accountsSelector } from '../../selectors/accounts'
import { sendOrder } from '../../common/utilities/orderUtils'

function QuoteList({
  //Own
  openFilter = false,
  expandedRows = [],
  selectedInstruments = {},
  quoteInstrumentData = {},
  filters: { selected = false },
  orkData = {},
  //Props
  tabIndex,
  keycloak,
  //State
  instruments,
  instrumentsGroups,
  isConfirmDialogRequired,
  currentAccount,
  //Dispatch
  updateQuoteInstrumentData,
  makeOrderWithConfirm,
  makeOrder,
  showErrorDialog,
  subscribeSymbolDynamic,
  unsubscribeSymbolDynamic,
  setFilters,
  setExpandedRows,
  setSelectedInstruments,
}) {
  const { t } = useContext(I18nContext)
  const { hasAnyRole } = useContext(PermissionsContext)
  const { colorMode, theme } = useThemeUI()
  const subscribedInstruments = []

  const addSubscribedInstrument = instrumentId => {
    if (!subscribedInstruments.includes(instrumentId)) {
      subscribedInstruments.push(instrumentId)
      return true
    }
    return false
  }

  const removeSubscribedInstrument = instrumentId => {
    const index = subscribedInstruments.indexOf(instrumentId)
    if (index !== -1) {
      subscribedInstruments.splice(index, 1)
      return true
    }
    return false
  }

  // Build the default state of component with the actual instrument groups only if expandedRows not contains data
  useEffect(() => {
    if (JSON.stringify(expandedRows) === '{}') {
      const instrumentGroupKeys = Object.keys(instrumentsGroups)
      const initialExpandedRows = instrumentGroupKeys.reduce(
        (acc, value) => ({ ...acc, [value]: false }),
        {}
      )
      const initialSelectedInstruments = instrumentGroupKeys.reduce(
        (acc1, instrumentGroupKey) => ({
          ...acc1,
          [instrumentGroupKey]: instrumentsGroups[instrumentGroupKey].data.reduce(
            (acc2, instrumentKey) => ({ ...acc2, [instrumentKey]: false }),
            {}
          ),
        }),
        {}
      )
      setExpandedRows({ tabIndex: tabIndex, expandedRows: initialExpandedRows })
      setSelectedInstruments({
        tabIndex: tabIndex,
        selectedInstruments: initialSelectedInstruments,
      })
    }
  }, [])

  // Subscriptions

  // Handle subscription on mount
  useEffect(() => {
    const entries = Object.entries(expandedRows)
      .filter(instrumentTypeId => instrumentTypeId[1])
      .map(instrumentTypeId => instrumentTypeId[0])
    if (selected) {
      entries.forEach(instrumentTypeId => subscribeOnUncheck(instrumentTypeId, true))
    } else {
      entries.forEach(instrumentTypeId => subscribeOnExpanded(instrumentTypeId))
    }
  }, [])

  function isSelected(instrument, instrumentTypeId) {
    return (
      selectedInstruments[instrumentTypeId] && selectedInstruments[instrumentTypeId][instrument.id]
    )
  }

  function processInstruments(instrumentTypeId, action, onlyChecked = false) {
    function shouldIncludeInstrument(instrumentId) {
      if (!selected) {
        return true
      }

      const isSelectedInstrument = isSelected(instruments[instrumentId], instrumentTypeId)

      if (onlyChecked) {
        return isSelectedInstrument
      } else {
        return !isSelectedInstrument
      }
    }

    if (instrumentsGroups?.[instrumentTypeId]?.data) {
      instrumentsGroups[instrumentTypeId]?.data
        .filter(instrumentId => shouldIncludeInstrument(instrumentId))
        .forEach(instrumentId => {
          action(instrumentId)
        })
    }
  }

  function subscribeOnExpanded(instrumentTypeId = '') {
    processInstruments(instrumentTypeId, subscribe)
  }

  function subscribe(instrumentId) {
    const instrument = instruments[instrumentId]
    if (!instrument) {
      console.error('Instrument %s not found', instrumentId)
      return
    }
    const instrumentHasTicks = instrument?.allowedSubscriptions?.includes('TICK') || false
    const wasNotSubscribed = instrumentHasTicks && addSubscribedInstrument(instrument.id)

    //Only ask for subscriptions if we were not already subscribed
    instrumentHasTicks &&
      wasNotSubscribed &&
      subscribeSymbolDynamic({ instrument, subscriptionType: 'tick' })
  }

  function unsubscribe(instrumentId) {
    const instrument = instruments[instrumentId]

    const instrumentHasTicks = instrument?.allowedSubscriptions?.includes('TICK') || false
    const wasSubscribed = instrumentHasTicks && removeSubscribedInstrument(instrument.id)

    //Only remove the subscription if we were subscribed
    instrumentHasTicks &&
      wasSubscribed &&
      unsubscribeSymbolDynamic({ instrument, subscriptionType: 'tick' })
  }

  function unsubscribeOnMinimize(instrumentTypeId = '') {
    processInstruments(instrumentTypeId, instrumentId => {
      unsubscribe(instrumentId)
    })
  }

  function subscribeOnUncheck(instrumentTypeId = '', onlyChecked = false) {
    processInstruments(instrumentTypeId, subscribe, onlyChecked)
  }

  function unsubscribeOnCheck(instrumentTypeId = '') {
    processInstruments(
      instrumentTypeId,
      instrumentId => {
        unsubscribe(instrumentId)
      },
      false
    )
  }

  // Expanded instruments by marketId, securityType and securitySubType
  const updateExpanded = instrumentTypeId => {
    const newExpanded = { ...expandedRows }
    newExpanded[instrumentTypeId] = !newExpanded[instrumentTypeId]
    setExpandedRows({ tabIndex: tabIndex, expandedRows: newExpanded })
    // Handle subscriptions on expanded elements
    if (newExpanded[instrumentTypeId]) {
      subscribeOnExpanded(instrumentTypeId)
    } else {
      unsubscribeOnMinimize(instrumentTypeId)
    }
  }

  // Selected instruments by marketId, securityType and securitySubType
  const updateSelected = (instrumentTypeId, instrumentId, checked) => {
    const newSelected = { ...selectedInstruments }

    newSelected[instrumentTypeId] = {
      ...newSelected[instrumentTypeId],
      [instrumentId]: checked,
    }

    setSelectedInstruments({ tabIndex: tabIndex, selectedInstruments: newSelected })
    // Handle unsubscription when selected filter is on and instrument is unseleted
    if (selected && !checked) {
      unsubscribe(instrumentId)
    }
  }

  function updateFilters(checked) {
    setFilters({ tabIndex: tabIndex, filters: { selected: checked } })
  }

  // Handle subscriptions on filter change
  useEffect(() => {
    const instrumentTypeIdList = Object.entries(expandedRows)
      .filter(instrumentTypeId => instrumentTypeId[1])
      .map(instrumentTypeId => instrumentTypeId[0])
    if (selected) {
      instrumentTypeIdList.forEach(instrumentTypeId => unsubscribeOnCheck(instrumentTypeId))
    } else {
      instrumentTypeIdList.forEach(instrumentTypeId => subscribeOnUncheck(instrumentTypeId))
    }
  }, [selected])

  // Build array of groups
  const instrumentTypesList = useMemo(() => Object.keys(instrumentsGroups), [instruments])
  // Build array with the translation text for each group
  const instrumentTypesNameList = useMemo(
    () => instrumentTypesList.map(i => getTranslationFromKeys(i.split(':'), t)),
    [instruments]
  )

  function getSubComponentData(instrumentType) {
    return instrumentsGroups[instrumentType].data
      .map(i => {
        if (
          selected &&
          selectedInstruments[instrumentType] &&
          !selectedInstruments[instrumentType][i]
        ) {
          return null
        }
        return {
          ...instruments[i],
          tabIndex: tabIndex,
          key: instruments[i].id,
        }
      })
      .filter(data => data !== null)
  }

  const handlePriceClick = (instrument, value, side) => {
    const quantity = quoteInstrumentData[instrument?.id]?.quantity || 1
    make({
      side,
      priceData: value,
      quantity: quantity,
      orderType: 'MARKET',
      instrument,
      clientId: orkData.clientId,
      decisorId: orkData.decisorId,
      executorId: orkData.executorId,
      tradingCapacity: orkData.tradingCapacity,
      title: t('confirmOrder'),
    })
  }

  const make = payload => {
    sendOrder({
      payload,
      currentAccount,
      instrument: payload.instrument,
      hasAnyRole,
      t,
      isConfirmDialogRequired,
      makeOrderWithConfirm,
      makeOrder,
      keycloak,
      handleModal,
    })
  }

  const handleModal = message =>
    showErrorDialog({
      title: t('error').toUpperCase(),
      type: 'ERROR',
      message: message,
    })

  function onQuantityChange(id = '', value = 1) {
    const quoteList = {
      ...quoteInstrumentData,
      [id]: { quantity: value },
    }
    updateQuoteInstrumentData({ quoteInstrumentData: quoteList, tabIndex: tabIndex })
  }

  return (
    <ExternalTable theme={theme} colorMode={colorMode}>
      <PermissionsVisible anyRoles={['member_trader']}>
        <tfoot>
          <tr>
            <th colSpan={5}>
              <div
                sx={{
                  height: '29px',
                  padding: '2px',
                  boxShadow: 'none',
                  alignItems: 'center',
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'center',
                  color: 'text',
                  backgroundColor: 'aux',
                }}
              >
                <TradingCapacity data={orkData} tabIndex={tabIndex} />
              </div>
            </th>
          </tr>
        </tfoot>
      </PermissionsVisible>
      {/* Header */}
      <thead>
        {openFilter ? (
          <tr className="filterRow">
            <th colSpan={5} className="filter">
              <label className="filterCheckBoxLabel">
                <input
                  type="checkbox"
                  id={'selected'}
                  className="filterCheckBox"
                  onChange={e => updateFilters(e.target.checked)}
                  checked={selected}
                />
                <div className="customCheckBox"></div>
                {t('selected')}
              </label>
            </th>
          </tr>
        ) : null}
        <tr>
          <th className="checkBoxHeader"></th>
          <th></th>
          <th>{t('bidPrice')}</th>
          <th>{t('askPrice')}</th>
          <th className="fixed-size">{t('quantity')}</th>
        </tr>
      </thead>
      {/* Body */}
      <tbody>
        {instrumentTypesList.map((instrumentTypeId, index) => {
          return (
            <tr key={instrumentTypeId}>
              <td colSpan={5}>
                <Expander
                  expanded={expandedRows[instrumentTypeId]}
                  onClick={() => updateExpanded(instrumentTypeId)}
                  text={instrumentTypesNameList[index]}
                  theme={theme}
                />
                {/* if instrument group is expanded render instruments */}
                {expandedRows[instrumentTypeId] ? (
                  <InternalTable theme={theme}>
                    <tbody>
                      {getSubComponentData(instrumentTypeId).map(sc => (
                        <tr key={sc.key}>
                          <td className="checkBoxCell">
                            <label>
                              <input
                                type="checkbox"
                                onChange={e =>
                                  updateSelected(instrumentTypeId, sc.id, e.target.checked)
                                }
                                checked={selectedInstruments[instrumentTypeId]?.[sc.id] || false}
                              />
                              <div className="customCheckBox" />
                            </label>
                          </td>
                          <td>{sc.name}</td>
                          <td>
                            <PriceCell
                              instrument={sc}
                              side={sides.SELL}
                              onPriceClick={(price, side) => handlePriceClick(sc, price, side)}
                            />
                          </td>
                          <td>
                            <PriceCell
                              instrument={sc}
                              side={sides.BUY}
                              onPriceClick={(price, side) => handlePriceClick(sc, price, side)}
                            />
                          </td>
                          <td className="fixed-size">
                            <QuantityCell
                              id={sc.id}
                              onQuantityChange={onQuantityChange}
                              value={quoteInstrumentData[sc.id]?.quantity || 1}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </InternalTable>
                ) : null}
              </td>
            </tr>
          )
        })}
      </tbody>
    </ExternalTable>
  )
}

const openFilterSelector = (state, props) =>
  state.workspace.workspaces[state.workspace.currentWorkspace]?.tabs[props.tabIndex]?.openFilter
const expandedRowsSelector = (state, props) =>
  state.workspace.workspaces[state.workspace.currentWorkspace]?.tabs[props.tabIndex]?.expandedRows
const selectedInstrumentsSelector = (state, props) =>
  state.workspace.workspaces[state.workspace.currentWorkspace]?.tabs[props.tabIndex]
    ?.selectedInstruments
const quoteInstrumentDataSelector = (state, props) =>
  state.workspace.workspaces[state.workspace.currentWorkspace]?.tabs[props.tabIndex]
    ?.quoteInstrumentData
const filtersSelector = (state, props) =>
  state.workspace.workspaces[state.workspace.currentWorkspace]?.tabs[props.tabIndex]?.filters
const orkDataSelector = (state, props) =>
  state.workspace.workspaces[state.workspace.currentWorkspace]?.tabs[props.tabIndex]?.orkData
const confirmDialogSelector = (state, props) => state.workspace.isConfirmDialogRequired

const mapStateToProps = (state, ownProps) =>
  createSelector(
    hydratedInstrumentSelector,
    instrumentsGroupsSelector,
    openFilterSelector,
    expandedRowsSelector,
    selectedInstrumentsSelector,
    quoteInstrumentDataSelector,
    filtersSelector,
    orkDataSelector,
    confirmDialogSelector,
    accountsSelector,
    (
      instruments,
      instrumentsGroups,
      openFilter,
      expandedRows,
      selectedInstruments,
      quoteInstrumentData,
      filters,
      orkData,
      isConfirmDialogRequired,
      currentAccount
    ) => ({
      openFilter,
      expandedRows,
      selectedInstruments,
      quoteInstrumentData,
      filters,
      instruments,
      instrumentsGroups,
      orkData,
      isConfirmDialogRequired,
      currentAccount
    })
  )(state, ownProps)

const mapDispatchToProps = {
  updateQuoteInstrumentData,
  makeOrderWithConfirm,
  makeOrder,
  showErrorDialog,
  subscribeSymbolDynamic,
  unsubscribeSymbolDynamic,
  setFilters,
  setExpandedRows,
  setSelectedInstruments,
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteList)
