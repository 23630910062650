/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as style from './style'
import React, { useState, useContext } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import styled from '@emotion/styled'

import CustomButton from '../CustomButton'
import { I18nContext } from '../../containers/i18n'
import {
  Dialog,
  DialogTitle,
  DialogSubtitle,
  DialogBanner,
  DialogContent,
  DialogActions as MDialogActions,
} from '../Dialog'
import FormattedNumberText from '../FormattedNumber'

const DialogActions = styled(MDialogActions)`
  justify-content: space-around;
  padding-left: 0px;
  width: 650px;
`

const Message = ({ orderInfo }) => {
  const { t } = useContext(I18nContext)

  return (
    <span>
      {t(orderInfo.side === 'BUY' ? 'BUYACTION' : 'SELLACTION')}&nbsp;
      <FormattedNumberText value={orderInfo.quantity} />
      &nbsp;
      {orderInfo.symbol || ''}&nbsp;
      {orderInfo.orderType !== 'MARKET' ? (
        <FormattedNumberText value={orderInfo.price} prefix="@ " suffix=" " />
      ) : (
        ''
      )}
      {t(orderInfo.orderType)}&nbsp;
      {orderInfo.orderType !== 'MARKET' ? t(orderInfo.tif) : ''}
    </span>
  )
}

const ConfirmDialog = ({
  action,
  side,
  dontShowAgain,
  message,
  onCancel,
  open,
  order, // Esto no es la orden, es un true/false para indicar si se está creando/cancelando
  orderInfo,
  selectedAccount,
}) => {
  const { t } = useContext(I18nContext)
  const [checked, setChecked] = useState(false)
  const updateCheckbox = () => setChecked(!checked)

  return (
    <Dialog onClose={onCancel} open={open}>
      <DialogTitle id="alert-dialog-title" disableTypography>
        <label sx={style.labelTitle}>{order ? t('cancelOrder') : t('placeOrder')}</label>
      </DialogTitle>
      <DialogContent>
        <DialogBanner sx={style.message} buy={side.toLowerCase() === 'buy'}>
          {message || <Message orderInfo={orderInfo} />}
        </DialogBanner>
        <DialogSubtitle>
          {'in'}{' '}
          {(selectedAccount?.name && selectedAccount?.fixAccount && `${selectedAccount.name} (${selectedAccount.fixAccount})`)
            || (selectedAccount?.accountName && selectedAccount?.fixAccount && `${selectedAccount.accountName} (${selectedAccount.fixAccount})`)
            || (selectedAccount?.name && `${selectedAccount.name}`)
            || (selectedAccount?.accountName && `${selectedAccount.accountName}`)
            || selectedAccount.account}
        </DialogSubtitle>
      </DialogContent>
      <DialogActions>
        {dontShowAgain && (
          <div onClick={updateCheckbox} sx={style.checkText}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              onClick={updateCheckbox}
              checked={checked}
              sx={style.checkBox}
            />
            <p sx={style.text}>{t('dontShowAgain')}</p>
          </div>
        )}

        <CustomButton
          type={side.toLowerCase()}
          size="large"
          noMargin
          width="29%"
          onClick={() => {
            action()
            checked && dontShowAgain()
            onCancel()
          }}
        >
          {order ? t('cancelOrder') : t('placeOrder')}
        </CustomButton>
        <CustomButton
          type="cancel"
          size="large"
          noMargin
          width="29%"
          onClick={() => {
            setChecked(false)
            onCancel()
          }}
        >
          {order ? t('noCancel') : t('noPlace')}
        </CustomButton>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
