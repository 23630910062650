export const styles = isMemberTrader => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    minWidth: '250px',
    height: isMemberTrader ? '254px' : '225px',
    margin: '5px 5px 5px 0px',
    backgroundColor: 'muted',
    color: 'newSpot.color',
    '&:hover': {
      color: 'newSpot.colorHover',
    },
  }
}

export const setPos = ([x, y]) => ({
  position: 'fixed',
  zIndex: 999,
  left: `${x - 80}px`,
  top: y > window.innerHeight - 350 ? `${y - 350}px` : `${y}px`,
})
