import React from 'react'
import classNames from 'classnames'
import IconButton from '@material-ui/core/IconButton'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { ErrorIcon, CheckCircleIcon, CloseIcon, withStyles } from '@material-ui/core/styles'

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon,
}

const styles = theme => ({
  success: {
    backgroundColor: theme.palette.success.dark,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
})

function MySnackbarContent({ classes, className, message, onClose, variant, ...other }) {
  const Icon = variantIcon[variant]

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" color="inherit" className={classes.close} onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  )
}

const CustomSnackbar = withStyles(styles)(MySnackbarContent)

export default CustomSnackbar
