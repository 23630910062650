/** @jsx jsx */
import { jsx } from 'theme-ui'
import { connect } from 'react-redux'
import { useContext } from 'react'
import * as style from './style'
import { I18nContext } from '../../containers/i18n'
import { Typography } from '@material-ui/core'
import { CustomSelect, getOptions } from '../../common/utilities'
import { updateTradePanel } from '../../actions/workspace'
import Ork from './ork'

const tradingCapacityTypes = ['DEAL', 'MATCH', 'ANY_OTHER']

const TradingCapacity = ({ value, tabIndex, updateTradePanel }) => {
  const { t } = useContext(I18nContext)

  return (
    <div
      sx={{
        pl: '10px',
        pr: '10px',
        my: '3px',
        // pb: '4px',
        py: '1px',
        //borderTop: 'solid 1px',
        //borderBottom: 'solid 1px',
        //borderColor: 'primary',
      }}
    >
      <div sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
        <Typography sx={{ color: 'text', fontSize: '13px' }}>{t('tradingCapacity')}</Typography>
        <CustomSelect
          onChange={e => updateTradePanel({ tradingCapacity: e.target.value, tabIndex })}
          value={value?.tradingCapacity?.toUpperCase()}
          sx={{ ...style.input, ...style.inputBase, ...style.tradingCapacitiy }}
        >
          {getOptions(t, tradingCapacityTypes)}
        </CustomSelect>
      </div>
      <div
        sx={{
          marginTop: '2px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <Ork type="clientId" tabIndex={tabIndex} value={value.clientId}></Ork>
        <Ork type="decisorId" tabIndex={tabIndex} value={value.decisorId}></Ork>
        <Ork type="executorId" tabIndex={tabIndex} value={value.executorId}></Ork>
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  updateTradePanel,
}

export default connect(null, mapDispatchToProps)(TradingCapacity)
