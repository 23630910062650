import { combineReducers } from 'redux'

//import fetch from './fetch'
import marketData from './marketData'
import workspace from './workspace'
import socket from './socket'
import order from './order'
import positions from './positions'
import notifications from './notifications'
import fetchReducer from './fetchReducer'
import csv from './csv'

const instruments = fetchReducer(
  'INSTRUMENTS',
  ['exchangeId', 'symbol'],
  ['marketId', 'securityType', 'securitySubType']
)
const accounts = fetchReducer('ACCOUNTS', ['id'], ['exchangeId'])
const enablements = fetchReducer('ENABLEMENTS', ['exchangeId'])
const markets = fetchReducer('MARKETS', ['exchangeId'])

export default combineReducers({
  //fetch,
  marketData,
  workspace,
  socket,
  instruments,
  order,
  positions,
  notifications,
  markets,
  accounts,
  enablements,
  csv,
})
