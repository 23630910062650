/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useContext, useMemo } from 'react'
import { connect } from 'react-redux'
import 'react-table/react-table.css'

import { PanelTable } from '../Table'
import { I18nContext } from '../../containers/i18n'
import { columnsHead } from './columnsHead'

const AccountGroup = ({
  tabIndex,
  accountGroups,
  currentAccount,
  markets,
  marketIds,
  accounts,
}) => {
  const { t } = useContext(I18nContext)

  const memoizedRows = useMemo(() => {
    if (currentAccount) {
      /** TODO: Change to accountGroup[currentAccount.id]?.accounts when the entry in
       * workspace.accountGroups is changed to JSON
       */
      const accountGroupAccounts = accountGroups.reduce(
        (prev, accountGroup) => ({
          ...prev,
          [accountGroup.id]: { ...accountGroup },
        }),
        {}
      )[currentAccount.id]?.accounts
      if (accountGroupAccounts) {
        return marketIds.map(accountKey => ({
          market: `${markets[accountKey]?.marketName} - ${markets[accountKey]?.segmentName}`,
          client:
            accounts[accountGroupAccounts[accountKey]?.value] &&
            (accounts[accountGroupAccounts[accountKey].value]?.client?.shortName || t('member')),
          account: accounts[accountGroupAccounts[accountKey]?.value]?.name || t('none'),
          fixId: accounts[accountGroupAccounts[accountKey]?.value]?.fixAccount,
          accountId: accountGroupAccounts[accountKey]?.value,
        }))
      } else {
        const acc = accounts[currentAccount.id]
        return acc
          ? [
              {
                market: `${markets[acc.exchangeId]?.marketName} - ${
                  markets[acc.exchangeId]?.segmentName
                }`,
                client: (acc.client && acc.client.shortName) || t('member'),
                account: acc.name || t('none'),
                fixId: acc.fixAccount,
                accountId: acc.id,
              },
            ]
          : []
      }
    } else {
      return []
    }
  }, [currentAccount?.id, accountGroups, markets, accounts, t])

  return (
    <PanelTable
      rows={memoizedRows}
      getColumns={() => columnsHead(t)}
      tabIndex={tabIndex}
      minRows={memoizedRows.length === 0 ? 3 : 1}
    />
  )
}

const mapStateToProps = ({
  workspace: { currentAccount, accountGroups },
  markets: { data: markets, dataIds: marketIds },
  accounts: { data: accounts },
}) => ({
  accountGroups,
  currentAccount,
  markets,
  marketIds,
  accounts,
})

export default connect(mapStateToProps, null)(AccountGroup)
