export default {
  initialColorMode: 'dark',
  useCustomProperties: true,
  fonts: {
    tabs: {
      dark: "Oswald, 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
      bm: 'Gloriola, Arial, sans-serif',
      igf: 'Gloriola, Arial, sans-serif',
    },
    body: {
      dark: "Heebo, 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
      bm: 'Gloriola, Arial, sans-serif',
      igf: 'Gloriola, Arial, sans-serif',
    },
    priceButtons: {
      dark: "Heebo, 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
      bm: 'Arial, sans-serif',
      igf: 'Arial, sans-serif',
    },
  },
  fontSizes: [12, 13, 14, 16, 18, 20, 22, 30, 63],
  heights: {
    iconButton: {
      dark: '31px',
      bm: '28px',
      igf: '28px',
    },
  },
  positions: {
    tableFilter: {
      dark: 29,
      bm: 25,
      igf: 25,
    },
  },
  buttons: {
    primary: {
      ml: '8px',
      color: '#fff',
      bg: 'buttons.primary',
      '&:hover': {
        bg: 'secondary',
      },
    },
    cancel: {
      ml: '8px',
      color: '#fff',
      bg: 'buttons.cancelBtn',
      '&:hover': {
        bg: 'secondary',
        color: 'hoverAlt',
      },
    },
    save: {
      ml: '8px',
      color: 'text',
      bg: 'green',
      '&:hover': {
        bg: 'greenHover',
      },
    },
    disabled: {
      mr: '8px',
      color: 'grey',
      bg: 'primary',
      '&:hover': {
        bg: 'primary',
      },
    },
    buy: {
      mr: '8px',
      color: 'white',
      bg: 'green',
      '&:hover': {
        bg: 'greenHover',
      },
    },
    sell: {
      ml: '8px',
      color: 'white',
      bg: 'red',
      '&:hover': {
        bg: '#b2102f',
      },
    },
    cellbuy: {
      mr: '8px',
      color: 'text',
      bg: 'topBarBack',
      '&:hover': {
        bg: 'green',
        color: '#fff',
      },
    },
    cellsell: {
      ml: '8px',
      color: 'text',
      bg: 'topBarBack',
      '&:hover': {
        bg: 'red',
        color: '#fff',
      },
    },
    navButton: {
      color: 'white',
      bg: 'buttons.navButton.bg',
      '&:hover': {
        bg: 'buttons.navButton.bgHover',
      },
      '&:focus': {
        border: '1px solid',
        borderColor: 'buttons.navButton.borderFocus',
      },
    },
  },
  borders: {
    primary: {
      dark: 'none',
      bm: '1px solid',
      igf: '1px solid',
    },
    secondary: {
      dark: '',
      bm: '1px solid',
      igf: '1px solid',
    },
    aux: {
      dark: '1px solid',
      bm: '',
      igf: '',
    },
    alt: {
      dark: '1px solid',
      bm: '1px solid',
      igf: '1px solid',
    },
  },
  icon: {
    dark: '#1ca2dc',
    bm: '#19a496',
    igf: '#19a496',
  },
  backTable: {
    dark: '#121a27',
    bm: '#a0b8b1',
    igf: '#a0b8b1',
  },
  colors: {
    topBarBack: '#121a27',
    topBarElements: '#fff',
    topBarSecond: '#fff',

    drawerHide: '#121a27',
    drawerOpen: '#1C2C4B',
    drawerElements: '#fff',
    drawerElementsDeactivate: '#283E60',
    drawerHover: '#496d90',

    background: '#121a27',

    text: '#fff',
    primary: '#283e60',
    secondary: '#496d90',
    aux: '#23334a',
    disabled: '#162439',
    accent: '#1ca2dc',
    subtitle: '#889098',
    muted: '#1c2c4b',
    green: '#45ad4d',
    greenHover: '#388e3c',
    greenValues: '#008000',
    redValues: '#ff0000',
    red: '#df2230',
    orange: '#ed6f2d',
    odd: '#1c2c4b',
    even: '#283e60',
    gray: '#3799a9',
    noEdit: '#4E5B6D',
    adornment: 'rgba(255, 255, 255, 0.2)',

    items: '#283e60',
    selectedCell: 'white',
    checkbox: 'white',
    checkboxModal: '#283e60',
    accentAlt: '#1ca2dc',
    modalTitle: 'black',
    icons: '#fff',
    borderAccountContainer: 'grey',
    borderAccountInput: '',
    hoverAlt: '',

    buttons: {
      primary: '#1c2c4b',
      cancelBtn: '#283e60',
      navButton: {
        bg: '#283e60',
        bgHover: '#496d90',
        borderFocus: 'transparent',
      },
    },
    table: {
      menu: {
        bgHover: '#283e60',
      },
      settings: 'white',
    },
    subComponentTable: {
      bgThead: '#23334a',
      bgTheadFilter: '#1ca2dc',
    },
    dialog: {
      buy: '#45ad4d',
      sell: '#df2230',
      label: '#1ca2dc',
    },
    newSpot: {
      color: 'white',
      colorHover: '#496d90',
    },
    modes: {
      bm: {
        topBarBack: '#fff',
        topBarElements: '#00806C',
        topBarSecond: '#00806C',

        drawerHide: '#fff',
        drawerOpen: '#fff',
        drawerElements: '#00806C',
        drawerElementsDeactivate: '#00806C',
        drawerHover: '#ECF6F5',

        background: '#fff',

        text: '#505050',
        disabled: 'lightgray',
        primary: '#fff',
        secondary: '#c1d4cf',
        aux: '#b4bfbc',
        accent: '#19a496',

        subtitle: 'violet',
        muted: '#eee',
        odd: '#FAFAFA',
        even: '#F5F5F5',
        green: '#00806C',
        greenHover: '#329888',
        greenValues: '#00806C',
        redValues: '#A70004',
        red: '#A70004',
        orange: '#ed6f2d',
        gray: '#3799a9',
        noEdit: '#323232',
        adornment: 'rgba(114, 114, 114, 0.5)',

        items: '#eee',
        selectedCell: '#00806C',
        checkbox: '#00806C',
        checkboxModal: '#00806C',
        accentAlt: '#fff',
        modalTitle: '#505050',
        icons: '#323232',
        borderAccountContainer: '#eee',
        borderAccountInput: '#323232',
        hoverAlt: '#329888',

        buttons: {
          primary: '#505050',
          cancelBtn: '#505050',
          navButton: {
            bg: '#00806C',
            bgHover: '#329888',
            borderFocus: '#005442',
          },
        },
        table: {
          menu: {
            bgHover: '#c1d4cf',
          },
          settings: '#00806C',
        },
        subComponentTable: {
          bgThead: '#fff',
          bgTheadFilter: '#ECF6F5',
        },
        dialog: {
          buy: '#00806C',
          sell: '#A70004',
          label: '#eee',
        },
        newSpot: {
          color: '#727272',
          colorHover: '#323232',
        },
      },

      igf: {
        topBarBack: '#fff',
        topBarElements: '#00806C',
        topBarSecond: '#00806C',

        drawerHide: '#fff',
        drawerOpen: '#fff',
        drawerElements: '#00806C',
        drawerElementsDeactivate: '#00806C',
        drawerHover: '#ECF6F5',

        background: '#fff',

        text: '#505050',
        disabled: 'lightgray',
        primary: '#fff',
        secondary: '#c1d4cf',
        aux: '#b4bfbc',
        accent: '#19a496',

        subtitle: 'violet',
        muted: '#eee',
        odd: '#FAFAFA',
        even: '#F5F5F5',
        green: '#00806C',
        greenHover: '#329888',
        greenValues: '#00806C',
        redValues: '#A70004',
        red: '#A70004',
        orange: '#ed6f2d',
        gray: '#3799a9',
        noEdit: '#323232',
        adornment: 'rgba(114, 114, 114, 0.5)',

        items: '#eee',
        selectedCell: '#00806C',
        checkbox: '#00806C',
        checkboxModal: '#00806C',
        accentAlt: '#fff',
        modalTitle: '#505050',
        icons: '#323232',
        borderAccountContainer: '#eee',
        borderAccountInput: '#323232',
        hoverAlt: '#329888',

        buttons: {
          primary: '#505050',
          cancelBtn: '#505050',
          navButton: {
            bg: '#00806C',
            bgHover: '#329888',
            borderFocus: '#005442',
          },
        },
        table: {
          menu: {
            bgHover: '#c1d4cf',
          },
          settings: '#00806C',
        },
        subComponentTable: {
          bgThead: '#fff',
          bgTheadFilter: '#ECF6F5',
        },
        dialog: {
          buy: '#00806C',
          sell: '#A70004',
          label: '#eee',
        },
        newSpot: {
          color: '#727272',
          colorHover: '#323232',
        },
      },
    },
  },
}
