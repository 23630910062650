const defaultState = {
  socketConnected: false,
  ids: {},
  data: {},
  snackbar: {},
  rfqInfo: {},
  hasInfo: true,
}

const isRfqDataChanged = (worstBid, bidsList, worstAsk, asksList, payload, askKeys, bidKeys) =>
  askKeys.length > 4 &&
  bidKeys.length > 4 &&
  bidsList?.[worstBid]?.bidPrice >= payload.quote.bidPrice &&
  asksList?.[worstAsk]?.offerPrice <= payload.quote.offerPrice &&
  !askKeys.includes(payload.quote.quoteId) &&
  !bidKeys.includes(payload.quote.quoteId)

export default (state = defaultState, { type, payload } = {}) => {
  switch (type) {
    case 'SOCKET_CONNECTED':
      return { ...state, socketConnected: payload }
    case 'ADD_OID':
      return { ...state, ids: { ...state.ids, [payload]: payload } }
    case 'GET_ID_FOR_OID':
      return state.ids[payload.oid]
        ? {
            ...state,
            ids: { ...state.ids, [payload.oid]: payload.id },
          }
        : state
    case 'UPDATE_RFQ':
      const bidsList = state.data[payload.rfqId] ? state.data[payload.rfqId].bids : {}
      const asksList = state.data[payload.rfqId] ? state.data[payload.rfqId].asks : {}
      const bidKeys = Object.keys(bidsList)
      const askKeys = Object.keys(asksList)
      const worstBid = bidKeys.length
        ? bidKeys.reduce((k1, k2) => (bidsList[k1].bidPrice < bidsList[k2].bidPrice ? k1 : k2))
        : false
      const worstAsk = askKeys.length
        ? askKeys.reduce((k1, k2) => (asksList[k1].offerPrice > asksList[k2].offerPrice ? k1 : k2))
        : false
      if (isRfqDataChanged(worstBid, bidsList, worstAsk, asksList, payload, askKeys, bidKeys)) {
        return state
      }
      const bids =
        bidsList?.[worstBid]?.bidPrice < payload.quote.bidPrice
          ? bidKeys.reduce(
              (obj, k) =>
                k === bidsList[worstBid].quoteId
                  ? { ...obj, [payload.quote.quoteId]: payload.quote }
                  : { ...obj, [k]: bidsList[k] },
              {}
            )
          : bidsList
      const asks =
        asksList?.[worstAsk]?.offerPrice > payload.quote.offerPrice
          ? askKeys.reduce(
              (obj, k) =>
                k === asksList[worstAsk].quoteId
                  ? { ...obj, [payload.quote.quoteId]: payload.quote }
                  : { ...obj, [k]: asksList[k] },
              {}
            )
          : asksList
      return {
        ...state,
        data: {
          ...state.data,
          [payload.rfqId]: {
            bids:
              bidKeys.length < 5 || bidsList[payload.quote.quoteId]
                ? { ...bidsList, [payload.quote.quoteId]: payload.quote }
                : bids,
            asks:
              askKeys.length < 5 || asksList[payload.quote.quoteId]
                ? { ...asksList, [payload.quote.quoteId]: payload.quote }
                : asks,
          },
        },
      }
    case 'SHOW_SNACKBAR':
      return { ...state, snackbar: { open: true, id: payload } }
    case 'HIDE_SNACKBAR':
      return { ...state, snackbar: {} }
    case 'SAVE_RFQ_LIST':
      return { ...state, rfqList: payload }
    default:
      return state
  }
}
