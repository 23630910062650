export const menu = {
  backgroundColor: 'accent',
  '&:hover': {
    backgroundColor: 'primary',
  },
  '&:focus': {
    backgroundColor: 'accent',
  },
}

export const tableTitle = {
  height: '26px',
  padding: '4px 8px',
  position: 'relative',
  backgroundColor: 'muted',
  cursor: 'default',
  color: 'text',
}
export const settings = {
  width: '17px',
  height: '26px',
  fill: 'table.settings',
  padding: '4px 8px',
  cursor: 'pointer',
}
