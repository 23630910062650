/** @jsx jsx */
import { jsx } from 'theme-ui'
import { connect } from 'react-redux'
import CustomButton from '../CustomButton'
import { createSelector } from 'reselect'
import { instrumentSelector } from '../../selectors/instruments'
import { tickSelector } from '../../selectors/marketData'

export const sides = { BUY: 'BUY', SELL: 'SELL' }

const PriceCell = ({ side, onPriceClick, instrument, marketData }) => {
  const safePrice = (currency, key, decimals) => {
    return currency?.[key] ? Number(currency?.[key]).toFixed(decimals) : ''
  }

  const askPriceFixed = safePrice(marketData, 'askPrice', instrument?.priceDecimals)
  const bidPriceFixed = safePrice(marketData, 'bidPrice', instrument?.priceDecimals)

  const price = side === sides.BUY ? askPriceFixed : bidPriceFixed

  return (
    <CustomButton
      onClick={() => {
        onPriceClick(price, side)
      }}
      size="large"
      disabled={price ? false : true}
      type={`cell${side.toLowerCase()}`}
      sx={{ padding: '3px', transition: 'none' }}
      width="80%"
    >
      {price || '...'}
    </CustomButton>
  )
}

const marketDataSelector = createSelector(instrumentSelector, tickSelector, (instrument, ticks) => {
  const accountId = instrument.marketNeedsAccount ? instrument.selectedAccountId || '' : ''

  return ticks[instrument.exchangeId]?.[instrument.symbol]?.[accountId]
})

const mapStateToProps = (state, ownProps) =>
  createSelector(instrumentSelector, marketDataSelector, (instrument, marketData) => ({
    instrument,
    marketData,
  }))(state, ownProps)

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PriceCell)
