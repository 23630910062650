/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as CustomTheme from './style'
import { COOKIE_POLICY, PRIVACY_POLICY, LEGAL_NOTICE } from '../../common/routes'

import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { ListItem, ListItemIcon, ListItemText, List } from '@material-ui/core'

import Icon from '../Icon'
import {
  changeWorkspace,
  removeWorkspace,
  updateWorkspaceName,
  saveWorkspace,
} from '../../actions/workspace'
import ConnectionIndicator from '../ConnectionIndicator'
import { I18nContext } from '../../containers/i18n'
import IconSvg from '../IconSvg/IconSvg'

const WorkspaceNavBar = ({
  changeWorkspace,
  isOpen,
  keycloak,
  removeWorkspace,
  saveWorkspace,
  updateWorkspaceName,
  workspace,
}) => {
  const { t } = useContext(I18nContext)
  useEffect(() => {
    saveWorkspace({
      token: keycloak.token,
      data: workspace,
    })
  }, [workspace.workspaces.length])

  return (
    <div sx={isOpen ? CustomTheme.drawerOpen : CustomTheme.drawerHide}>
      <div sx={CustomTheme.workspaceList}>
        <List>
          {workspace.workspaces.map((space, index) => (
            <div sx={CustomTheme.tabWorkspace} key={index}>
              <ListItem
                button
                selected={index === workspace.currentWorkspace}
                onClick={() => changeWorkspace(index)}
              >
                <ListItemIcon>
                  <IconSvg
                    name={'workspace'}
                    style={
                      index === workspace.currentWorkspace
                        ? CustomTheme.workspaceIconActive
                        : CustomTheme.workspaceIconDeactive
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    index === workspace.currentWorkspace ? (
                      <div>
                        <input
                          type="text"
                          sx={CustomTheme.textInput}
                          value={space.name}
                          data-test-id="rename-workspace"
                          onChange={e => updateWorkspaceName(index, e.target.value)}
                        />
                      </div>
                    ) : (
                      <div>
                        <span sx={CustomTheme.text}>
                          {space.name}
                          &nbsp;
                        </span>
                      </div>
                    )
                  }
                />
              </ListItem>
              {workspace.workspaces.length > 1 && isOpen && (
                <Icon
                  sx={CustomTheme.workspaceClose}
                  data-test-id="close-workspace"
                  name="times"
                  onClick={() =>
                    keycloak.updateToken(30).success(() => {
                      changeWorkspace(index)
                      removeWorkspace(index)
                    })
                  }
                />
              )}
            </div>
          ))}
        </List>
      </div>

      <div sx={CustomTheme.sidebarBottom}>
        {isOpen && (
          <ul>
            <li>
              <a href={LEGAL_NOTICE} target="_blank">
                {t('legalNotice')}
              </a>
            </li>
            <li>
              <a href={COOKIE_POLICY} target="_blank">
                {t('cookiePolicy')}
              </a>
            </li>
            <li>
              <a href={PRIVACY_POLICY} target="_blank">
                {t('privacyPolicy')}
              </a>
            </li>
          </ul>
        )}

        <ConnectionIndicator tooltipText={t('connectionStatus')} />
      </div>
    </div>
  )
}

const mapStateToProps = ({ workspace }) => ({
  workspace,
})

const mapDispatchToProps = { changeWorkspace, removeWorkspace, saveWorkspace, updateWorkspaceName }

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceNavBar)
