import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { DialogContent } from '../Dialog'

export const Content = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-content: space-between;
  max-width: 700px;
  min-width: 500px;
  padding: 0 20px;
`

export const headLabel = { display: 'flex', backgroundColor: 'muted', justifyContent: 'center' }
