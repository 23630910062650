/** @jsx jsx */
import { jsx } from 'theme-ui'
import StaticLegalPage from '../../components/StaticLegalPage'

const LegalNotice = () => {
  return (
    <StaticLegalPage title="Aviso Legal">
      <h2>Aviso Legal</h2>
      <h3>Datos identificativos</h3>

      <p>
        Denominación social: El titular y prestador de servicios de la presente Página Web es BME
        Post Trade Services, S.A.U. (en adelante, la "Sociedad").
      </p>
      <p>Dirección de correo electrónico: bmefx@grupobme.es</p>
      <h3>Condiciones Generales de utilización de la página Web</h3>
      <h4>Aceptación de las condiciones de uso</h4>
      <p>
        Al acceder y usar esta Página Web, por cualquier usuario de la misma (en adelante, el
        "usuario") declara conocer y aceptar todos los términos y condiciones de uso contenidos en
        la misma. Si no está de acuerdo con ellos, el usuario no debe usar esta Página Web.
      </p>
      <p>
        Para recabar esta información y tratarla de manera estadísticamente la Sociedad emplea la
        herramienta Google Analytics, propiedad de Google con el fin único de obtener información
        anónima de los usuarios de esta Web. Puede encontrar las políticas de privacidad y de Google
        Analytics en el centro de privacidad de Google y el complemento de inhabilitación de Google
        Analytics.
      </p>
      <h4>Propiedad de la Página Web</h4>
      <p>
        Esta Página Web es propiedad de la Sociedad. Los derechos de propiedad intelectual y
        derechos de explotación y reproducción de esta Página Web, así como su contenido, apariencia
        y diseño, son propiedad exclusiva de ésta, salvo que expresamente se especifique otra cosa.
        Cualquier uso indebido de esta Página Web o de su contenido podrá ser perseguido de
        conformidad con la legislación vigente.
      </p>
      <p>
        La Sociedad se reserva el derecho de modificar, actualizar, ampliar y eliminar en cualquier
        momento, sin necesidad de previo aviso, el contenido de esta Página Web, incluyendo las
        propias condiciones de uso. Asimismo, podrá restringir o suprimir el acceso a esta Página
        Web, por las razones que estime convenientes y a su entera discreción.
      </p>
      <h4>Alcance de la Página Web</h4>

      <p>
        Toda la información puesta a disposición en esta Página Web ("contenido") tiene carácter
        exclusivamente informativo.
      </p>
      <p>
        No es intención de la Sociedad utilizar esta Página Web como vehículo ni instrumento,
        directo o indirecto, para la realización de negocios o la contratación de personas. Bajo
        ninguna circunstancia debe usarse ni considerarse esta Página Web como oferta de venta,
        solicitud de una oferta de compra, ni como ofrecimiento para la realización de ningún otro
        tipo de transacción, salvo que expresamente se especifique otra cosa.
      </p>
      <p>
        El contenido de esta Página Web no conlleva ningún tipo de asesoramiento legal, ni de otra
        clase, por parte de la Sociedad, en relación con la actuación en el mercado de valores, dada
        su finalidad exclusivamente informativa. En consecuencia, no debe ser tomado como base o
        recomendación para realizar inversiones o tomar decisiones, siendo responsabilidad personal
        del usuario la obtención de la información y asesoramiento adecuados sobre los riesgos y la
        normativa reguladora de la organización y operativa del mercado de valores. Igualmente,
        corresponde al usuario valorar y, en caso, asumir los riesgos asociados a operar en tal
        mercado.
      </p>
      <p>
        Esta Página Web puede contener información proporcionada por entidades distintas de la
        Sociedad, sin que ésta responda de la veracidad de la información ni asuma responsabilidad
        alguna en cuanto a hipotéticos perjuicios que pudieran originarse por el uso de dicha
        información.
      </p>
      <p>
        Esta Página Web contiene enlaces con otras páginas web con la intención de facilitar y
        proporcionar al usuario una mejor información acerca del trabajo, proyectos, iniciativas y
        asuntos relativos al objeto social de la Sociedad. Es responsabilidad del usuario evaluar el
        contenido y la utilidad de la información publicada en esos otros espacios web, sin que la
        Sociedad asuma responsabilidad alguna acerca de la seguridad o privacidad de esos enlaces ni
        del contenido de dichas páginas web.
      </p>
      <p>
        Los terceros que deseen establecer enlaces con esta Página Web deberán obtener una
        autorización previa y por escrito de la Sociedad para ello.
      </p>
      <h4>Uso de la Página Web</h4>
      <p>
        Los contenidos de esta Página Web pueden ser descargados, si estuvieran dispuestos para
        ello, copiados e impresos únicamente para uso personal. La Sociedad, no se responsabiliza de
        las posibles discrepancias que puedan surgir entre la versión impresa y la versión
        electrónica de la información contenida en esta Página Web.
      </p>
      <p>
        Sin perjuicio de lo establecido en el párrafo anterior, queda prohibida la copia,
        duplicación, redistribución, reproducción electrónica, impresión, comercialización o
        cualquier otra actividad que pudiera realizarse con el contenido de esta Página Web, ya se
        trate de documentos escritos ya de gráficos, en su totalidad o parcialmente, ni aun citando
        la fuente, salvo consentimiento previo por escrito de la Sociedad.
      </p>
      <h4>Responsabilidades</h4>
      <p>
        La Sociedad no otorga garantía ni se hace responsable de los daños, perjuicios, pérdidas,
        reclamaciones o gastos, de cualquier naturaleza, que resulten o estén en conexión con el
        uso, la imposibilidad de uso o el uso ilícito, negligente o fraudulento de esta Página Web.
      </p>
      <p>
        Con el objeto de ofrecer un mejor servicio a los usuarios, la Sociedad podrá realiza
        actualizaciones y mejoras del contenido de esta Página Web. Sin embargo, la información
        contenida de esta Página Web no tiene carácter oficial. La responsabilidad de la Sociedad se
        circunscribe a aquella información que publique con dicho carácter.
      </p>
      <p>
        La Sociedad pone esta Página Web a disposición del usuario de acuerdo con el estado actual
        de la técnica, el cual no permite garantizar la seguridad ni la privacidad de esta Página
        Web, ni que ésta o su contenido estén libres del riesgo de interrupción temporal,
        indisponibilidad parcial u otras alteraciones. El usuario debe conocer que cualquier
        información recibida o enviada a través de esta Página Web es susceptible de ser
        interceptada.
      </p>
      <h4>Legislación aplicable y jurisdicción</h4>
      <p>
        El presente aviso legal, así como la Página Web, se rigen por la legislación española. Para
        la resolución de cualquier discrepancia que pudiera surgir entre la Sociedad y el usuario,
        ambas partes, con expresa renuncia a cualquier otro fuero que pudiera corresponderles, se
        someten a la jurisdicción de los Jueces y Tribunales de Madrid.
      </p>
    </StaticLegalPage>
  )
}

export default LegalNotice
