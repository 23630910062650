/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { tableColumns } from '../../common/utilities'
import * as style from './style'
import {
  baseCurrencyCell,
  eurCurrencyCell,
  numberCell,
  quoteCurrencyCell,
} from '../../common/utilities/tableCellUtils'

const columnsHead = t => {
  /*
   *  Cell format for the netPosition column
   */
  const netPositionCell = (value, long) => {
    return <span sx={long ? style.long : style.short}>{value}</span>
  }

  const c = tableColumns(t)

  return [
    c.getColumn({ accessor: 'account', csvHeader: 'account' }),
    c.getColumn({ header: 'instrument', accessor: 'symbol', csvHeader: 'instrument' }),
    c.getFromToFloatColumn({
      header: 'netPosition',
      accessor: 'netPositionFormat',
      csvHeader: 'netPosition',
      filterProps: { type: 'number' },
      Cell: ({ value }) =>
        value != 0 ? netPositionCell(numberCell({ value }), value > 0) : numberCell({ value: 0 }),
    }),
    c.getFromToCurrencyColumn({
      header: 'netPositionValue',
      csvHeader: 'netPositionValue',
      filterProps: { type: 'number' },
      Cell: baseCurrencyCell,
    }),
    c.getFromToFloatColumn({
      header: 'averagePrice',
      accessor: 'averagePriceFormat',
      csvHeader: 'averagePrice',
      filterProps: { type: 'number' },
      Cell: numberCell,
    }),
    c.getFromToCurrencyColumn({
      header: 'deferralVariationMargin',
      accessor: 'deferralVariationMargin',
      csvHeader: 'deferralVariationMargin',
      filterProps: { type: 'number' },
      Cell: quoteCurrencyCell,
    }),
    c.getFromToCurrencyColumn({
      header: 'deferralVariationMarginEUR',
      accessor: 'deferralVariationMarginEURValue',
      csvHeader: 'deferralVariationMarginEur',
      filterProps: { type: 'number' },
      Cell: eurCurrencyCell,
    }),
    c.getFromToCurrencyColumn({
      header: 'unrealizedPL',
      accessor: 'unrealizedPnL',
      csvHeader: 'unrealizedPnL',
      filterProps: { type: 'number' },
      Cell: quoteCurrencyCell,
    }),
    c.getFromToCurrencyColumn({
      header: 'unrealizedPLE',
      accessor: 'unrealizedPnLEURValue',
      csvHeader: 'unrealizedPnLEur',
      filterProps: { type: 'number' },
      Cell: eurCurrencyCell,
    }),
    c.getFromToCurrencyColumn({
      header: 'realizedProfit',
      accessor: 'realizedProfit',
      csvHeader: 'realizedProfit',
      filterProps: { type: 'number' },
      Cell: quoteCurrencyCell,
    }),
    c.getFromToCurrencyColumn({
      header: 'realizedProfitEUR',
      accessor: 'realizedProfitEURValue',
      csvHeader: 'realizedProfitEur',
      filterProps: { type: 'number' },
      Cell: eurCurrencyCell,
    }),
  ]
}

export { columnsHead }
