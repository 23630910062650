import { compose, applyMiddleware, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'

import reducers from './reducers'

const isDev = process.env.NODE_ENV === 'development'
const hiddenActions = ['EXAMPLE_HIDDEN_ACTION']
const logger = createLogger({
  predicate: (_, action) => !hiddenActions.includes(action.type),
  collapsed: (_, __, logEntry) => !logEntry.error,
})

const middleware = [promise(), thunk, ...(isDev ? [logger] : [])]
const composeEnhancers = (isDev && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose
const store = createStore(reducers, composeEnhancers(applyMiddleware(...middleware)))

export default store
