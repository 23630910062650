export const container = {
  zIndex: '1000',
  position: 'fixed',
  marginTop: '37px',
  width: '332px',
  textAlign: 'center',
  padding: '0px 8px 8px 8px',
  height: '299px',
  overflow: 'hidden',
  backgroundColor: 'muted',
  border: '1px grey solid',
  '*': { ':focus': { outline: 'none', backgroundColor: 'transparent' } },
}

export const input = {
  width: '100%',
  height: '38px',
  bg: 'primary',
  color: 'text',
  border: 'none',
  fontSize: 4,
  '&:hover': {
    bg: 'secondary',
  },
}

export const resultBox = {
  marginTop: '10px',
  minHeight: '100px',
  maxHeight: '194px',
  overflowY: 'scroll',
  scrollbarWidth: 'thin',
  backgroundColor: 'primary',
}

export const divider = {
  marginTop: '20px',
}

export const inputContainer = {
  width: '95.5%',
  marginTop: '10px',
  color: 'text',
}

export const instrumentContainer = {
  minWidth: '300px',
  display: 'flex',
  justifyContent: 'space-between',
}

export const instrumentDataDescription = {
  color: 'grey',
}

export const instrumentSymbol = {
  color: 'text',
}

export const iconColor = {
  color: 'gray',
}

export const searchIcon = {
  color: 'text',
}

export const menuItem = {
  '&:hover': {
    backgroundColor: 'secondary',
  },
}
