/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useContext } from 'react'

import { Field, Label, Text } from './style'
import Grid from '@material-ui/core/Grid'
import { I18nContext } from '../../containers/i18n'
import { FormattedInteger } from '../FormattedNumber'

const TextField = ({ value }) => {
  const { t } = useContext(I18nContext)
  return <Text>{t(value) || value || ''}</Text>
}

const FormatField = props => (
  <>
    {props.nameData?.name && (
      <Field>
        <Label>{props.nameData.name}</Label>
        <props.format value={props.nameData.data} />
      </Field>
    )}
  </>
)

const OrderData = ({ nameData }) => {
  return (
    <Grid container spacing={8}>
      <Grid item md={4} sm={6} xs={12}>
        <FormatField nameData={nameData.side} format={TextField} />
        <FormatField nameData={nameData.exchangeId} format={TextField} />
        <FormatField nameData={nameData.symbol} format={TextField} />
        {/* <FormatField nameData={nameData.masterOrderId} />*/}
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <FormatField nameData={nameData.orderType} format={TextField} />
        <FormatField nameData={nameData.expiry} format={TextField} />
        <FormatField nameData={nameData.account} format={TextField} />
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <FormatField nameData={nameData.qty} format={FormattedInteger} />
        <FormatField nameData={nameData.cumQty} format={FormattedInteger} />
        <FormatField nameData={nameData.remainingQty} format={FormattedInteger} />
      </Grid>
    </Grid>
  )
}

export default OrderData
