import { Dialog as MDialog } from '@material-ui/core'
import styled from '@emotion/styled'

export const alertStyles = { color: 'red' }
export const warningStyles = { color: '#ff7f00' }

export const Dialog = styled(MDialog)(props => ({
  borderRadius: 'none',
  color: '#1c2c4b',
  fullWidth: true,
}))

MDialog.defaultProps = {
  maxWidth: 'md',
  PaperProps: { square: true },
}

export const ListContainer = styled.ul`
  text-align: left;
`

export const Field = styled.div`
  min-width: 350px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 20px 0 10px;
  align-items: center;
`

export const SectionTitle = styled.h3`
  margin: 15px 0;
  font-size: 1.4em;
`

export const SpecificBody = styled(Field)`
  color: red;
  font-weight: bold;
  padding: 0 30px;
`

export const SubTitle = styled.h2`
  font-size: 1.5em;
`

export const ListTitle = styled.h4`
  font-size: 1.2em;
  font-weight: 500;
  margin: 15px 0;
`

export const ListItem = styled.li`
  margin: 0 40px;
  font-weight: 300;
  list-style: none;
`

export const ListDecoratedItem = styled(ListItem)`
  list-style: square;
`
