import { set, lensPath } from 'ramda'

export const defaultState = { csv: {} }

export default (state = defaultState, { type, payload } = {}) => {
  switch (type) {
    case 'SET_DOWNLOAD_CSV':
      const downloadCsvLens = lensPath([
        'csv',
        payload.currentWorkspace,
        'tabs',
        payload.tabIndex,
        'download',
      ])
      return set(downloadCsvLens, payload.download, state)

    default:
      return state
  }
}
