const dataHeight = '26px'
const inlineBlock = 'inline-block'

const getTextStyle = {
  fontSize: '10px',
  textAlign: 'center',
  color: 'text',
  lineHeight: dataHeight,
}

export const depthPanel = {
  position: 'relative',
  mr: '5px',
  ml: '9px',
  mb: '9px',
  width: '232px',
}

export const depthHeader = {
  width: '100%',
}

export const depthSection = {
  width: '232px',
  height: dataHeight,
  mb: '3px',
}

export const setLabelVolLeftStyle = {
  ...getTextStyle,
  width: '17%',
  height: '16px',
  lineHeight: '16px',
  mr: '1%',
  pl: '1%',
  pr: '1%',
  display: inlineBlock,
}

export const setLabelPriceStyle = {
  ...getTextStyle,
  width: '26%',
  height: '16px',
  lineHeight: '16px',
  ml: '1%',
  mr: '1%',
  pl: '1%',
  pr: '1%',
  display: inlineBlock,
}

export const setLabelVolRightStyle = {
  ...getTextStyle,
  width: '17%',
  height: '16px',
  lineHeight: '16px',
  ml: '1%',
  pl: '1%',
  pr: '1%',
  display: inlineBlock,
}

export const setBoxLeftStyle = {
  ...getTextStyle,
  width: '17%',
  height: dataHeight,
  mr: '1%',
  pl: '1%',
  pr: '1%',
  bg: 'disabled',
  display: inlineBlock,
}

export const setButtonStyle = ask => ({
  ...getTextStyle,
  cursor: 'pointer',
  width: '26%',
  height: dataHeight,
  ml: '1%',
  mr: '1%',
  pl: '1%',
  pr: '1%',
  bg: 'primary',
  '&:hover': {
    color: 'white',
    bg: ask ? 'green' : 'red',
  },
  display: inlineBlock,
})

export const setBoxRightStyle = {
  ...getTextStyle,
  width: '17%',
  height: dataHeight,
  ml: '1%',
  pl: '1%',
  pr: '1%',
  bg: 'disabled',
  display: inlineBlock,
}
