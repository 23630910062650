export const userSettingsTittle = {
  fontFamily: '',
  color: 'black',
  fontSize: '',
}

export const marginLabel = {
  marginRight: '15px',
}

export const interfaceTitle = {
  display: 'flex',
  alignItems: 'center',
  paddingTop: '0.5em',
  paddingBottom: '0.5em',
  paddingLeft: '1em',
  fontFamily: '',
  backgroundColor: 'accent',
  color: 'text',
}

export const tradingSettings = {
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
  height: 'max-content',
  paddingLeft: '1em',
  paddingRight: '1em',
}

export const labelTitle = {
  fontSize: 'larger',
  fontWeight: '400',
  color: 'white',
  top: '-3px',
  position: 'relative',
}

export const mainTitle = {
  fontSize: '23px',
  fontWeight: '400',
  color: 'black',
  top: '-3px',
  position: 'relative',
}

export const settings = {
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
  height: 'max-content',
  flexDirection: 'column',
  paddingLeft: '1em',
  paddingRight: '1em',
  paddingTop: '2em',
  paddingBottom: '2em',
}

export const languageSettings = {
  alignItems: 'center',
  display: 'flex',
}

export const textContainer = {
  overflowX: 'hidden',
  width: '450px',
}

export const iconColor = {
  color: 'gray',
}

export const input = colorMode => ({
  width: '100%',
  bg: 'primary',
  color: 'text',
  border: theme => theme.borders.primary[colorMode],
  borderColor: 'muted',
  fontSize: 3,
  '&:hover': {
    bg: 'secondary',
  },
})

export const w50 = {
  width: '49.5%',
}
