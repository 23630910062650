import { Stomp } from '@stomp/stompjs'
import config from '../common/config'
import _ from 'lodash'

const createStompClient = config => {
  const client = Stomp.over(() => new WebSocket(config.urls.marketData))
  if (process.env.NODE_ENV !== 'development') {
    client.debug = () => { }
  }
  client.debug = () => { }

  return client
}

const createCacheMap = () => new Map()

const client = createStompClient(config())
const subscriptions = createCacheMap()
const staticSubscriptions = createCacheMap()
const dynamicSubscriptions = createCacheMap()
const dynamicSubscriptionKeysByAccount = createCacheMap()
const dynamicSubscriptionKeysByMarket = createCacheMap()
const removedButNeeded = createCacheMap()

//TODO: Los sets solo comparan referencias. Tengo que mirar que la clave no exista antes de meterlo
const addDynamicSubscription = (
  map,
  key,
  exchangeId,
  symbol,
  account,
  subscriptionType,
  actionType,
  path
) => {
  console.warn('Before add', map)
  if (!map.has(exchangeId)) {
    map.set(exchangeId, [])
  }

  const values = map.get(exchangeId)
  let found = false
  // for (const element of set) {
  //   if (element.key === key) {
  //     found = true
  //     set.delete(element)
  //     element.count += 1
  //     set.add(element)
  //   }
  // }

  values.forEach((element, index) => {
    if (element.key === key) {
      found = true
      values[index] = { ...element, count: element.count + 1 }
    }
  })

  if (!found) {
    const result = {
      key: key,
      exchangeId,
      exchangeId,
      symbol: symbol,
      account: account,
      subscriptionType: subscriptionType,
      actionType: actionType,
      path: path,
      count: 1,
    }
    values.push(result)
  }
  console.warn('After add. Set size %d', map.get(exchangeId).size, map)
}

const removeDynamicSubscription = (map, key, exchangeId, force = false) => {
  console.warn('Removing entry with key %s on exchange %s', key, exchangeId, map)
  const values = map.get(exchangeId)
  if (!values) {
    console.error('Map is empty')
    return
  }
  values.forEach((element, index) => {
    if (element.key === key) {
      if (element.count <= 1 || force) {
        values.splice(index, 1)
      } else values[index] = { ...element, count: element.count - 1 }
    }
  })
  if (values.length === 0) {
    console.warn('Set is empty, removing from map')
    map.delete(exchangeId)
  }
  console.warn('After remove', map)
}

// const addDynamicSubscription = (account, key) => {
//   console.warn("Before add", dynamicSubscriptionKeysByAccount)
//   if (!dynamicSubscriptionKeysByAccount.has(account)) {
//     dynamicSubscriptionKeysByAccount.set(account, new Set())
//   }
//   dynamicSubscriptionKeysByAccount.get(account).add(key)
//   console.warn("After add. Set size %d",dynamicSubscriptionKeysByAccount.get(account).size, dynamicSubscriptionKeysByAccount)
// }

// const removeDynamicSubscription = (account, key) => {
//   console.warn("Before remove", dynamicSubscriptionKeysByAccount)
//   dynamicSubscriptionKeysByAccount.get(account).delete(key)
//   console.warn("After remove",dynamicSubscriptionKeysByAccount)
// }

const manageSubscription = _.throttle(
  (
    map,
    { exchangeId, symbol, subscriptionType, account, isDynamic },
    actionType,
    path,
    initialCount,
    dispatch
  ) => {
    //TODO: Error si es dinámica y no tiene account
    const key = account
      ? `${exchangeId}:${symbol}:${account}:${subscriptionType}`
      : `${exchangeId}:${symbol}:${subscriptionType}`

    if (map.has(key)) {
      const existingEntry = map.get(key)
      map.set(key, {
        ...existingEntry,
        staticCount: isDynamic
          ? existingEntry.staticCount
          : existingEntry.staticCount + initialCount,
        dynamicCount: isDynamic
          ? existingEntry.dynamicCount + initialCount
          : existingEntry.dynamicCount,
      })
    } else {
      const newEntry = {
        staticCount: isDynamic ? 0 : initialCount,
        dynamicCount: isDynamic ? initialCount : 0,
        account: account,
        sub: client.subscribe(path, data => {
          const parsedData = JSON.parse(data.body)
          // if (
          //   parsedData.exchangeId &&
          //   parsedData.symbol &&
          //   `${parsedData.exchangeId}:${parsedData.symbol}:${parsedData[dataPath] || ''}` === key
          // ) {
          dispatch({ type: actionType, payload: parsedData })
          // }
        }),
      }
      map.set(key, newEntry)
    }
    if (isDynamic) {
      addDynamicSubscription(
        dynamicSubscriptionKeysByMarket,
        key,
        exchangeId,
        symbol,
        account,
        subscriptionType,
        actionType,
        path
      )
    }
  },
  0
)

//Asumes format from accountSelector
export const updateDynamicSubscriptions = accounts => dispatch => {
  console.warn('Updating dynamic subscriptions with accounts', accounts)
  console.warn('Current subscriptions', subscriptions)
  console.warn('Current dynamic entries', dynamicSubscriptionKeysByMarket)
  console.warn('Current removed but needed', removedButNeeded)

  Object.entries(accounts).forEach(([key, value]) => {
    if (dynamicSubscriptionKeysByMarket.has(key)) {
      const dynSubscriptions = new Set(dynamicSubscriptionKeysByMarket.get(key))

      dynSubscriptions.forEach(s => {
        console.error('Changing sub', s)
        const currentSubscription = subscriptions.get(s.key)
        if (!currentSubscription) {
          console.error('Current subscription not found. STOPPING', s)
          return
        }
        if (s.account === value.account) {
          console.warn('Current account is the same as new, SKIPPING', s)
          return
        }
        console.error('Current subscription', s)
        if (currentSubscription.dynamicCount > 0) {
          console.warn('Subscription has dynamic subs. Will resuscribe')
          if (value.account) {
            const newPath = s.path.replace(s.account, value.account)
            console.error('Will call add sub', newPath)
            manageSubscription(
              subscriptions,
              {
                exchangeId: s.exchangeId,
                symbol: s.symbol,
                subscriptionType: s.subscriptionType,
                account: value.account,
                isDynamic: true,
              },
              s.actionType,
              newPath,
              currentSubscription.dynamicCount,
              dispatch
            )
          } else {
            removeDynamicSubscription(dynamicSubscriptionKeysByMarket, s.key, s.exchangeId)
            removeDynamicSubscription(removedButNeeded, s.key, s.exchangeId)
            addDynamicSubscription(
              removedButNeeded,
              s.key,
              s.exchangeId,
              s.symbol,
              s.account,
              s.subscriptionType,
              s.actionType,
              s.path
            )
          }
          manageUnsubscription(
            subscriptions,
            {
              exchangeId: s.exchangeId,
              symbol: s.symbol,
              subscriptionType: s.subscriptionType,
              account: currentSubscription.account,
              isDynamic: true,
            },
            s.actionType.replace('UPDATE', 'REMOVE'),
            currentSubscription.dynamicCount,
            true,
            dispatch
          )
        }
      })
      console.error('Will call remove sub', dynSubscriptions)
      // manageUnsubscription

      console.error('Removing all subscriptions from %s', key, value)
    } else {
      if (removedButNeeded.has(key)) {
        const subs = removedButNeeded.get(key)
        console.warn('Removed but needed subscriptions on market %s', key, removedButNeeded)
        removedButNeeded.delete(key)
        subs.forEach(s => {
          if (value.account) {
            const path = `/public/price/${s.subscriptionType}/${s.exchangeId}/${s.symbol}/${value.account}`
            const action = `UPDATE_MARKET_${s.subscriptionType.toUpperCase()}_DATA`

            manageSubscription(
              subscriptions,
              {
                exchangeId: s.exchangeId,
                symbol: s.symbol,
                subscriptionType: s.subscriptionType,
                account: value.account,
                isDynamic: true,
              },
              action,
              path,
              1,
              dispatch
            )
          } else {
            addDynamicSubscription(
              removedButNeeded,
              s.key,
              s.exchangeId,
              s.symbol,
              'NEEDSACCOUNT',
              s.subscriptionType,
              s.actionType,
              s.path
            )
          }
        })
      } else {
        console.warn("Market %s doesn't have subscriptions", key)
      }
    }
  })
  console.warn('New subscriptions', subscriptions)
  console.warn('New dynamic entries', dynamicSubscriptionKeysByMarket)
  console.warn('New removed but needed', removedButNeeded)
}

const manageUnsubscription = _.throttle(
  (
    map,
    { exchangeId, symbol, subscriptionType, account, isDynamic },
    actionType,
    reduceCount,
    force,
    dispatch
  ) => {
    // if (payload && payload.hasOwnProperty('exchangeId')) {
    const key = account
      ? `${exchangeId}:${symbol}:${account}:${subscriptionType}`
      : `${exchangeId}:${symbol}:${subscriptionType}`
    const payload = { exchangeId, symbol, subscriptionType, account }

    if (!map.has(key)) {
      console.error('Tried to unsubscribe with non exising key %s', key, map)
      return
    }

    const subscription = map.get(key)
    console.warn('Removing subscription', key, subscription)

    const newSubscription = {
      ...subscription,
      staticCount: isDynamic ? subscription.staticCount : subscription.staticCount - reduceCount,
      dynamicCount: isDynamic ? subscription.dynamicCount - reduceCount : subscription.dynamicCount,
    }

    //TODO: Validate is not negative

    if (newSubscription.dynamicCount + newSubscription.staticCount <= 0) {
      map.get(key).sub.unsubscribe()
      dispatch({ type: actionType, payload })
      map.delete(key)
      if (isDynamic) {
        //TODO: Esto no funciona cuando se mezclan static y dynamic
        removeDynamicSubscription(dynamicSubscriptionKeysByMarket, key, exchangeId, force)
      }
    } else {
      console.warn('Map has key %s', key, newSubscription)
      map.set(key, newSubscription)
    }
    // } else {
    //   console.error('Payload is undefined or does not contain exchangeId property')
    // }
  },
  0
)

export const connectToMarketSocket = payload => dispatch =>
  client.connect({ Authorization: `Bearer ${payload}` }, () =>
    dispatch({ type: 'MARKET_SOCKET_CONNECTED' })
  )

const internalSubscribeSymbol = (
  map,
  instrument,
  subscriptionType,
  isDynamic,
  staticAccount,
  dispatch
) => {
  console.warn('Calling subscribe')
  const subscriptionTypeLowerCase = subscriptionType.toLowerCase()
  const subscriptionTypeUpperCase = subscriptionType.toUpperCase()
  const exchangeId = instrument.exchangeId
  const symbol = instrument.symbol
  const marketNeedsAccount = instrument.marketNeedsAccount
  const allowedSubscriptions = instrument.allowedSubscriptions
  const account = marketNeedsAccount ? staticAccount || instrument.selectedAccountId : null
  const subscriptionIsDynamic = (isDynamic && account && true) || false

  if (!allowedSubscriptions || !allowedSubscriptions.includes(subscriptionTypeUpperCase)) {
    console.warn(
      'Subscription %s is not allowed for instrument %s',
      subscriptionTypeUpperCase,
      symbol,
      instrument
    )
    return
  }

  const path = account
    ? `/public/price/${subscriptionTypeLowerCase}/${exchangeId}/${symbol}/${account}`
    : `/public/price/${subscriptionTypeLowerCase}/${exchangeId}/${symbol}`
  const action = `UPDATE_MARKET_${subscriptionTypeUpperCase}_DATA`

  if (marketNeedsAccount && !account) {
    if (isDynamic) {
      console.warn(
        'Instrument %s needs an account to receive market data and it was not provided. Storing on requested map',
        instrument.id,
        instrument
      )
      //TODO:
      const key = `${exchangeId}:${symbol}:NEEDSACCOUNT:${subscriptionType}`
      addDynamicSubscription(
        removedButNeeded,
        key,
        exchangeId,
        symbol,
        'NEEDSACCOUNT',
        subscriptionType,
        action,
        path
      )
      return
    } else {
      console.error(
        'Instrument %s needs an account to receive market data and it was not provided',
        instrument.id,
        instrument
      )
      return
    }
  }

  return manageSubscription(
    map,
    { exchangeId, symbol, subscriptionType, account, isDynamic: subscriptionIsDynamic },
    action,
    path,
    1,
    dispatch
  )
}

export const subscribeSymbolDynamic = ({ instrument, subscriptionType }) => dispatch => {
  console.warn('Subscribing to %s of %s:%s', subscriptionType, instrument.exchangeId, instrument.id)
  return internalSubscribeSymbol(subscriptions, instrument, subscriptionType, true, null, dispatch)
}

export const subscribeSymbolStatic = ({ instrument, subscriptionType, account }) => dispatch => {
  return internalSubscribeSymbol(
    subscriptions,
    instrument,
    subscriptionType,
    false,
    account,
    dispatch
  )
}

export const unsubscribeSymbolDynamic = ({ instrument, subscriptionType }) => dispatch => {
  console.warn(
    'Unsubscribing from %s of %s:%s',
    subscriptionType,
    instrument.exchangeId,
    instrument.id
  )
  return internalUnsubscribeSymbol(
    subscriptions,
    instrument,
    subscriptionType,
    true,
    null,
    dispatch
  )
}

export const unsubscribeSymbolStatic = ({ instrument, subscriptionType, account }) => dispatch => {
  return internalUnsubscribeSymbol(
    subscriptions,
    instrument,
    subscriptionType,
    false,
    account,
    dispatch
  )
}

const internalUnsubscribeSymbol = (
  map,
  instrument,
  subscriptionType,
  isDynamic,
  staticAccount,
  dispatch
) => {
  const subscriptionTypeUpperCase = subscriptionType.toUpperCase()

  const exchangeId = instrument.exchangeId
  const symbol = instrument.symbol
  const marketNeedsAccount = instrument.marketNeedsAccount
  const allowedSubscriptions = instrument.allowedSubscriptions
  const account = marketNeedsAccount ? staticAccount || instrument.selectedAccountId : null
  const subscriptionIsDynamic = (isDynamic && marketNeedsAccount && account && true) || false

  if (!allowedSubscriptions.includes(subscriptionTypeUpperCase)) {
    console.warn(
      'Subscription %s is not allowed for instrument %s for unsubscribe',
      subscriptionTypeUpperCase,
      symbol,
      instrument
    )
    return
  }

  if (marketNeedsAccount && !account) {
    console.error(
      'Instrument %s needs an account to stop receiving market data and it was not provided. Removing from requested map',
      instrument.id,
      instrument
    )
    //TODO:
    const key = `${exchangeId}:${symbol}:NEEDSACCOUNT:${subscriptionType}`
    removeDynamicSubscription(removedButNeeded, key, exchangeId)

    return
  }

  const action = `REMOVE_MARKET_${subscriptionTypeUpperCase}_DATA`
  manageUnsubscription(
    map,
    { exchangeId, symbol, subscriptionType, account, isDynamic: subscriptionIsDynamic },
    action,
    1,
    false,
    dispatch
  )
}
