import React, { useContext } from 'react'
import CustomButton from '../CustomButton'
import { Dialog, SubTitle, SpecificBody, alertStyles, warningStyles } from './style'
import { DialogTitle, DialogContent, DialogActions } from '../Dialog'
import { I18nContext } from '../../containers/i18n'
import CommonBody from './CommonBody'
import { connect } from 'react-redux'
import { closeNotificationDialog } from '../../actions/positions'
import PropTypes from 'prop-types'

export const dinamicNotificationBackground = {
  ALERT: { style: alertStyles },
  WARNING: { style: warningStyles },
}

const getTitle = notification => context => {
  switch (notification.marginAlert.alertType) {
    case 'MARGIN_EXCEEDED':
      return `${context('alert')}: ${context('maxGuaranteeOrderRejected')(
        notification.marginAlert.orderAffectedOperationAccountName
      )}`
    case 'WARNING_THRESHOLD':
      return `${context('warning')}: ${context('maxThresholdGuaranteeConsumption')(
        notification.marginAlert.orderAffectedOperationAccountName
      )}`
    case 'OVER_MARGIN_DECREASES':
    case 'OVER_MARGIN_CANCEL':
      return `${context('alert')}: ${context('maxGuaranteeConsumptionOnAccount')(
        notification.marginAlert.orderAffectedOperationAccountName
      )}`
    default:
      return ''
  }
}

const getBody = notification => context => {
  switch (notification.marginAlert.alertType) {
    case 'MARGIN_EXCEEDED':
      return (
        <SpecificBody style={dinamicNotificationBackground['ALERT'].style}>
          <SubTitle>{context('alert')}</SubTitle>
          <p>
            {context('orderHasBeenRejectedOnAccount')(
              notification.marginAlert.orderAffectedOperationAccountName
            )}
          </p>
        </SpecificBody>
      )
    case 'WARNING_THRESHOLD':
      return (
        <SpecificBody style={dinamicNotificationBackground['WARNING'].style}>
          <SubTitle>{context('warning')}</SubTitle>
          <p>
            {context('reachingMaximumGuaranteeThresholdForAccount')(
              notification.marginAlert.orderAffectedOperationAccountName
            )}
          </p>
        </SpecificBody>
      )
    case 'OVER_MARGIN_DECREASES':
    case 'OVER_MARGIN_CANCEL':
      return (
        <SpecificBody style={dinamicNotificationBackground['ALERT'].style}>
          <SubTitle>{context('alert')}</SubTitle>
          <p>
            {context('reachedMaximumGuaranteeThresholdForAccount')(
              notification.marginAlert.orderAffectedOperationAccountName
            )}
          </p>
        </SpecificBody>
      )
    default:
      return <></>
  }
}

const ModalMessageNotification = ({ open, closeNotificationDialog, notifications }) => {
  const { t } = useContext(I18nContext)

  return (
    open && (
      <Dialog open={open} onClose={closeNotificationDialog}>
        {/* TODO etiqueta sx para aplicar color en funcion del tema */}
        <DialogTitle id="alert-dialog-title" disableTypography>
          {getTitle(notifications[0])(t)}
        </DialogTitle>
        <DialogContent>
          {getBody(notifications[0])(t)}
          <CommonBody notification={notifications[0]} />
        </DialogContent>

        <DialogActions>
          <CustomButton type="primary" width="27%" noMargin onClick={closeNotificationDialog}>
            {t('close')}
          </CustomButton>
        </DialogActions>
      </Dialog>
    )
  )
}

const mapStateToProps = ({
  notifications: {
    notifications,
    notificationModalMessage: { open },
  },
}) => ({
  notifications,
  open,
})

const mapDispatchToProps = { closeNotificationDialog }

ModalMessageNotification.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  notifications: PropTypes.array,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalMessageNotification)
