import PropTypes from 'prop-types'
import Iconlibrary from './IconLibrary'

const IconSvg = ({ name, style }) => Iconlibrary[name](style)

IconSvg.propTypes = {
  name: PropTypes.string,
  style: PropTypes.object,
}

export default IconSvg
