import React, { useEffect } from 'react'
import uuid from 'uuid'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'

import { Table } from '../Table'
import style from './style.css'
import 'react-table/react-table.css'
import { socketSendMessage, subscribeForRFQ } from '../../actions/socket'

const options = {
  defaultPageSize: 1000,
  minRows: 3,
  filterable: false,
  showPagination: false,
  className: '-highlight',
  getTdProps: (_, rowInfo) => ({
    onClick: () =>
      rowInfo.original.status === 'CREATED' && props.onAddClick('RFQ', rowInfo.original),
  }),
}

const columns = [
  {
    Header: 'Id',
    headerClassName: style.tableHeader,
    accessor: 'id',
  },
  {
    Header: 'Pair',
    headerClassName: style.tableHeader,
    accessor: 'pair',
  },
  {
    Header: 'Tenor',
    headerClassName: style.tableHeader,
    accessor: 'tenor',
  },
  {
    Header: 'Amount',
    headerClassName: style.tableHeader,
    accessor: 'amount',
  },
  {
    Header: 'Currency',
    headerClassName: style.tableHeader,
    accessor: 'currency',
  },
  {
    Header: 'Side',
    headerClassName: style.tableHeader,
    accessor: 'side',
  },
  {
    Header: 'Status',
    headerClassName: style.tableHeader,
    accessor: 'status',
  },
  {
    Header: 'Created At',
    headerClassName: style.tableHeader,
    accessor: 'createdAt',
  },
]

const RfqList = ({ rfqList, socketSendMessage, tabIndex, keycloak, subscribeForRFQ }) => {
  useEffect(() => {
    subscribeForRFQ()
    keycloak.updateToken(30).success(() => {
      socketSendMessage({
        token: keycloak.token,
        topic: '/private/rfq/list',
        message: { type: 'list_rfq', oid: uuid() + '_list_rfq' },
        tabIndex,
      })
    })
  }, [])

  return rfqList ? (
    <div>{rfqList.rfqs && <Table columns={columns} rows={rfqList.rfqs} options={options} />}</div>
  ) : (
    <div className={style.loadingTable}>
      <CircularProgress className={style.loadingIcon} size={50} />
    </div>
  )
}

const mapStateToProps = ({ socket: { rfqList } }) => ({ rfqList })
const mapDispatchToProps = { socketSendMessage, subscribeForRFQ }

export default connect(mapStateToProps, mapDispatchToProps)(RfqList)
