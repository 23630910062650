import styled from '@emotion/styled'

export const Expander = styled.div`
  height: 40px;
  align-items: center;
  display: flex;
  flex: 1;
  cursor: pointer;

  .expanderIndicator {
    color: black;
    display: inline-block;
    margin-left: 10px;
  }

  .expanderText {
    color: ${props => props.theme.colors.text};
    display: inline-block;
    margin-left: 10px;
  }
`
