const defaultModalMessage = {
  open: false,
  type: 'CRITICAL',
}

const defaultState = {
  notifications: [],
  notificationModalMessage: defaultModalMessage,
}

const existNotification = (notification, notifications) => {
  return notifications.filter(n => JSON.stringify(n) === JSON.stringify(notification)).length > 0
}

export default (state = defaultState, { type, payload } = {}) => {
  switch (type) {
    case 'NOTIFICATION_UPDATE':
      const notifications = !existNotification(payload, state.notifications)
        ? [...state.notifications, payload]
        : [...state.notifications]
      return {
        ...state,
        notifications,
        notificationModalMessage: {
          ...state.notificationModalMessage,
          open: true,
        },
      }

    case 'CLOSE_NOTIFICATION_DIALOG':
      const newNotifications = [...state.notifications]
      newNotifications.shift()
      if (newNotifications.length === 0) {
        return {
          ...state,
          notifications: [],
          notificationModalMessage: defaultModalMessage,
        }
      }
      return {
        ...state,
        notifications: newNotifications,
      }
    default:
      return {
        ...state,
      }
  }
}
