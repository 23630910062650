/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import { connect } from 'react-redux'
import Close from '@material-ui/icons/Close'
import React, { useState, useEffect, useContext, useMemo } from 'react'
import { ClickAwayListener, Paper } from '@material-ui/core'

import * as style from './style'
import PriceButton from '../PriceButton'
import { setPos } from '../NewSpot/style'
import PriceMovement from '../PriceMovement'
import AmountToOperate from '../AmountToOperate'
import InstrumentSearch from '../InstrumentSearch'
import { I18nContext } from '../../containers/i18n'
import { fmtDateShort } from '../../common/utilities'
import { subscribeSymbolDynamic, unsubscribeSymbolDynamic } from '../../actions/marketData'
import {
  deleteCurrency,
  changeToViewMode,
  reorderCurrencies,
  resetCurrency,
} from '../../actions/workspace'
import MarketDepthPanel from '../MarketDepthPanel'
import { hydratedInstrumentSelector } from '../../selectors/instruments'
import { createSelector } from 'reselect'

const spotSubtitle = (instrument, t) => {
  const subType = t(instrument?.securitySubType)
  const type = !subType ? t(instrument?.securityType) : ''
  const expiryDate = fmtDateShort(instrument?.expiryDate)
  return `${subType || ''} ${type} ${expiryDate}`
}

const safePrice = (currency, key, decimals) =>
  currency?.[key] ? Number(currency[key]).toFixed(decimals) : ''

let oldIndex = -1
let oldTabIndex = -1

const handleDragStart = (e, index, tabIndex) => {
  e.dataTransfer.setData('text/plain', '')
  e.stopPropagation()
  oldIndex = index
  oldTabIndex = tabIndex
}

const SpotView = ({
  markets,
  changeToViewMode,
  marketData,
  currency,
  deleteCurrency,
  resetCurrency,
  instrument,
  isDragging,
  reorderCurrencies,
  spotIndex,
  tabIndex,
  keycloak,
  tab,
  subscribeSymbolDynamic,
  unsubscribeSymbolDynamic,
  withDepth = false,
}) => {
  const { t } = useContext(I18nContext)
  const [{ x, y }, setPosition] = useState({ x: 0, y: 0 })
  const isDeleting = tab.deleting || false
  const askPriceFixed = useMemo(
    () =>
      safePrice(withDepth ? marketData?.['0'] : marketData, 'askPrice', instrument?.priceDecimals),
    [marketData, instrument?.priceDecimals, withDepth]
  )

  const bidPriceFixed = useMemo(
    () =>
      safePrice(withDepth ? marketData?.['0'] : marketData, 'bidPrice', instrument?.priceDecimals),
    [marketData, instrument?.priceDecimals, withDepth]
  )

  useEffect(() => {
    if (!isDeleting) {
      if (instrument) {
        subscribeSymbolDynamic({ instrument, subscriptionType: withDepth ? 'book' : 'tick' })
      }
    }
    resetCurrency(spotIndex, tabIndex, false)
  }, [instrument?.id])

  useEffect(() => {
    console.error('Status changed')
  }, [currency.status])

  const onSelectInstrument = (newInstrument, tabIndex, spotIndex) => {
    if (instrument && newInstrument !== instrument) {
      unsubscribeSymbolDynamic({ instrument, subscriptionType: withDepth ? 'book' : 'tick' })
    }
    changeToViewMode(newInstrument, spotIndex, tabIndex, 'VIEW')
    if (newInstrument !== instrument) {
      subscribeSymbolDynamic({
        instrument: newInstrument,
        subscriptionType: withDepth ? 'book' : 'tick',
      })
    }
  }

  const handleDrop = (e, index, tabIndex) => {
    e.preventDefault()
    tabIndex === oldTabIndex &&
      reorderCurrencies({
        oldIndex,
        newIndex: index,
        currentTab: tabIndex,
      })
  }

  const { colorMode } = useThemeUI()
  return (
    <>
      <div sx={style.paper}>
        <Paper
          square
          draggable
          elevation={0}
          sx={style.spot}
          style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}
          onDrop={e => handleDrop(e, spotIndex, tabIndex)}
          onDragStart={e => handleDragStart(e, spotIndex, tabIndex)}
          onDragOver={e => e.preventDefault()}
        >
          <div sx={style.mainTitle(colorMode)}>
            <span
              sx={style.close}
              onClick={() => {
                const total = tab.spots.length
                resetCurrency(spotIndex, tabIndex, total - 1 !== spotIndex)
                deleteCurrency(spotIndex, tabIndex)
                unsubscribeSymbolDynamic({
                  instrument,
                  subscriptionType: withDepth ? 'book' : 'tick',
                })
              }}
            >
              <Close sx={{ fontSize: 3 }} />
            </span>
            <span
              sx={style.currency(colorMode)}
              onDoubleClick={e => {
                console.error('Double click!')
                const { top, left } = e.target.offsetParent.getBoundingClientRect()
                setPosition({ y: top + 4, x: left + 70 })
                changeToViewMode(instrument, spotIndex, tabIndex)
              }}
            >
              {instrument?.symbol}
              <br />
              <div sx={style.title}>{spotSubtitle(instrument, t)}</div>
            </span>
          </div>
          <div sx={style.container}>
            <PriceButton
              priceData={bidPriceFixed}
              currency={instrument?.symbol}
              instrument={instrument}
              tabIndex={tabIndex}
              spotIndex={spotIndex}
              keycloak={keycloak}
            />
            <PriceButton
              priceData={askPriceFixed}
              currency={instrument?.symbol}
              instrument={instrument}
              tabIndex={tabIndex}
              spotIndex={spotIndex}
              keycloak={keycloak}
              ask
            />
            <PriceMovement bid={bidPriceFixed} ask={askPriceFixed} instrument={instrument} />
          </div>
          {useMemo(
            () => (
              <div sx={style.amount}>
                <AmountToOperate
                  currencyData={currency}
                  spotIndex={spotIndex}
                  tabIndex={tabIndex}
                  instrument={instrument}
                  markets={markets}
                />
              </div>
            ),
            [currency, spotIndex, tabIndex, instrument]
          )}
          {withDepth ? (
            <div>
              <MarketDepthPanel
                instrument={instrument}
                depth={5}
                currency={instrument?.symbol}
                tabIndex={tabIndex}
                spotIndex={spotIndex}
                keycloak={keycloak}
                instrumentDepthData={marketData}
              />
            </div>
          ) : null}
        </Paper>
      </div>
      {currency.status === 'EDIT' && (
        <div key="2" sx={setPos([x, y])}>
          <ClickAwayListener onClickAway={() => changeToViewMode(instrument, spotIndex, tabIndex)}>
            <InstrumentSearch
              search={currency.value}
              spotIndex={spotIndex}
              tabIndex={tabIndex}
              onSelectInstrument={onSelectInstrument}
            />
          </ClickAwayListener>
        </div>
      )}
    </>
  )
}

const currencySelector = (state, ownProps) =>
  state.workspace.workspaces[state.workspace.currentWorkspace]?.tabs[ownProps.tabIndex]?.spots[
  ownProps.spotIndex
  ]
const tickSelector = state => state.marketData.tick
const bookSelector = state => state.marketData.book
const tabSelector = (state, ownProps) =>
  state.workspace.workspaces[state.workspace.currentWorkspace]?.tabs[ownProps.tabIndex]

const instrumentSelector = (state, ownProps) => {
  const instruments = hydratedInstrumentSelector(state, ownProps)
  const instrumentId = currencySelector(state, ownProps).id
  return instruments[instrumentId]
}

const marketDataSelector = createSelector(
  instrumentSelector,
  bookSelector,
  tickSelector,
  (_, ownProps) => ownProps.withDepth,
  (instrument, books, ticks, withDepth) => {
    if (!instrument) {
      return {}
    }
    const accountId = instrument?.marketNeedsAccount ? instrument.selectedAccountId || '' : ''

    return withDepth
      ? books?.[instrument?.exchangeId]?.[instrument?.symbol]?.[accountId]?.book || {}
      : ticks?.[instrument?.exchangeId]?.[instrument?.symbol]?.[accountId] || {}
  }
)

const mapStateToProps = (state, ownProps) =>
  createSelector(
    instrumentSelector,
    tabSelector,
    marketDataSelector,
    currencySelector,
    (instrument, tab, marketData, currency) => ({
      instrument,
      tab,
      marketData,
      currency,
    })
  )(state, ownProps)

const mapDispatchToProps = {
  deleteCurrency,
  resetCurrency,
  changeToViewMode,
  reorderCurrencies,
  subscribeSymbolDynamic,
  unsubscribeSymbolDynamic,
}

export default connect(mapStateToProps, mapDispatchToProps)(SpotView)
