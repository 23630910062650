import Api from '../common/api'
import config from '../common/config'

export function fetchInstruments(token) {
  const api = new Api({
    baseUrl: config().urls.baseUrl,
    defaultOptions: { headers: { Authorization: `Bearer ${token}` } },
  })
  return {
    type: 'FETCH_INSTRUMENTS',
    payload: api.get('/instruments/v2/instruments'),
  }
}
