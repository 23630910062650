/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as style from './style'
import React, { useState, useEffect, useLayoutEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import Table from './Table'
import { Filter, CustomMenu } from './TableAddons'
import { Settings } from '@material-ui/icons'
import { propEq, reject, isEmpty, remove, insert } from 'ramda'
import { dragableColumns, tranformColumns, getTableId } from './TableUtils'
import { I18nContext } from '../../containers/i18n'

const hasEnoughColumns = ({ colAux, isVisible }) => colAux.length > 1 || isVisible

const changeAccessors = ({
  accessor,
  isVisible,
  columns,
  colAux,
  onColumnsFilter,
  columnsFilter,
  setColAux,
}) => {
  const pred = propEq('accessor', accessor)
  const newCols = isVisible
    ? columns.filter(c => (c.accessor !== accessor ? c : isVisible && c))
    : reject(pred, colAux)
  onColumnsFilter(
    columnsFilter.map(col => ({
      accessor: col.accessor,
      isVisible: col.accessor === accessor ? isVisible : col.isVisible,
    }))
  )
  setColAux(newCols)
}

const TableWrapper = ({
  filterable,
  columns,
  onColumnsFilter,
  columnsFilter,
  filterColumns,
  styleTable = {},
  styleContainer = {},
  minRows = 3,
  rows,
  defaultSorted,
  defaultFiltered,
  onChangeDefaultFiltered,
  tableTitle,
  disabled = false,
  tabIndex,
  ...props
}) => {
  const { t } = useContext(I18nContext)

  // State & Constants
  const [ownProps, setOwnProps] = useState({
    filterable: false,
    filterColumns: false,
    openAccessor: false,
    defaultFilters: [],
  })

  const [position, setPosition] = useState({})
  const [open, setOpen] = useState(false)
  const [colAux, setColAux] = useState(columns)
  const columnsFilterPlus =
    columnsFilter && columns && !isEmpty(columns)
      ? columnsFilter
          .map(colF => {
            const col = columns.find(c => c.accessor === colF.accessor)
            return { ...colF, header: col?.headerHidden || col?.Header }
          })
          .filter(c => c.header)
      : []

  // Handlers
  const handleClickFilter = isFilterable =>
    setOwnProps({
      ...ownProps,
      filterable: isFilterable,
    })

  const handleChangeFilter = filters => {
    setOwnProps({
      ...ownProps,
      defaultFilters: filters,
    })
    onChangeDefaultFiltered(filters)
  }

  const handleClickAccessor = e => {
    const { top, left } = e.currentTarget.getBoundingClientRect()
    setPosition({ top, left })
    setOpen(true)
  }

  const handlerChangeAccessors = (accessor, isVisible) => {
    if (hasEnoughColumns({ colAux, isVisible })) {
      changeAccessors({
        accessor,
        isVisible,
        columns,
        colAux,
        onColumnsFilter,
        columnsFilter,
        setColAux,
      })
    }
  }

  const handleClose = () => setOpen(false)

  const onDropColumn = (dragIndex, dropIndex) => {
    const removeColAux = remove(dragIndex, 1, colAux)
    const uploadColAux = insert(dropIndex, colAux[dragIndex], removeColAux)
    const ori = columnsFilter.findIndex(col => col.accessor === colAux[dragIndex].accessor)
    const dest = columnsFilter.findIndex(col => col.accessor === colAux[dropIndex].accessor)
    const removeColumnsFilter = remove(ori, 1, columnsFilter)
    const uploadColumnsFilter = insert(dest, columnsFilter[ori], removeColumnsFilter)
    onColumnsFilter(uploadColumnsFilter)
    setColAux(uploadColAux)
  }

  // UseEffects
  useEffect(() => {
    const [transformFlag, columnsTransform] = tranformColumns(columns, columnsFilter)
    transformFlag
      ? setColAux(columnsTransform)
      : dragableColumns({ colAux, tabIndex, onDropColumn })
  }, [columns])

  useLayoutEffect(() => {
    setTimeout(() => dragableColumns({ colAux, tabIndex, onDropColumn }), 200)
  }, [colAux])

  return (
    <div
      data-test-id={getTableId(tabIndex)}
      id={getTableId(tabIndex)}
      sx={{ flex: 'auto', ...styleContainer }}
    >
      <div sx={{ display: 'flex' }}>
        {tableTitle && <span sx={style.tableTitle}>{t(tableTitle)}</span>}
        {filterable && <Filter onClick={handleClickFilter} />}
        {filterColumns && (
          <>
            <Settings
              onMouseDown={e => handleClickAccessor(e)}
              sx={{
                ...style.settings,
                backgroundColor: open ? 'accent' : 'muted',
              }}
            />
            <CustomMenu
              position={position}
              columns={columnsFilterPlus}
              colAux={colAux}
              open={open}
              fnClose={handleClose}
              fnChangeAccessors={handlerChangeAccessors}
            />
          </>
        )}
      </div>
      <Table
        onChangeFilter={handleChangeFilter}
        styleTable={styleTable}
        minRows={minRows}
        columns={colAux}
        rows={rows}
        defaultSorted={defaultSorted}
        defaultFiltered={defaultFiltered}
        disabled={disabled}
        {...props}
        {...ownProps}
      />
    </div>
  )
}

TableWrapper.propTypes = {
  addons: PropTypes.array,
  style: PropTypes.object,
  minRows: PropTypes.number,
}

export default TableWrapper
