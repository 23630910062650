import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'theme-ui'
import { MuiThemeProvider } from '@material-ui/core'

import '../style/default.css'
import store from './store'
import App from './containers/App'
import theme from './containers/theme'
import muiTheme from './containers/MaterialTheme'
import { I18nContextProvider } from './containers/i18n'
import PermissionsProvider from './containers/PermissionsProvider'

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={muiTheme}>
        <PermissionsProvider>
          <I18nContextProvider>
            <App />
          </I18nContextProvider>
        </PermissionsProvider>
      </MuiThemeProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('main')
)
