/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import React, { useContext } from 'react'
import { Checkbox, MenuItem, ListItemText, Menu } from '@material-ui/core'
import * as style from './style'
import { I18nContext } from '../../../containers/i18n'

const CustomMenu = ({ position, columns, colAux, open, fnClose, fnChangeAccessors }) => {
  const { t } = useContext(I18nContext)
  const { theme } = useThemeUI()

  return (
    <Menu
      PaperProps={{
        style: {
          maxHeight: 315,
          left: +position.left,
          top: +position.top + 34,
          borderRadius: 0,
          boxShadow: 'none',
          scrollbarWidth: 'thin',
          scrollbarColor: '#789 white',
        },
      }}
      anchorReference="none"
      disableAutoFocusItem
      transitionDuration={100}
      MenuListProps={{
        style: {
          padding: 0,
        },
      }}
      open={open}
      onClose={() => {
        fnClose()
      }}
    >
      {columns.map(c => (
        <MenuItem
          key={c.accessor}
          value={c.accessor}
          onClick={() => fnChangeAccessors(c.accessor, !c.isVisible)}
          disableGutters
          sx={style.menu}
        >
          <>
            <Checkbox
              checked={c.isVisible}
              style={{
                color: theme.colors.checkbox,
              }}
            />
            <ListItemText
              primary={t(c.header)}
              primaryTypographyProps={{
                style: { color: theme.colors.text, textTransform: 'capitalize' },
              }}
              sx={style.listItem}
            />
          </>
        </MenuItem>
      ))}
    </Menu>
  )
}

export default CustomMenu
