import React from 'react'
import { connect } from 'react-redux'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import style from './style.css'
import { socketSendMessage } from '../../../actions/socket'
import CustomButton from '../../CustomButton'

const sides = ['BUY', 'SELL', 'UNDISCLOSED']

class RfqForm extends React.Component {
  constructor(props) {
    super(props)
  }

  UNSAFE_componentWillMount() {
    this.checkIfDataIsComplete()
    this.setState({ disabledData: false })
  }

  state = {
    side: '',
    btnRFQ: true,
    endRFQ: false,
  }

  checkIfDataIsComplete() {
    !Object.values(this.props.rfqData).some(x => x === null || x === '')
      ? this.setState({ create: false })
      : this.setState({ create: true })
  }

  handleChangeRQ = (txt, event) => {
    this.props.rfqData[txt] = event.target.value
    this.checkIfDataIsComplete()
  }

  handlerRFW = () => {
    const { keycloak, socketSendMessage, tabIndex, rfqId, oid, rfqData } = this.props
    this.props.keycloak.updateToken(30).success(() => {
      if (!this.state.disabledData) {
        socketSendMessage({
          token: keycloak.token,
          topic: '/private/rfq/create',
          message: { ...rfqData, oid: oid },
          tabIndex: tabIndex,
        })
        this.setState({ create: false })
        this.setState({ disabledData: true })
      } else {
        socketSendMessage({
          token: keycloak.token,
          topic: 'private/rfq/end',
          message: { type: 'end_rfq', oid: oid, rfqId: rfqId },
        })
        this.setState({ disabledData: false })
        this.setState({ create: false })
      }
    })
  }

  render() {
    const { literals = {}, instruments, rfqData } = this.props
    const selectedPair = instruments.find(p => p.symbol === rfqData.pair)
    const currencies = selectedPair
      ? [selectedPair.quoteCurrency, selectedPair.settlementCurrency]
      : [instruments[0].quoteCurrency, instruments[0].settlementCurrency]

    return (
      <div className={style.container}>
        <Paper className={style.paper} elevation={0} square>
          <div>
            <FormControl className={style.formControl}>
              <InputLabel htmlFor="pair" className={style.labelFormat}>
                {literals.pair}
              </InputLabel>
              <Select
                value={selectedPair ? selectedPair.symbol : instruments[0].symbol}
                disabled={this.state.disabledData}
                className={style.inputFormat}
                onChange={event => {
                  rfqData.currency = ''
                  this.handleChangeRQ('pair', event)
                }}
              >
                {instruments.map(p => (
                  <MenuItem value={p.symbol} key={p.symbol}>
                    {p.symbol}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={style.formControl}>
              <InputLabel htmlFor="tenor" className={style.labelFormat}>
                {literals.tenor}
              </InputLabel>
              <Input
                id="tenor"
                value={rfqData.tenor}
                onChange={event => this.handleChangeRQ('tenor', event)}
                disabled={this.state.disabledData}
                className={style.inputFormat}
              />
            </FormControl>
            <FormControl className={style.formControl}>
              <InputLabel htmlFor="amount" className={style.labelFormat}>
                {literals.amount}
              </InputLabel>
              <Input
                id="amount"
                value={rfqData.amount}
                onChange={event => this.handleChangeRQ('amount', event)}
                disabled={this.state.disabledData}
                className={style.inputFormat}
              />
            </FormControl>
            <FormControl className={style.formControl}>
              <InputLabel htmlFor="currency" className={style.labelFormat}>
                {literals.currency}
              </InputLabel>
              <Select
                value={rfqData.currency}
                onChange={event => this.handleChangeRQ('currency', event)}
                disabled={this.state.disabledData}
                className={style.inputFormat}
              >
                {currencies.map(c => (
                  <MenuItem value={c} key={c}>
                    {c}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={style.formControl}>
              <InputLabel htmlFor="side" className={style.labelFormat}>
                {literals.side}
              </InputLabel>
              <Select
                value={rfqData.side}
                onChange={event => this.handleChangeRQ('side', event)}
                className={style.inputFormat}
                disabled={this.state.disabledData}
                inputProps={{
                  name: 'side',
                  id: 'side',
                }}
              >
                {sides.map(side => (
                  <MenuItem value={side} key={side}>
                    {side}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <CustomButton
            style={{ margin: '20px' }}
            disabled={this.state.create}
            text={!this.state.disabledData ? literals.createRFQ : literals.endRFQ}
            type={this.state.action === 'buy' ? 'buy' : ''}
            size="medium"
            onClick={() => this.handlerRFW()}
          />
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = ({ instruments: { data: instruments }, socket }) => ({
  instruments,
  socket,
})

const mapDispatchToProps = {
  socketSendMessage,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RfqForm)
