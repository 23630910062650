// Todo Cambiar si es necesario cuando se implementen los instrumentos SPOT
/* Oculta el botón de trading capacity y el ork si el instrumentType
contiene uno de los tipos listados en "notVisible" */
const notVisible = ['SPOT']

const OrkVisible = ({ children, instrumentType = '' }) => {
  const isVisible = !notVisible.includes(instrumentType)

  return isVisible ? children : null
}

export default OrkVisible
