export const header = {
  padding: '60px',
}

const headerFontFamily = 'Oswald,Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif'

export const content = {
  fontFamily: 'Heebo, Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif',
  color: '#626262',
  textAlign: 'justify',
  padding: '20px',
  width: '60%',
  margin: 'auto',
  marginBottom: '2em',

  '& h2': {
    fontFamily: headerFontFamily,
    color: '#121A27',
    fontSize: '3em',
    marginTop: '0.83em',
    marginBottom: '0.83em',
    textTransform: 'uppercase',
  },

  '& h3': {
    fontFamily: headerFontFamily,
    color: '#121A27',
    fontSize: '2em',
    marginTop: '1em',
    marginBottom: '1em',
    textTransform: 'uppercase',
  },

  '& h4': {
    fontFamily: headerFontFamily,
    color: '#121A27',
    fontSize: '1.5em',
    marginTop: '1.33em',
    marginBottom: '1.33em',
    textTransform: 'uppercase',
  },

  '& p': {
    marginTop: '1em',
    marginBottom: '1em',
  },

  '& a': {
    color: '#002F5F',

    '&:hover': {
      color: '#00acc8',
    },
  },
}
