import styled from '@emotion/styled'
import FormLabel from '@material-ui/core/FormLabel'

import { DialogContent } from '../Dialog'

export const Content = styled(DialogContent)`
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  min-height: max-content;
`

export const FieldContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const Field = styled.div`
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-bottom: 10px;
  flex: 1 1 30%;
  box-shadow: 0 2px rgba(0, 0, 0, 0.3);
`
export const Label = styled(FormLabel)`
  text-align: initial;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
`

export const Text = styled.div`
  white-space: normal;
  display: flex;
  justify-content: flex-end;
  text-align: right;
`
