import { isEqual } from 'lodash'
import { find, propEq } from 'ramda'

import { createSelectorCreator, defaultMemoize } from 'reselect'

const selectMarkets = state => state.markets.data
const selectCurrentAccount = state => state.workspace.currentAccount
const selectAccountGroups = state => state.workspace.accountGroups
const selectAccounts = state => state.accounts.data

// create a "selector creator" that uses lodash.isequal instead of ===
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual)

export const accountsSelector = createDeepEqualSelector(
  selectCurrentAccount,
  selectAccountGroups,
  selectAccounts,
  selectMarkets,
  (currentAccount, accountGroups, accounts, markets) => {
    console.warn('Selector executing')

    console.warn('Current Account', currentAccount)
    console.warn('accountGroups', accountGroups)
    console.warn('accounts', accounts)
    console.warn('markets', markets)

    const isGroup = currentAccount?.type === 'group'
    const group = isGroup && find(propEq('id', currentAccount?.id))(accountGroups)
    console.warn('group', group)

    if (!accounts || Object.keys(accounts).length === 0) {
      return null
    }

    return Object.keys(markets).reduce((acc, id) => {
      const account = isGroup
        ? accounts[group?.accounts[id]?.value]
        : accounts[currentAccount?.id]?.exchangeId === id
          ? accounts[currentAccount?.id]
          : null

      const result = {
        market: id,
        account: account?.id,
        accountName: account?.name,
        fixAccount: account?.fixAccount,
        groupName: isGroup ? group.name : null,
      }

      return { ...acc, [id]: { ...result } }
    }, {})

  }
)

