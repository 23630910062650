import React from 'react'
import { buildURI } from './core'
import { defaultProps as commonDefaultProps, propTypes as commonPropTypes } from './metaProps'
import { format } from 'date-fns'

const defaultProps = {
  target: '_blank',
}

class CSVDownload extends React.Component {
  static defaultProps = Object.assign(commonDefaultProps, defaultProps)

  static propTypes = commonPropTypes

  constructor(props) {
    super(props)
    this.state = {}
  }

  buildURI() {
    return buildURI(...arguments)
  }

  componentDidMount() {
    const {
      data,
      headers,
      separator,
      enclosingCharacter,
      uFEFF,
      fileName = 'export',
      addDateToFileName = true,
    } = this.props
    const csvData = typeof data === 'function' ? data() : data
    const csvHeaders = typeof headers === 'function' ? headers() : data

    const fileNameWithExtension = addDateToFileName
      ? fileName + '_' + format(new Date(), 'yyyyMMddHHmmss') + '.csv'
      : fileName + '.csv'
    const url = this.buildURI(csvData, uFEFF, csvHeaders, separator, enclosingCharacter)

    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileNameWithExtension)
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  render() {
    return null
  }
}

export default CSVDownload
