import React from 'react'

import Icon from '../Icon'
import { loading, dark, bm, igf } from './style.css'
import { useColorMode } from 'theme-ui'

export default () => {
  const [theme] = useColorMode()
  const colorLoading = () => {
    if (theme === 'bm') {
      return bm
    } else if (theme === 'igf') {
      return igf
    } else {
      return dark
    }
  }

  return (
    <div className={`${loading} ${colorLoading()}`}>
      <Icon name="spinner" size="3x" pulse fixedWidth />
    </div>
  )
}
