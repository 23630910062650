import React from 'react'

import { Expander as StyledExpander } from './Expander'
import { ExternalTable as StyledExternalTable } from './ExternalTable'
import { InternalTable as StyledInternalTable } from './InternalTable'

export function ExternalTable({ theme, colorMode, children }) {
  return (
    <StyledExternalTable theme={theme} colorMode={colorMode}>
      {children}
    </StyledExternalTable>
  )
}

export function InternalTable({ theme, children }) {
  return <StyledInternalTable theme={theme}>{children}</StyledInternalTable>
}

export function Expander({ expanded = false, text = '', onClick = () => null, theme }) {
  return (
    <StyledExpander theme={theme} onClick={() => onClick()}>
      <div className="expanderIndicator">{expanded ? '▼' : '►'}</div>
      <div className="expanderText">{text}</div>
    </StyledExpander>
  )
}
