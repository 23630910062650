export const input = {
  bg: 'primary',
  border: 'none',
  color: 'text',
  fontSize: 4,
  height: '28px',
  lineHeight: '1.1em',
  px: '14px',
  position: 'relative',
  width: '70%',
  '&:hover': {
    bg: 'secondary',
  },
}

export const container = {
  width: '30%',
  display: 'flex',
  justifyContent: 'space-between',
}

export const price = {
  width: '50%',
  border: 'none',
  bg: 'primary',
  ml: '3px',
  color: 'text',
  '&:hover': {
    bg: 'secondary',
  },
  '&:disabled': {
    bg: 'disabled',
    color: 'noEdit',
    cursor: 'not-allowed',
  },
}

export const selector = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'space-between',
  '*': { ':focus': { outline: 'none' } },
}

// export const currency = {
//   bg: 'primary',
//   border: 'none',
//   color: 'text',
//   // fontSize: 4,
//   height: '28px',
//   lineHeight: '1.1em',
//   px: '14px',
//   position: 'relative',
//   width: '30%',
//   '&:hover': {
//     bg: 'secondary',
//   },
//   ml: '3px',

//   // '&:disabled': {
//   //   bg: 'disabled',
//   //   color: 'noEdit',
//   //   cursor: 'not-allowed',
//   // },
// }


export const mediumSize = {
  height: '36px',
  fontSize: '2',
}

export const currency = {
  ml: '5px',
  fontSize: '2',
  bg: 'primary',
  color: 'text',
  border: 'none',
  '&:hover': {
    bg: 'secondary',
  },
  '&:disabled': {
    border: '10px solid',
    bg: 'disabled',
    //color: 'noEdit',
    cursor: 'not-allowed',
  },
}



export const inputBaseDisabled = {
  ml: '5px',
  bg: 'disabled',
  '&:hover': {
    bg: 'disabled',
    cursor: 'not-allowed !important',
  },
  '& div': {
    color: 'transparent',
    cursor: 'not-allowed !important',
  },
  '& span': {
    color: 'text',
    cursor: 'not-allowed !important',
  },
  cursor: 'not-allowed !important',
}
