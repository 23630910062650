/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useEffect, useContext, useMemo } from 'react'
import { connect } from 'react-redux'

import { PanelTable } from '../Table'
import * as style from './style'
import { columnsHead, defaultSorted } from './columnsHead'
import 'react-table/react-table.css'
import ModalOrderView from '../ModalOrderView'
import ModalOrderEdit from '../ModalOrderEdit'
import debounceRender from 'react-debounce-render'
import {
  getOrderList,
  showOrderDialog,
  closeOrderDialog,
  cancelOrderWithConfirm,
  replaceOrder,
} from '../../actions/orders'
import { fmtDateLong, fmtDateLongFromyyyMMdd } from '../../common/utilities'
import { I18nContext } from '../../containers/i18n'

const OrderList = ({
  cancelOrderWithConfirm,
  closeOrderDialog,
  getOrderList,
  keycloak,
  modal,
  orderList,
  updates,
  replaceOrder,
  showOrderDialog,
  tabIndex,
  instruments,
  accounts,
}) => {
  const { t } = useContext(I18nContext)

  const cancelOrder = data => {
    const accountFromOperationAccount =
      accounts[data.operationAccount] &&
      `${accounts[data.operationAccount].name} (${accounts[data.operationAccount].fixAccount})`
    const payload = {
      origClOrdId: data.clientOrderId,
      symbol: data.symbol,
      exchangeId: data.exchangeId,
      title: accountFromOperationAccount
        ? `${t('account')} ${accountFromOperationAccount}`
        : `${t('account')} ${order.operationAccount}`,
      orderInfo: data,
      order: true,
      side: data.side,
      operationAccount: data.operationAccount,
      selectedAccount: accounts[data.operationAccount] || order.operationAccount,
    }
    cancelOrderWithConfirm(payload, keycloak.token)
  }

  //Handle actions cell

  const handleCancel = order => {
    const accountFromOperationAccount =
      accounts[order.operationAccount] &&
      `${accounts[order.operationAccount].name} (${accounts[order.operationAccount].fixAccount})`
    const payload = {
      origClOrdId: order.clientOrderId,
      symbol: order.symbol,
      exchangeId: order.exchangeId,
      title: accountFromOperationAccount
        ? `${t('account')} ${accountFromOperationAccount}`
        : `${t('account')} ${order.operationAccount}`,
      orderInfo: order,
      order: true,
      side: order.side,
      operationAccount: order.operationAccount,
      selectedAccount: accounts[order.operationAccount] || order.operationAccount,
    }
    cancelOrderWithConfirm(payload, keycloak.token)
  }

  const handleModal = (order, viewMode, status) =>
    showOrderDialog({
      data: order,
      title: t('orderDetails'),
      viewMode,
      status,
    })

  const handleEditModal = (order, viewMode, status) =>
    showOrderDialog({
      data: order,
      viewMode,
      status,
    })

  useEffect(() => {
    getOrderList(keycloak.token)
  }, [])

  const memoizedTableData = useMemo(() => {
    // console.warn('Mapping order list')
    const result = Object.values(orderList).map(o => ({
      ...o,
      instrument: instruments[o.instrumentId],
      orderId: o.orderId && o.orderId !== 'NONE' ? o.orderId : '',
      formatDate: o.date && fmtDateLong(o.date),
      formatUpdateDate: (o.updateDate && fmtDateLong(o.updateDate)) || '',
      formatExpirationDate: o.expirationDate && fmtDateLongFromyyyMMdd(o.expirationDate),
      formatQuantity: !isNaN(o.quantity) ? +o.quantity : '',
      formatCumQuantity: !isNaN(o.cumQuantity) ? +o.cumQuantity : '',
      formatRemainingQuantity: !isNaN(o.remainingQuantity) ? +o.remainingQuantity : '',
      formatPrice: +o.price || '',
      formatAveragePrice: +o.averagePrice || '',
      formatPendingQuantity: !isNaN(o.pendingQuantity) ? +o.pendingQuantity : '',
      formatAccount: accounts[o.operationAccount]?.name || o.operationAccount || '',
      handleModal,
      handleEditModal,
      handleCancel,
    }))
    // console.warn('Mapped')
    // result.sort((a, b) => {
    //   const date1 = new Date(a.date).getTime()
    //   const date2 = new Date(b.date).getTime()
    //   if (date1 === date2) {
    //     return 0;
    //   } else if (date1 > date2) {
    //     return -1;
    //   } else {
    //     return 1;
    //   }

    //   // a.date?.getTime() === b.date?.getTime() ? 0 : a.date?.getTime() >= b.date?.getTime() ? -1 : 1
    // })
    return result
  }, [orderList, updates, accounts])

  // console.warn("Rendering")


  //TODO: Hacer que no se definan los dos modales a la vez?. Definir solo el que se va a abrir?
  return (
    <PanelTable
      rows={memoizedTableData}
      getColumns={() => columnsHead(t)}
      tabIndex={tabIndex}
      defaultSorted={defaultSorted}
    >
      {modal.open ? (
        <>
          <ModalOrderEdit
            {...modal}
            sx={style.modal}
            onClose={closeOrderDialog}
            onSave={replaceOrder}
            open={modal.open && !modal.viewMode}
            token={keycloak.token}
          />
          <ModalOrderView
            {...modal}
            sx={style.modal}
            open={modal.open && modal.viewMode}
            onClose={closeOrderDialog}
            onCancel={cancelOrder}
            onModify={showOrderDialog}
            token={keycloak.token}
          />
        </>
      ) : null}
    </PanelTable>
  )
}

const mapStateToProps = ({
  order: { orderList, modal, updates },
  accounts: { data: accounts },
  instruments: { data: instruments },
}) => ({
  orderList,
  modal,
  accounts,
  updates,
  instruments,
})

const mapDispatchToProps = {
  getOrderList,
  showOrderDialog,
  closeOrderDialog,
  cancelOrderWithConfirm,
  replaceOrder,
}

const debouncedComponent = debounceRender(OrderList, 150)

export default connect(mapStateToProps, mapDispatchToProps)(debouncedComponent)
