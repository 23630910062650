export const add = {
  backgroundColor: 'primary',
  color: 'text',
  padding: '5px 1.5px 6px 1.5px;',
  cursor: 'pointer',
}

export const tabIcons = {
  paddingTop: '1px',
  marginLeft: '5px',
  width: '17px',
  display: 'flex',
  paddingRight: '5px',
  paddingLeft: '5px',
  zIndex: '999',
}

export const tabIconsOpen = {
  backgroundColor: 'accent',
  height: '30px',
}

export const infoContent = {
  bg: 'primary',
  color: 'text',
  paddingLeft: '15px',
  marginLeft: '-13px',
  marginRight: '-29px',
  paddingRight: '28px',
  paddingTop: '3px',
  height: '29px',
  display: 'flex',
  flexWrap: 'nowrap',
}
