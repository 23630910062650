/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'

import { Input } from './style'
import { DialogRowElement, Label } from '../Dialog'

const DialogInput = ({ label, onChange, defaultValue = '0', labelProps, ...props }) => (
  <DialogRowElement {...props}>
    <Label style={{ display: 'inline-table' }} {...labelProps}>
      {label}
    </Label>
    <Input {...props} onChange={e => onChange(e.target.value)} />
  </DialogRowElement>
)

export default DialogInput
