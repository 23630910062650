export const drawerOpen = {
  backgroundColor: 'drawerOpen',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}

export const drawerHide = {
  backgroundColor: 'drawerHide',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}

export const text = {
  color: 'drawerElements',
}

export const tabWorkspace = {
  width: '100%',
  position: 'relative',
  '&:hover': {
    bg: 'drawerHover',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
}

export const workspaceClose = {
  cursor: 'pointer',
  position: 'absolute',
  right: '13px',
  top: '14.5px',
  color: 'drawerElements',
  '&:hover': {
    color: 'drawerElements',
  },
}

export const workspaceList = {
  display: 'inline',
  padding: '0',
}

export const sidebarBottom = {
  display: 'inline',
  alignSelf: 'flex-end',
  paddingLeft: '30px !important',
  marginTop: 'auto',
  width: '100%',

  '& ul': {
    listStyleType: 'none',

    '& li': {
      padding: '0 10px 10px 25px',

      '& a': {
        textDecoration: 'none',
        color: 'drawerElements',

        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
}

export const textInput = {
  color: 'drawerElements',
  fontSize: '1em',
  textAlign: 'left',
  textTransform: 'none',
  maxWidth: '120px',
  border: 'none',
  backgroundColor: 'rgba(0, 0, 0, 0)',
  boxSizing: 'border-box',
  '&:hover': {
    borderBottom: 'solid 1px',
    borderBottomColor: 'drawerElements',
  },
}

export const listItem = {
  width: '80%',
}

const workspaceIcon = {
  width: '25px',
  height: '25px',
}

export const workspaceIconActive = {
  ...workspaceIcon,
  fill: 'drawerElements',
}

export const workspaceIconDeactive = {
  ...workspaceIcon,
  fill: 'drawerElementsDeactivate',
}
