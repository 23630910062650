import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import CustomButton from '../CustomButton'
import { Field, Label, Content, errorLabel, warningLabel, infoLabel } from './style'
import { Dialog, DialogTitle, DialogActions } from '../Dialog'
import { I18nContext } from '../../containers/i18n'
import { useThemeUI } from 'theme-ui'

export const modalMessageTypesEnum = {
  ERROR: { value: 'ERROR', style: errorLabel, titleLiteral: 'error' },
  WARNING: { value: 'WARNING', style: warningLabel, titleLiteral: 'warning' },
  INFO: { value: 'INFO', style: infoLabel, titleLiteral: 'info' },
}
const ModalMessage = ({
  open,
  onClose,
  title,
  message,
  type = 'ERROR',
  onConfirm,
  closeLiteral = 'close',
  cancelLiteral = 'cancel',
  confirmLiteral = 'confirm',
}) => {
  const { t } = useContext(I18nContext)
  const typeEnum = modalMessageTypesEnum[type] || modalMessageTypesEnum.ERROR
  const { colorMode } = useThemeUI()

  const handleOnConfirm = () => {
    onConfirm()
    onClose()
  }

  return (
    open && (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle id="alert-dialog-title" disableTypography colormode={colorMode}>
          {title || t(typeEnum.titleLiteral).toUpperCase()}
        </DialogTitle>
        <Content>
          <Field>
            {typeof message === 'string' ? (
              <Label style={typeEnum.style}>{message}</Label>
            ) : (
              <div style={typeEnum.style}>{message}</div>
            )}
          </Field>
        </Content>
        <DialogActions>
          {onConfirm && (
            <CustomButton type="primary" width="27%" noMargin onClick={() => handleOnConfirm()}>
              {t(confirmLiteral)}
            </CustomButton>
          )}
          <CustomButton type="primary" width="27%" noMargin onClick={onClose}>
            {onConfirm ? t(cancelLiteral) : t(closeLiteral)}
          </CustomButton>
        </DialogActions>
      </Dialog>
    )
  )
}

ModalMessage.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  type: PropTypes.oneOf(Object.values(modalMessageTypesEnum).map(e => e.value)),
}

export default ModalMessage
