/** @jsx jsx */
import { jsx } from 'theme-ui'
import { connect } from 'react-redux'
import React, { useContext, useState } from 'react'
import * as style from './style'
import { I18nContext } from '../../containers/i18n'
import { InputBase, Typography } from '@material-ui/core'
import { allowOnlyNumbers } from '../../common/utilities'
import { changeTradingOrk } from '../../actions/workspace'

const SpotOrk = ({ label, type, tabIndex, spotIndex, data, changeTradingOrk }) => {
  const { t } = useContext(I18nContext)
  const [inputValue, setInputValue] = useState(data ? data : '')

  return (
    <>
      <Typography sx={{ ...style.orkTipography }}>{t(label)}</Typography>
      <InputBase
        inputProps={{
          maxLength: '10',
        }}
        sx={{
          ...style.input,
          ...style.inputBase,
        }}
        onBlur={e =>
          changeTradingOrk({
            [type]: e.target.value,
            spotIndex,
            tabIndex,
          })
        }
        onChange={e => setInputValue(e.target.value)}
        value={inputValue ? inputValue : ''}
        onPaste={allowOnlyNumbers}
        onKeyPress={allowOnlyNumbers}
      />
    </>
  )
}

const mapDispatchToProps = {
  changeTradingOrk,
}

export default connect(null, mapDispatchToProps)(SpotOrk)
