/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as style from './style'
import { useState, useRef, useContext } from 'react'
import { connect } from 'react-redux'
import Fuse from 'fuse.js'
import Search from '@material-ui/icons/Search'
import { InputAdornment, MenuList, ClickAwayListener, OutlinedInput } from '@material-ui/core'
import { CustomSelect, MenuItem } from '../../common/utilities'
import { I18nContext } from '../../containers/i18n'
import { createSelector } from 'reselect'
import { hydratedInstrumentSelector } from '../../selectors/instruments'

const mt = { marginTop: '9px' }

const options = {
  shouldSort: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: [
    { name: 'symbol', weight: 0.5 },
    { name: 'name', weight: 0.3 },
    { name: 'description', weight: 0.2 },
  ],
}

const handleChangeType = ({ value, setType, instruments, setFilteredData, fuse }) => {
  setType(value)
  const filtered =
    value === 'ALL'
      ? instruments
      : instruments.filter(
        instrument =>
          instrument?.securityType +
          (instrument?.securitySubType ? `_${instrument?.securitySubType}` : '') ===
          value
      )

  setFilteredData(filtered)
  fuse.setCollection(filtered)
}

const handleSearchFuse = ({
  filter,
  setFilterSymbol,
  setFilteredData,
  instruments,
  type,
  fuse,
}) => {
  setFilterSymbol(filter)

  const filtered = () => {
    if (filter) {
      return fuse.search(filter)
    } else {
      return instruments.filter(instrument =>
        type !== 'ALL'
          ? instrument?.securityType +
          (instrument?.securitySubType ? `_${instrument?.securitySubType}` : '') ===
          type
          : instruments
      )
    }
  }

  setFilteredData(filtered)
}

//TODO: Esto esta bien? Solo busca por simbolo
const handleEnter = ({ value, instruments, tabIndex, spotIndex, onSelectInstrument }) => {
  const instrumentList = instruments.find(
    instrument => instrument?.symbol.toUpperCase() === value.toUpperCase()
  )

  instrumentList && onSelectInstrument(instrumentList, tabIndex, spotIndex)
}

const handleScrollTo = listContainerRef => {
  listContainerRef.current.scrollTop = 0
}

const InstrumentSearch = ({
  search = '',
  instruments,
  onSelectInstrument,
  instrument,
  tabIndex,
  spotIndex,
}) => {
  const fuse = new Fuse(instruments, options)
  const [filteredData, setFilteredData] = useState(search ? fuse.search(search) : instruments || [])
  const [filterSymbol, setFilterSymbol] = useState(instrument || search)
  const [type, setType] = useState('ALL')
  const [open, setOpen] = useState(false)
  const listContainerRef = useRef()
  const { t } = useContext(I18nContext)
  search && fuse.setCollection(fuse.search(search))

  const onClickAway = event => {
    if (event.currentTarget.activeElement.tagName === 'LI') {
      event.preventDefault()
    }
  }

  return (
    <div>
      <div sx={style.container}>
        <div sx={style.inputContainer}>
          <OutlinedInput
            autoFocus
            labelWidth={0}
            sx={style.input}
            id="search"
            value={filterSymbol}
            endAdornment={
              <InputAdornment position="end">
                <Search sx={style.searchIcon} />
              </InputAdornment>
            }
            onChange={event => {
              handleChangeType({ value: type, setType, instruments, setFilteredData, fuse })
              handleSearchFuse({
                filter: event.target.value,
                setFilterSymbol,
                setFilteredData,
                instruments,
                type,
                fuse,
              })
              handleScrollTo(listContainerRef)
            }}
            onKeyPress={event =>
              event.key === 'Enter' &&
              handleEnter({
                value: event.target.value,
                instruments,
                tabIndex,
                spotIndex,
                onSelectInstrument,
              })
            }
            placeholder={search || t('search')}
          />
        </div>
        <div>
          <ClickAwayListener onClickAway={onClickAway}>
            <CustomSelect
              open={open}
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              sx={{ ...style.input, ...mt }}
              value={type}
              onChange={e => {
                handleChangeType({
                  value: e.target.value,
                  setType,
                  instruments,
                  setFilteredData,
                  fuse,
                })
                handleSearchFuse({
                  filter: filterSymbol,
                  setFilterSymbol,
                  setFilteredData,
                  instruments,
                  type: e.target.value,
                  fuse,
                })
              }}
            >
              <MenuItem value="ALL" classes={{ selected: 'customSelected' }}>
                {t('ALL')}
              </MenuItem>
              <MenuItem value="FXSPOT" classes={{ selected: 'customSelected' }}>
                {t('SPOT')}
              </MenuItem>
              <MenuItem value="FUTURE_ROLLING_SPOT" classes={{ selected: 'customSelected' }}>
                {t('ROLLING_SPOT')}
              </MenuItem>
            </CustomSelect>
          </ClickAwayListener>
        </div>
        <div ref={listContainerRef} sx={style.resultBox}>
          <div>
            <MenuList disablePadding dense>
              {filteredData &&
                filteredData.map((instrument, i) => (
                  <MenuItem
                    sx={style.menuItem}
                    value={instrument?.id}
                    key={`${instrument?.id}${i.toString()}`} //TODO: No usar indice en la id
                    onClick={() => onSelectInstrument(instrument, tabIndex, spotIndex)}
                  >
                    <div sx={style.instrumentContainer}>
                      <span sx={style.instrumentSymbol}>{instrument?.name}</span>
                      <span sx={style.instrumentDataDescription}>{instrument?.description}</span>
                    </div>
                  </MenuItem>
                ))}
            </MenuList>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) =>
  createSelector(hydratedInstrumentSelector, instruments => ({
    instruments: Object.values(instruments),
  }))(state, ownProps)

export default connect(mapStateToProps, null)(InstrumentSearch)
