/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import { equals } from 'ramda'
import { filterCaseInsensitive, sxTable, addColumnListStyle } from './TableUtils'
import 'react-table/react-table.css'

const Table = ({
  rows,
  columns,
  filterable,
  onChangeFilter,
  defaultFilters,
  styleTable = {},
  minRows = 3,
  defaultSorted,
  defaultFiltered,
  disabled = false,
  ...props
}) => {
  return (
    <div className="esr-table" sx={{ bg: 'primary' }}>
      <ReactTable
        defaultPageSize={Number.MAX_VALUE}
        minRows={minRows}
        defaultFiltered={defaultFilters}
        data={rows}
        columns={addColumnListStyle(columns)}
        className="-highlight"
        showPagination={false}
        filterable={filterable}
        defaultFilterMethod={filterCaseInsensitive}
        onFilteredChange={onChangeFilter}
        sx={sxTable(styleTable, disabled)}
        defaultSorted={defaultSorted}
        {...styleTable}
        {...props}
      />
    </div>
  )
}

Table.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  filterable: PropTypes.bool,
  setFilters: PropTypes.object,
  onChangeFilter: PropTypes.func,
  defaultFilters: PropTypes.array,
}

export default React.memo(
  Table,
  (prexProps, nextProps) =>
    equals(prexProps.columns, nextProps.columns) &&
    equals(prexProps.filterable, nextProps.filterable) &&
    equals(prexProps.rows, nextProps.rows)
)
