/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as style from './style'
import { useState, useRef, useContext } from 'react'
import { Search } from '@material-ui/icons'
import { MenuList, OutlinedInput } from '@material-ui/core'
import { MenuItem } from '../../../common/utilities'
import { I18nContext } from '../../../containers/i18n'

const handleEnter = ({ value, data, onChange }) => {
  const filteredOption = data.find(
    option => option[labelProp].toUpperCase() === value.toUpperCase()
  )
  filteredOption && onChange(filteredOption)
}

const handleScrollTo = listContainerRef => {
  listContainerRef.current.scrollTop = 0
}

const SelectSearchOptions = ({
  placeholder = 'search',
  noneEnabled,
  options,
  valueProp = 'value',
  labelProp = 'label',
  value,
  onChange,
  menuItemHeight = 40,
  boundingClientRect,
  menuItemRender = option => option[labelProp],
  ...props
}) => {
  const [filteredOptions, setFilteredOptions] = useState(options || [])
  const [searchString, setSearchString] = useState('')
  const listContainerRef = useRef()
  const { t } = useContext(I18nContext)

  const menuOption = option => (
    <MenuItem
      sx={option.style || style.menuItemHeight(menuItemHeight)}
      value={option[valueProp]}
      onClick={() => onChange(option)}
    >
      {menuItemRender(option)}
    </MenuItem>
  )

  const numItems = filteredOptions.length + noneEnabled

  return (
    <div
      sx={{
        ...style.containerByNumItems({ numItems }),
        ...style.getModalStyle(boundingClientRect),
      }}
      {...props}
    >
      <div sx={style.inputContainer}>
        <OutlinedInput
          autoFocus
          labelWidth={0}
          sx={style.searchInput}
          id="search"
          value={searchString}
          endAdornment={<Search sx={style.searchIcon} />}
          onChange={event => {
            setSearchString(event.target.value)
            setFilteredOptions(
              options.filter(e =>
                e[labelProp].toUpperCase().includes(event.target.value.toUpperCase())
              )
            )
            handleScrollTo(listContainerRef)
          }}
          onKeyPress={event =>
            event.key === 'Enter' &&
            handleEnter({
              value: event.target.value,
              data: options,
              onChange,
            })
          }
          placeholder={t(placeholder)}
        />
      </div>
      <div ref={listContainerRef} sx={style.resultBoxByNumItems({ numItems })}>
        <MenuList disablePadding dense>
          {noneEnabled && menuOption({ [labelProp]: t('none'), style: style.menuItemNone })}
          {filteredOptions.map(menuOption)}
        </MenuList>
      </div>
    </div>
  )
}

export default SelectSearchOptions
