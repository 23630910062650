import React from 'react'
import { connect } from 'react-redux'
import RfqTable from './Table'
import { showConfirmDialog, closeConfirmDialog } from '../../actions/workspace'
import { socketSendMessage, subscribeForRFQ, hideSnackbar } from '../../actions/socket'
import RfqForm from './Form'
import RqfBest from './RqfBest'
import Snackbar from '@material-ui/core/Snackbar'
import CustomSnackbar from './CustomSnackbar'
import uuid from 'uuid'

class Rfq extends React.Component {
  constructor(props) {
    super(props)
    this.acceptQuote = this.acceptQuote.bind(this)
  }

  UNSAFE_componentWillMount() {
    const { keycloak, tabIndex, socketSendMessage, workspaces, currentWorkspace } = this.props
    const id = workspaces[currentWorkspace].tabs[tabIndex].rfqId
    this.props.subscribeForRFQ(this.props.tabIndex)
    if (id) {
      const rfqInf = {
        type: 'get_rfq',
        oid: uuid() + '_get_rfq',
        rfqId: id,
      }
      this.props.keycloak.updateToken(30).success(() => {
        socketSendMessage({
          token: keycloak.token,
          topic: '/private/rfq/get',
          message: rfqInf,
          tabIndex: tabIndex,
        })
      })
    }
  }

  handleClick = () => {
    this.setState({ open: true })
  }

  render() {
    const { keycloak, pairList, tabIndex, socket, currentWorkspace, workspaces } = this.props
    const transactionId = socket.ids[workspaces[currentWorkspace].tabs[tabIndex].oid]
    const bids = socket.data[transactionId] ? socket.data[transactionId].bids : {}
    const asks = socket.data[transactionId] ? socket.data[transactionId].asks : {}
    const bidKeys = Object.keys(bids)
    const askKeys = Object.keys(asks)
    const data = workspaces[currentWorkspace].tabs[tabIndex].data || {}

    return (
      <div>
        <RfqForm
          clientRef={this.clientRef}
          rfqId={transactionId}
          pairList={pairList}
          rfqData={data}
          keycloak={keycloak}
          tabIndex={tabIndex}
        />
        {socket.data && (
          <RqfBest
            currency={''} //TODO send the currency
            bids={bids}
            asks={asks}
            bidKeys={bidKeys}
            askKeys={askKeys}
          />
        )}
        {socket.data && (
          <RfqTable
            action={this.acceptQuote}
            bids={bids}
            asks={asks}
            bidKeys={bidKeys}
            askKeys={askKeys}
          />
        )}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={socket.snackbar.open && socket.snackbar.id === transactionId}
          autoHideDuration={6000}
          onClose={this.props.hideSnackbar}
        >
          <CustomSnackbar
            onClose={this.props.hideSnackbar}
            variant="success"
            message="RFQ accepted!"
          />
        </Snackbar>
      </div>
    )
  }

  acceptQuote(quoteId, quoteMsgId, side) {
    const url = 'private/rfq/accept'
    const message = {
      type: 'accept_rfq',
      rfqId: this.props.socket.ids[
        this.props.workspaces[this.props.currentWorkspace].tabs[this.props.tabIndex].oid
      ],
      quoteId,
      side,
      quoteMsgId,
    }

    this.props.isConfirmDialogRequired
      ? this.props.showConfirmDialog({
          message: this.props.literals[side],
          title: this.props.literals.confirmAction,
          action: () => {
            socketSendMessage({
              token: this.props.keycloak.token,
              topic: url,
              message: message,
              tabIndex: this.props.tabIndex,
            })
            this.props.closeConfirmDialog()
          },
        })
      : socketSendMessage({
          token: this.props.token,
          topic: url,
          message: message,
          tabIndex: this.props.tabIndex,
        })
  }

  sendSocketMessage(url, message) {
    socketSendMessage({
      topic: url,
      message: JSON.stringify(message),
      token: this.props.keycloak.token,
    })
  }
}

const mapStateToProps = ({
  workspace: { isConfirmDialogRequired, workspaces, currentWorkspace, rfqData },
  socket,
}) => ({
  isConfirmDialogRequired,
  workspaces,
  currentWorkspace,
  rfqData,
  socket,
})

const mapDispatchToProps = {
  showConfirmDialog,
  closeConfirmDialog,
  socketSendMessage,
  subscribeForRFQ,
  hideSnackbar,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Rfq)
