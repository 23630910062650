/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import { Button } from '../NumberSelector/style'
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons'
import styled from '@emotion/styled'

import { allowOnlyNumbers } from '../../common/utilities'
import { InputBase } from '@material-ui/core'
import { FormattedNumberInput, FormattedNumberInputEvent } from '../FormattedNumber'

const borderStyle = { border: '1px solid', borderColor: 'background' }

const Input = styled(InputBase)`
  font-family: ${props => props.theme.fonts.body[props.colorMode]};
  background-color: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.text};
  border: none;
  border-color: ${props => props.theme.colors.muted};
  font-size: 1rem;
  text-align: left;
  padding: 0 15px;
  height: 30px;
  align-items: center;
  flex-grow: 2;
  &:hover {
    background-color: ${props => props.theme.colors.secondary};
  }
  &:disabled {
    background-color: ${props => props.theme.colors.disabled};
    &:hover {
      background-color: none;
    }
  }
  &:focus {
    outline: none;
  }
  //& * {
  //  color: #fff;
  //}
`

const fixNumberString = str => str && parseInt(str)

const QuantityCell = ({ id, value, onQuantityChange, increment = 1 }) => {
  const { colorMode } = useThemeUI()
  const handleOnChange = quantity => {
    const parsedQuantity = fixNumberString(quantity)
    quantity && onQuantityChange(id, parsedQuantity)
  }
  return (
    <div
      sx={{
        minWidth: '120px',
        mx: '10px',
        display: 'flex',
      }}
    >
      <Input
        sx={{
          fontSize: '13px',
        }}
        value={value}
        onChange={e => {
          handleOnChange(e.target.value)
        }}
        inputComponent={FormattedNumberInput}
      />
      <div
        sx={{
          width: '62px',
          alignItems: 'center',
        }}
      >
        <Button
          sx={{ ...borderStyle, marginLeft: '-1px' }}
          disabled={value <= increment}
          onClick={() => handleOnChange(+value - +increment)}
          colorMode={colorMode}
        >
          <ArrowDropDown />
        </Button>
        <Button
          sx={{ ...borderStyle, marginLeft: '-1px' }}
          onClick={() => handleOnChange(+value + +increment)}
          colorMode={colorMode}
        >
          <ArrowDropUp />
        </Button>
      </div>
    </div>
  )
}

export default QuantityCell
