import React from 'react'

import { useColorMode } from 'theme-ui'
import { allowOnlyNumbers } from '../../common/utilities'
import { Input } from './style'
import { DialogRowElement, Label } from '../Dialog'

const DialogNumberInput = ({ label, onChange, defaultValue = '0', ...props }) => {
  const [colorMode] = useColorMode()

  return (
    <DialogRowElement {...props}>
      <Label>{label}</Label>
      <Input
        {...props}
        onChange={e => onChange(e.target.value === '0' ? defaultValue : e.target.value)}
        onBlur={e => onChange(e.target.value === '' ? defaultValue : e.target.value)}
        onPaste={allowOnlyNumbers}
        onKeyPress={allowOnlyNumbers}
        colorMode={colorMode}
      />
    </DialogRowElement>
  )
}

export default DialogNumberInput
