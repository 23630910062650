import { connect } from 'react-redux'
import React, { useContext } from 'react'

import { I18nContext } from '../../containers/i18n'
import { DialogInput } from '../index'

const AccountSelector = ({
  operationAccount,
  //connect
  accounts,
}) => {
  const { t } = useContext(I18nContext)

  const findLabelByValue = value => {
    const found = accounts[value?.id || '']
    return found && `${found['name']} (${found['fixAccount']})`
  }

  return <DialogInput label={t('in')} value={findLabelByValue(operationAccount || '')} disabled />
}

const mapStateToProps = ({ accounts: { data: accounts } }) => ({
  accounts,
})

export default connect(mapStateToProps, null)(AccountSelector)
