export default {
  account: 'Cuenta de operaciones',
  accountCollateral: 'Margen consumido por la cuenta de operación (estimado)',
  accountError:
    'No se ha seleccionado una cuenta adecuada para una operación que involucre este tipo de producto.',
  actions: 'Acciones',
  add: 'Añadir',
  addOrderList: 'Lista de órdenes',
  addRfqTab: 'Panel RFQ',
  addDepthSplitQuote: 'Precios con profundidad',
  addSplitQuote: 'Precios por par',
  addTableTab: 'Panel de tabla',
  addAccountSummaryList: 'Resumen de mis cuentas',
  addAccountPositionList: 'Posiciones cuenta seleccionada',
  addMarketDepth: 'Profundidad de mercado',
  addPositionList: 'Posiciones de mis cuentas',
  addTradeList: 'Lista de operaciones',
  addQuoteList: 'Panel de cotizaciones',
  addPriceList: 'Lista de precios',
  addTrade: 'Panel de nueva orden',
  addAccountGroup: 'Grupo de cuentas',
  ALL: 'Todos',
  allTypes: 'Todos los tipos',
  amount: 'Volumen',
  ANY_OTHER: 'AOTC',
  APPLICATION: 'Cross Trade',
  authError: 'Usuario o contraseña incorrectos',
  averagePrice: 'Precio medio acumulado',
  BMEFX: 'BME FX',
  BUY: 'Compra',
  BUYACTION: 'Comprar',
  realizedProfit: 'PyG realizado',
  realizedProfitEUR: 'PyG realizado (EUR)',
  cancel: 'Cancelar',
  CANCELLED: 'Cancelada',
  cancelOrder: 'Cancelar orden',
  CLASSIC: 'Classic Future',
  clientId: 'Cliente',
  clientOrderId: 'Nº orden cliente',
  marketOrderId: 'Nº orden mercado',
  close: 'Cerrar',
  columnSelection: 'Seleccionar columnas',
  confirm: 'Aceptar',
  confirmAction: 'Confirmar acción',
  confirmOrder: 'Confirmar orden',
  connectionStatus: 'Estado de la conexión',
  cookiePolicy: 'Política de Cookies',
  createAccountGroup: 'Crear Grupo de Cuentas',
  createRFQ: 'Crear RFQ',
  currency: 'Divisa',
  dark: 'Oscuro',
  dateLocal: 'Fecha entrada (local)',
  timeLocal: 'Hora (Local)',
  DAY: 'DAY',
  DEAL: 'DEAL',
  decisorId: 'Decisor',
  deferralVariationMargin: 'VM de diferimiento',
  deferralVariationMarginEUR: 'VM de diferimiento (EUR)',
  delete: 'Borrar',
  dontChange: 'No cambiar',
  dontShowAgain: 'No mostrar este mensaje de nuevo',
  dragText: '<br><br>(Arrastra para colocar)',
  endRFQ: 'Terminar RFQ',
  en: 'Inglés',
  es: 'Español',
  error: 'Error',
  EUR: 'EUR',
  exchange: 'Mercado',
  exchangeId: 'ID mercado',
  execStatus: 'Estado del evento',
  executorId: 'Ejecutor',
  expirationDate: 'Fecha de expiración',
  EXPIRED: 'Expirada',
  expiry: 'Validez',
  FILLED: 'Ejecutada',
  FILLED_INCOMPLETE: 'Ejec. incompleta',
  filledPrice: 'Precio de ejecución',
  FOK: 'FOK',
  GTD: 'GTD',
  hideTables: 'Ocultar tablas',
  in: 'en',
  instrument: 'Instrumento',
  interface: 'Interfaz',
  interfaceSettings: 'Preferencias de interfaz',
  IOC: 'IOC',
  intradayCollateral: 'Consumo de margen intradía (estimado)',
  keep: 'Mantener',
  keycloak: 'keycloak',
  lang: 'ES',
  language: 'Idioma',
  lastUpdateDate: 'Fecha última actualización',
  legalNotice: 'Aviso Legal',
  light: 'Claro',
  LIMIT: 'Limitada',
  login: 'Acceder',
  logout: 'Salir',
  MARKET: 'A Mercado',
  MATCH: 'MTCH',
  maxCollateral: 'Margen máximo permitido',
  modalDeleteAccountGroup: name => `Se va a borrar el grupo de cuentas ${name}`,
  modalTitleDeleteAccount: name => `Borrar ${name}`,
  modifyOrder: 'Modificar orden',
  notModify: 'No modificar',
  more: 'Más',
  netPosition: 'Posición neta',
  netPositionValue: 'Valor neto de la posición',
  NEW: 'Nueva',
  newTab: 'Nuevo panel',
  newWorkspace: 'Nuevo espacio',
  noAvailable: 'No disponible',
  noCancel: `No cancelar`,
  noPlace: `No confirmar`,
  ORDER_STATUS: 'Estado de la orden',
  orderDetails: 'Detalles de la orden',
  orderedPrice: 'Precio',
  orderId: 'Número de orden',
  orderQuantity: 'Volumen orden',
  orders: 'Órdenes',
  orderListLabel: 'Órdenes', //flexlayout tab name
  orderCancelText: 'Motivo de cancelación',
  orderRejectText: 'Motivo de rechazo',
  reason: 'Motivo',
  ork: 'ORK',
  orkError: 'Codificación de ORK no válida - la orden no será enviada',
  pair: 'Par',
  PARTIALLY_FILLED: 'Parcialmente eje.',
  password: 'Contraseña',
  passwordVisibility: 'Ver contraseña',
  PENDING_CANCEL: 'Pendiente cancelar',
  PENDING_NEW: 'Pendiente nueva',
  pendingQuantity: 'Volumen pendiente',
  pendingQty: 'Volumen pendiente',
  PENDING_REPLACE: 'Pendiente reemplazar',
  PENDING: 'Pendiente',
  placeOrder: 'Confirmar orden',
  preferences: 'Preferencias',
  price: 'Precio',
  privacyPolicy: 'Política de privacidad',
  limitPrice: 'Precio límite',
  shortLimitPrice: 'Precio LMT',
  stopPrice: 'Precio stop',
  executionPrice: 'Precio ejecución',
  avgPrice: 'Precio medio acumulado',
  shortStopPrice: 'Precio STP',
  productSubType: 'Subtipo',
  positionListLabel: 'Posiciones de mis cuentas', //flexlayout tab name
  marketDepthLabel: 'Profundidad de mercado', //flexlayout tab name
  accountPositionListLabel: 'Posiciones cuenta seleccionada', //flexlayout tab name
  pyl: 'PyG',
  quantity: 'Volumen',
  QTY: 'VOL',
  qty: 'Vol',
  lastqty: 'Volumen evento',
  cumQty: 'Volumen ejecutado',
  cumQuantity: 'Volumen ejecutado',
  realizedPL: 'PyG realizado',
  REJECTED: 'Rechazada',
  remainingQuantity: 'Volumen restante',
  remainingCollateral: 'Margen disponible (estimado)',
  REPLACE_REJECTED: 'Reemplazo rechazado',
  REPLACED: 'Reemplazada',
  RESTATED: 'Reestablecida',
  rfq: 'RFQ',
  rfqList: 'Lista RFQs',
  ROLLING_SPOT: 'xRolling FX',
  FXSPOT: 'FX Spot',
  BMEFXSPOT: 'BMEFX',
  null: '',
  save: 'Guardar',
  search: 'Buscar',
  selectAccountGroupsName: 'Selecciona grupos de cuenta',
  selectInstrument: 'Seleccionar instrumento',
  SELL: 'Venta',
  SELLACTION: 'Vender',
  showConfirmTransaction: 'Solicitar confirmación de la transacción',
  showTables: 'Ver tablas',
  side: 'Compra/Venta',
  signIn: 'Registrarse',
  splitQuote: 'Precios por par',
  splitQuoteLabel: 'Precios por par',
  depthSplitQuoteLabel: 'Precios con profundidad',
  SPOT: 'FX Spot',
  status: 'Estado',
  STOP_LIMIT: 'Stop Limitada',
  accountSummaryListLabel: 'Resumen de mis cuentas', //flexlayout tab name
  tenor: 'Tenor',
  text: 'Texto del evento',
  theme: 'Cambiar de tema',
  title: 'BME FX',
  TRADE: 'Trade completado',
  TRADE_CANCEL: 'Cancelación de trade',
  TRADE_CORRECT: 'Trade correcto',
  trade: 'Operación',
  tradeId: 'ID de operación',
  tradeLabel: 'Nueva orden', //flexlayout tab name
  tradeLocal: 'Hora operación (local)',
  trader: 'Operador',
  trades: 'Operaciones',
  TRIGGERED: 'Activada',
  quoteListLabel: 'Cotizaciones', //flexlayout tab name
  tradeListLabel: 'Operaciones', //flexlayout tab name
  priceListLabel: 'Precios', //flexlayout tab name
  tradingCapacity: 'Capacidad de negociación',
  tradingSettings: 'Preferencias de operación',
  type: 'Tipo',
  unrealizedPL: 'PyG no realizado',
  unrealizedPLE: 'PyG no realizado (EUR)',
  username: 'Nombre de usuario',
  userSettingsTitle: 'Preferencias de usuario',
  dateFormat: 'dd/MM/yy',
  masterOrderId: 'ID de Orden Maestro',
  orderHistory: 'Histórico',
  //ORK Validations
  clientIdRange:
    'El código de cliente tiene que ser cero, uno, dos o un número entero >= 100 y <= 4.294.967.295.',
  decisorIdZeroOrInteger:
    'Los valores del Decisor ID pueden ser cero (0) o números enteros entre >= 100 y <= 4.294.967.295 (2^32-1).',
  executorIdThreeOrInteger:
    'Los valores del Executor ID pueden ser tres (3) o números enteros entre >= 100 y <= 4.294.967.295 (2^32-1).',
  whenClientIdIsZero:
    'Si el código de cliente es cero, el decisor ha de ser >= 100 y el trading capacity (capacidad de negociación) a de ser DEAL o MTCH.',
  whenClientIdIsNotZero:
    'Si la orden tiene un código de cliente (Valor distinto de 0) entonces la Trading Capacity tiene que ser AOTC o MTCH.',
  whenTradingCapacityIsDeal: 'Si el Trading Capacity es DEAL el código de cliente tiene que ser 0.',
  whenExecutorIdIsThree: 'Si ejecutor es 3 el TC ha de ser AOTC y el decisor ha de ser 0.',
  //Price List columns
  bidVolume: 'Vol.',
  bidPrice: 'Demanda',
  askPrice: 'Oferta',
  askVolume: 'Vol.',
  lastTrade: 'Última operación',

  FUTURE: 'Futuro',

  selected: 'Seleccionado',

  //accountGroup
  accountOperationAccountLabel: 'Cuenta de op.',
  accountGroupLabel: 'Grupo de cuentas',
  accountGroups: 'Grupos de cuentas',
  accountGroupName: 'Nombre del grupo de cuentas',
  selectAccount: 'Selecciona cuenta',
  none: 'Ninguna',
  member: 'Miembro',
  market: 'Mercado',
  client: 'Cliente',
  accounts: 'Cuentas',
  accountId: 'ID de cuenta',
  fixId: 'ID de FIX',

  validateAccountGroup_title: 'Hay errores en el formulario',
  validateAccountGroup_NotEmptyName: 'En nombre de la cuenta es obligatorio.',
  validateAccountGroup_NotEmptyAccounts: 'Es necesaria al menos una cuenta.',

  hedgingSim: 'Sim. cobertura',
  hedgingSimUrl: 'https://www.meff.es/esp/Derivados-Financieros/Simulador-Cobertura-FX',

  marketDepthUrl: 'https://www.meff.es/xRollingFx/Widget?lang=es',

  bm: 'Bm',

  //ModelMessageNotification
  alert: 'Alerta',
  maxGuaranteeOrderRejected: account =>
    `Orden rechazada en cuenta "${account}" por máximo de garantías consumido.`,
  warning: 'Aviso',
  maxThresholdGuaranteeConsumption: account =>
    `Garantías consumidas cerca del umbral máximo en cuenta "${account}"`,
  maxGuaranteeConsumptionOnAccount: account =>
    `Máximo de garantías consumido en cuenta "${account}"`,
  orderHasBeenRejectedOnAccount: account =>
    `Se ha rechazado una orden en la cuenta "${account}" porque su ejecución excedería las garantías máximas configuradas para la cuenta. Por favor, contacte con su administrador.`,
  reachingMaximumGuaranteeThresholdForAccount: account =>
    `Se está alcanzando el umbral máximo de garantías establecido para la cuenta "${account}". En caso de duda, por favor contacte con su administrador.`,
  reachedMaximumGuaranteeThresholdForAccount: account =>
    `Se ha consumido el máximo de garantía establecido para la cuenta "${account}". Por favor, contacte con su administrador.`,
  details: 'Detalles',
  currentAccountMargin: margin => `Garantías consumidas actuales: ${margin}€`,
  accountMarginRequired: margin => `Garantías requeridas para procesar la orden: ${margin}€`,
  warningThreshold: threshold => `Umbral de alarma: ${threshold}€`,
  affectedOperationAccounts: 'Cuentas de Operación afectadas',
  triggeringOrder: 'Orden procesada:',
  rejectedOrder: 'Orden rechazada:',
  operationAccountNotification: account => `Cuenta de operación: ${account}`,
  exchangeNotification: exchange => `Mercado: ${exchange}`,
  typeNotification: type => `Tipo: ${type}`,
  sideNotification: side => `Operación: ${side}`,
  instrumentNotification: instrument => `Instrumento: ${instrument}`,
  quantityNotification: quantity => `Volumen: ${quantity}`,
  accountMaxMargin: margin => `Garantías máximas de la cuenta: ${margin}€`,

  //MarketDepth
  vol: 'VOL',
  bid: 'DEMANDA',
  ask: 'OFERTA',

  kidBoughtxRollingFuture: 'Futuro sobre Divisa xRolling® FX Comprado',
  kidSoldxRollingFuture: 'Futuro sobre Divisa xRolling® FX Vendido',
  kidBoughtxRollingFutureUrl:
    'https://www.meff.es/docs/docsSubidos/KIDS_esp/KIDS-DF/KID_Futuro_sobre_Divisa_xRolling%C2%AE_FX_Comprado.pdf',
  kidSoldxRollingFutureUrl:
    'https://www.meff.es/docs/docsSubidos/KIDS_esp/KIDS-DF/KID_Futuro_sobre_Divisa_xRolling%C2%AE_FX_Vendido.pdf',

  externalCustomerId: 'ID Cliente Externo',
}
