import { find, propEq } from 'ramda'

export const searchAccount = (currentAccount, accountGroups, accounts, instrument) => {
  let acc = accounts[currentAccount?.id]

  if (acc && acc.exchangeId === instrument?.exchangeId) {
    return acc
  }

  acc = find(propEq('id', currentAccount?.id))(accountGroups)
  if (acc) {
    if (currentAccount.type === 'group') {
      const myAccG = acc.accounts[instrument?.exchangeId]
      return accounts[myAccG?.value] || null
    }
  }
  return null
}
